import React from 'react'
import './BillDetailOS.css'

// libraries
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

// components
import Header from '../../layout/Header/Header';
import { ListClaimsDetail } from '../../components';

const BillDetailOS = () => {
	const location = useLocation()
  const { organization, claims } = location.state

	return (
		<>
		<Header title='Detalle'/>
		<div className="container bill-detail-os__container">			
			<div className="bill-details-os__table animate__animated animate__fadeIn animate__faster">
				<ListClaimsDetail organization={organization} claims={claims}/>
			</div>
		</div>
		</>
	)
}

export default withRouter(BillDetailOS);
