import { Box, List, Typography } from "@mui/material"
import { DiaCash } from "./DiaCash"

export const MesCash = ({Mes}) => {
  return (
    <Box>
      <Typography align="left" sx={{ color: '#aba183' }}>{Mes}</Typography>
      <List sx={{textAlign: 'center'}}>
        <DiaCash></DiaCash>
        <DiaCash></DiaCash>
      </List>
    </Box>
  )
}
