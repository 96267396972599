import React, { useState, useEffect, useContext } from 'react';
import './BillSuccessCard.css'

// libraries
import { Card } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from "@react-pdf/renderer";

// utils
import { AppContext } from '../../context/provider';
import { formatDateDDMMYYYY } from '../../utils/dateUtils';

//constants
import pdfMock from '../../mock/pdf-mock.json';
import afipParams from '../../constants/afip-params';

//components
import Header from '../../layout/Header/Header';
import PdfStructure from '../../pdf/PdfStructure';

const DownloadIcon = () => {
  return (
    <PDFDownloadLink
      document={<PdfStructure responseData={pdfMock} requestData={afipParams} />}
      fileName="planilla-presentacion.pdf"
    >
      <DownloadOutlined />
    </PDFDownloadLink>
  )
}

export const BillSuccessCard = () => {
  const [ state ] = useContext(AppContext);
  const [data, setData] = useState({})
  const [localState, setLocalState] = useState({ 
    loading: true
  });

  useEffect(() => {
    setTimeout(() => {
      const res = pdfMock.responseData.FeDetResp.FECAEDetResponse[0];
      if(res){
        setLocalState({ 
          loading: false
        })
        setData(res)
      }
    }, 3000);
  }, [])

  return (
    <>
      <Header />
      <Card 
        className="BillSuccessCard"
        loading={ localState.loading }
        actions={[<DownloadIcon />]}
      >
        <div className="BillSuccessCard__body">
          <p className="BillSuccessCard__body-title">OSDE</p>
          <p className="BillSuccessCard__body-item">Comprobante numero: { data.CbteDesde }</p>
          <p className="BillSuccessCard__body-item">Numero CAE: { data.CAE }</p>
          <p className="BillSuccessCard__body-item">Fecha de comprobante: { formatDateDDMMYYYY(data.CbteFch) }</p>
          <p className="BillSuccessCard__body-mount">Monto: { state.total }</p>
          {/* <p>Vencimiento: { formatDateDDMMYYYY(data.CAEFchVto) }</p> */}
        </div>
      </Card>
    </>
  )
}
