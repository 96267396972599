import dayjs from 'dayjs';

const formatDateDDMMYYYY = date => (
  dayjs(date).format('DD/MM/YYYY')
);

const formatDateOSI = date => {
  const splitDate = date.split('/');
  return new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
};


const convertNanoToMili = nanoseconds => {
  return nanoseconds/1000000
};

const convertSecondsToDate = seconds => {
  return new Date(seconds * 1000)
};

const isSameDate = (date1, date2) => {
  return (
    dayjs(date1).date() === dayjs(date2).date() &&
    dayjs(date1).month() + 1   === dayjs(date2).month() + 1 &&
    dayjs(date1).year()  === dayjs(date2).year()
  );
};

const addDaysToDate = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
};

const convertAAAAMMtoMMfromAAAA  =(aaaamm)=>{

  // Validar la entrada (debe ser una cadena AAAAMM válida)
  if (!/^\d{6}$/.test(aaaamm)) {
    return "Fecha no válida";
  }

  // Extraer el año y el mes de la cadena AAAAMM
  const año = parseInt(aaaamm.substring(0, 4));
  const mes = parseInt(aaaamm.substring(4, 6));

  // Validar el mes (debe estar en el rango de 1 a 12)
  if (mes < 1 || mes > 12) {
    return "Mes no válido";
  }

  // Crear un objeto Date con el año y el mes
  const fecha = new Date(año, mes - 1, 1); // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11

  // Formatear la fecha como "mes del año"
  const mesDelAño = fecha.toLocaleString("es-ES", { month: "long", year: "numeric" });

  return mesDelAño;

};

const formatDate = (isoDateString)=>{
  const date = new Date(isoDateString);
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = date.getFullYear();
return `${day}/${month}/${year}`;
}
const formatTime = (isoTimeString)=>{
  const time = new Date(isoTimeString);
const hours = String(time.getHours()).padStart(2, '0');
const minutes = String(time.getMinutes()).padStart(2, '0');
return `${hours}:${minutes}`;
}



const formatDateTurnos = (newValue)=>{  
return dayjs(newValue).format('DD/MM/YYYY');
}


const formatFirebaseDate = (firebaseDate) => {
  const dateObject = new Date(firebaseDate.seconds * 1000 + firebaseDate.nanoseconds / 1000000);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11 en JavaScript
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};


export {
  formatDateDDMMYYYY,
  formatDateOSI,
  convertNanoToMili,
  convertSecondsToDate,
  isSameDate,
  addDaysToDate,
  convertAAAAMMtoMMfromAAAA,
  formatDate, 
  formatTime,
  formatDateTurnos,
  formatFirebaseDate
};
