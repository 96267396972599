import React, { useContext, useState } from "react";
import "./Header.css";

// librearies
import { NavBar } from "antd-mobile";
import { useHistory } from "react-router-dom";
import MenuButton from "../../components/MenuButton/MenuButton";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { AppContext } from "../../context/provider";
import { Box, Modal, Typography } from "@mui/material";
import QRCode from "react-qr-code";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Header = ({ isHome, title, noback, isSubscripter }) => {
  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const back = () => {
    if (noback) return;
    history.goBack();
  };
  const backHome = () => {
    if (noback) return;
    history.push('/')
  }
  const handleReadQr = ()=>{
    history.push("/scann-qr");
  }

  return (
    <>
      <NavBar
        className="nav"
        onBack={noback ? null : isSubscripter ? backHome : back}
        left={isHome && <MenuButton />}
        right={<>
          <QrCode2Icon onClick={handleOpen} style={{ cursor: 'pointer' }} />
          <CenterFocusStrongIcon onClick={handleReadQr} style={{ cursor: 'pointer', marginInline: '5px' }} />
        </>}
        back={noback && null}
      >
        <div className="nav-title">{title}</div>        
      </NavBar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Mostrar codigo QR
          </Typography>
          <Box textAlign="center" marginTop={2}>
            <QRCode value={JSON.stringify(currentUser)} />
          </Box>
        </Box>
      </Modal>
    </>

  );
};

export default Header;
