import React, { useState, useRef, useContext } from 'react';
import { Box, Container, Paper, Modal, Typography, Button } from '@mui/material';
import Header from '../../layout/Header/Header';
import { Space } from 'antd-mobile';
import { BrowserQRCodeReader } from '@zxing/library';
import Swal from 'sweetalert2';
import { AppContext } from '../../context/provider';
import { useHistory } from 'react-router-dom';

const QrScannerComponent = () => {
    const [state, setState] = useContext(AppContext);
    const [data, setData] = useState('No QR code detected');
    const [openModal, setOpenModal] = useState(false);
    const videoRef = useRef(null);
    const history = useHistory();

    const startScanner = async () => {
        const codeReader = new BrowserQRCodeReader();
        try {
            const result = await codeReader.decodeOnceFromVideoDevice(undefined, videoRef.current);
            if (result.text) {
                setData(result.text);
                try {
                    const jsonResult = JSON.parse(result.text);
                    if (jsonResult && jsonResult.name) {
                        setOpenModal(true);
                        setState({ ...state, selectedPatient: jsonResult, hdcs: [] });
                    } else {
                        throw new Error('Invalid QR data');
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Datos personales incompletos',
                        text: 'Completar datos personales',
                    });
                }
            } else {
                setData('No QR code detected');
                Swal.fire({
                    icon: 'error',
                    title: 'Datos personales incompletos',
                    text: 'Completar datos personales',
                });
            }
        } catch (error) {
            console.error(error);
            setData('Completar datos personales');
            Swal.fire({
                icon: 'error',
                title: 'Datos personales incompletos',
                text: 'Completar datos personales',
            });
        }
    };

    const handleCloseModal = () => setOpenModal(false);

    const renderModalContent = () => {
        try {
            const jsonResult = JSON.parse(data);
            return (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <img src={jsonResult?.urlphoto ?? 'https://via.placeholder.com/200x200'} alt="Foto" style={{ width: '100%', marginTop: '10px' }} />
                    <Typography variant="h6" component="h2">{jsonResult.name ?? ''}</Typography>
                    <Typography sx={{ mt: 2 }}>Teléfono: {jsonResult?.phone ?? ''}</Typography>
                    <Typography sx={{ mt: 2 }}>Dni: {jsonResult?.dni ?? ''}</Typography>
                    <Typography>Email: {jsonResult?.email ?? ''}</Typography>
                    <Button
                        style={{ width: '100%', fontWeight: 'bold', backgroundColor: '#00a92b', color: '#fff' }}
                        onClick={editPatient}>Motrar detalle de paciente</Button>
                </Box>
            );
        } catch (error) {
            return <Typography>Datos incompletos</Typography>;
        }
    };

    const editPatient = () => {
        // history.push('/edit-patient');
        history.push('/');
    }

    const buildName = () => {
        return 'Leer Qr - Health';
    };

    return (
        <Box>
            <Header title={buildName()} />
            <Space align='center' direction='vertical' className='container animate__animated animate__fadeIn animate__faster'></Space>
            <Container maxWidth="sm">
                <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
                    <video
                        ref={videoRef}
                        style={{ width: '100%' }}
                        muted
                        autoPlay
                    />
                    <button onClick={startScanner}>Escanear QR</button>
                </Paper>
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {renderModalContent()}
            </Modal>
        </Box>
    );
};

export default QrScannerComponent;
