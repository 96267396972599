const CLAIMS_IN_GRID = [
  { id: 1, state: false, name: 'Consulta' },
  { id: 2, state: false, name: 'Ortodoncia' },
  { id: 3, state: false, name: 'Endodoncia' },
  { id: 4, state: false, name: 'Reduccion de Luxacion' },
  { id: 5, state: false, name: 'Tratamiento Gingivitis' },
  { id: 6, state: false, name: 'Bote-Wing' },
  { id: 7, state: false, name: 'Tomografia CBCT' },
  { id: 8, state: false, name: 'Extraccion Dentaria Simple' },
  { id: 9, state: false, name: 'Biopsia por Escision' },
];

const CLAIMS = [
  { id: 1, state: false, name: 'FACTOR VIII COAGULACIÓN RECOMB. - XYNTHA - 500 UI F.A.X1+JGA.PRELL.' },
  { id: 2, state: false, name: 'HERNIOPLASTIA INGUINAL / CRURAL BILATERAL POR VIA VIDEOLAPAROSCOPICA' },
  { id: 3, state: false, name: 'CONSULTA ESPECIALISTA EN GENETICA MEDICA (VG)' },
  { id: 4, state: false, name: 'PAPILOMA VIRUS HUMANO - HPV (CARGA VIRAL)' },
  { id: 5, state: false, name: 'SUCCINIL PURINAS' },
  { id: 6, state: false, name: 'RECONSTRUCCION DE ANGULO PIEZA FILTRADA' },
  { id: 7, state: false, name: 'ACIDO LACTICO - URINARIO' },
  { id: 8, state: false, name: 'CETONEMIA' },
  { id: 9, state: false, name: 'RADIOGRAFIA DE RAQUIS (COLUMNA): PRIMERA EXPOSICION' },
  { id: 10, state: false, name: 'Consulta ortodoncia' },
  { id: 11, state: false, name: 'consulta de urgencia en ortodoncia' },
  { id: 12, state: false, name: 'consultas no incluidas en mantenimiento y evaluacion' },
  { id: 13, state: false, name: 'consulta quirurgica' },
  { id: 14, state: false, name: 'consulta de implantes' },
  { id: 15, state: false, name: 'consulta post-quirurgico' },
  { id: 16, state: false, name: 'Estomatologia - primera consulta' },
  { id: 17, state: false, name: 'Estomatologia - consulta ulterior' },
  { id: 18, state: false, name: 'ortopedia prequirurgica - consulta fichado' },
];

const PROFESSIONS = [
  {
    id: 0,
    name: 'Medicina',
    isPsychologistTech: false,
  },
  {
    id: 1,
    name: 'Psicología',
    isPsychologistTech: true,
  },
];

const INSTITUTIONCATEGORY = [
  {
    id: 0,
    name: '1'
  }
]

const MEDICCATEGORY = [{
  id: 0,
  name: '0'
}
]

const REGISTRATION = [
  {
    id: 0,
    name: 'Nacional',

  },
  {
    id: 1,
    name: 'Provincial',
  }
]

const PROVINCE = [
  {

    id: 0,
    name: "Buenos Aires",


  },
  {

    id: 1,
    name: "Ciudad Autónoma de Buenos Aires",


  },
  {

    id: 2,
    name: "Catamarca",


  },
  {

    id: 3,
    name: "Chaco",


  },
  {

    id: 4,
    name: "Chubut",


  },
  {

    id: 5,
    name: "Córdoba",

  },
  {

    id: 6,
    name: "Corrientes",


  },
  {

    id: 7,
    name: "Entre Ríos",


  },
  {

    id: 8,
    name: "Formosa",


  },

  {

    id: 9,
    name: "Jujuy",


  },
  {

    id: 10,
    name: "La Pampa",


  },
  {

    id: 11,
    name: "La Rioja",


  },
  {

    id: 12,
    name: "Mendoza",


  },
  {

    id: 13,
    name: "Misiones",


  },
  {

    id: 14,
    name: "Neuquén",


  },
  {

    id: 15,
    name: "Río Negro",


  },
  {

    id: 16,
    name: "Salta",


  },
  {

    id: 17,
    name: "San Juan",


  },
  {

    id: 18,
    name: "San Luis",


  },
  {

    id: 19,
    name: "Santa Cruz",


  },
  {

    id: 20,
    name: "Santa Fe",


  },
  {

    id: 21,
    name: "Santiago del Estero",


  },
  {

    id: 22,
    name: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",


  },
  {

    id: 23,
    name: "Tucumán",


  }
]

const MOCK_PATIENT_CLAIMS = [
  {
    id: 1,
    date: '01/10/2021',
    patient: 'Juan Perez',
    dni: '34829384',
    claim: 'Consulta',
    organization: 'osde',
    amount: 500
  }, {
    id: 2,
    date: '01/10/2021',
    patient: 'Juan Perez',
    dni: '34829384',
    claim: 'Estomatologia - primera consulta',
    organization: 'osde',
    amount: 1300
  }, {
    id: 3,
    date: '01/10/2021',
    patient: 'Emiliano Esteban Medina',
    dni: '35393934',
    claim: 'ortopedia prequirurgica - consulta fichado',
    organization: 'subsidio',
    amount: 600
  }, {
    id: 4,
    date: '01/10/2021',
    patient: 'Carla Lucia Ramirez',
    dni: '27293405',
    claim: 'Extraccion Dentaria Simple',
    organization: 'swiss',
    amount: 400
  }, {
    id: 5,
    date: '01/10/2021',
    patient: 'Carla Lucia Ramirez',
    dni: '27293405',
    claim: 'Extraccion Dentaria Simple',
    organization: 'swiss',
    amount: 400
  },
  {
    id: 6,
    date: '01/10/2021',
    patient: 'Juan Perez',
    dni: '34829384',
    claim: 'Consulta',
    organization: 'osde',
    amount: 1100
  },
  {
    id: 7,
    date: '01/10/2021',
    patient: 'Alex Villecco',
    dni: '34716738',
    claim: 'Consulta',
    organization: 'omint',
    amount: 200
  },
];

const MOCK_ORGANIZATIONS = [
  {
    name: 'OSDE',
    alias: 'osde',
  }, {
    name: 'Swiss Medical',
    alias: 'swiss',
  }, {
    name: 'Subsidio de salud',
    alias: 'subsidio',
  }, {
    name: 'Red de seguridad',
    alias: 'red-seguridad',
  }, {
    name: 'Colmed',
    alias: 'colmed',
  }, {
    name: 'Omint',
    alias: 'omint',
  },
];

const MOCK_PATIENTS = [
  { id: 1, dni: 11010223, lastname: 'Perez', name: 'Macarena', claim: 'Consulta', amount: '19492' },
  { id: 2, dni: 13990223, lastname: 'Mendoza', name: 'Luciano', claim: 'Reduccion de Luxacion', amount: '340' },
  { id: 4, dni: 34087623, lastname: 'Medina', name: 'Mariano', claim: 'Extraccion Dentaria Simple', amount: '560' },
  { id: 5, dni: 34087623, lastname: 'Diaz', name: 'Carla Lucia', claim: 'Extraccion Dentaria Simple', amount: '840' },
  { id: 6, dni: 34087623, lastname: 'Romano', name: 'Alejandro Martin', claim: 'Extraccion Dentaria Simple', amount: '20040' },
  { id: 7, dni: 34087623, lastname: 'Marquez', name: 'Maria Pilar', claim: 'Extraccion Dentaria Simple', amount: '5436' },
  { id: 9, dni: 34087623, lastname: 'Bustos', name: 'Ana Roberta', claim: 'Extraccion Dentaria Simple', amount: '850' },
  { id: 10, dni: 11010223, lastname: 'Perez', name: 'Macarena', claim: 'Consulta', amount: '19492' },
  { id: 11, dni: 13990223, lastname: 'Mendoza', name: 'Luciano', claim: 'Reduccion de Luxacion', amount: '340' },
  { id: 12, dni: 34087623, lastname: 'Medina', name: 'Mariano', claim: 'Extraccion Dentaria Simple', amount: '560' },
  { id: 13, dni: 34087623, lastname: 'Diaz', name: 'Carla Lucia', claim: 'Extraccion Dentaria Simple', amount: '840' },
  { id: 14, dni: 34087623, lastname: 'Romano', name: 'Alejandro Martin', claim: 'Extraccion Dentaria Simple', amount: '20040' },
  { id: 15, dni: 34087623, lastname: 'Marquez', name: 'Maria Pilar', claim: 'Extraccion Dentaria Simple', amount: '5436' },
  { id: 16, dni: 34087623, lastname: 'Bustos', name: 'Ana Roberta', claim: 'Extraccion Dentaria Simple', amount: '850' },
];

const BILL_DETAILS_PATIENTS = [
  {
    id: 1,
    dni: 11010223,
    lastname: 'Perez',
    name: 'Macarena',
    claim: 'Consulta',
    amount: '19492',
    os: 'omint',
  },
  {
    id: 2,
    dni: 13990223,
    lastname: 'Mendoza',
    name: 'Luciano',
    claim: 'Reduccion de Luxacion',
    amount: '340',
    os: 'omint',
  },
  {
    id: 4,
    dni: 34087623,
    lastname: 'Medina',
    name: 'Mariano',
    claim: 'Extraccion Dentaria Simple',
    amount: '560',
    os: 'colmed',
  },
  {
    id: 5,
    dni: 34087623,
    lastname: 'Diaz',
    name: 'Carla Lucia',
    claim: 'Extraccion Dentaria Simple',
    amount: '840',
    os: 'colmed',
  },
  {
    id: 6,
    dni: 34087623,
    lastname: 'Romano',
    name: 'Alejandro Martin',
    claim: 'Extraccion Dentaria Simple',
    amount: '20.040',
    os: 'colmed',
  },
  {
    id: 7,
    dni: 34087623,
    lastname: 'Marquez',
    name: 'Maria Pilar',
    claim: 'Extraccion Dentaria Simple',
    amount: '5.436',
    os: 'red de seguridad',
  },
  {
    id: 9,
    dni: 34087623,
    lastname: 'Bustos',
    name: 'Ana Roberta',
    claim: 'Extraccion Dentaria Simple',
    amount: '850',
    os: 'red de seguridad',
  },
  {
    id: 10,
    dni: 11010223,
    lastname: 'Perez',
    name: 'Macarena',
    claim: 'Consulta',
    amount: '19.492',
    os: 'red de seguridad',
  },
  {
    id: 11,
    dni: 13990223,
    lastname: 'Mendoza',
    name: 'Luciano',
    claim: 'Reduccion de Luxacion',
    amount: '340',
    os: 'red de seguridad',
  },
  {
    id: 12,
    dni: 34087623,
    lastname: 'Medina',
    name: 'Mariano',
    claim: 'Extraccion Dentaria Simple',
    amount: '560',
    os: 'red de seguridad',
  },
  {
    id: 13,
    dni: 34087623,
    lastname: 'Diaz',
    name: 'Carla Lucia',
    claim: 'Extraccion Dentaria Simple',
    amount: '840',
    os: 'subsidio de salud',
  },
];

export {
  CLAIMS,
  CLAIMS_IN_GRID,
  PROFESSIONS,
  MOCK_PATIENT_CLAIMS,
  MOCK_ORGANIZATIONS,
  MOCK_PATIENTS,
  BILL_DETAILS_PATIENTS,
  REGISTRATION,
  PROVINCE,
  INSTITUTIONCATEGORY,
  MEDICCATEGORY
};
