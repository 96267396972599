import React from 'react';

// libraries
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';

const InvoiceFooter = ({ total, quantityOfPages, currentPage }) => {
  return(
    <View style={styles.footerContainer}>
      <View style={styles.totalInfo}>
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Subtotal: $                                  {total}</Text>
          <Text style={styles.totalLabel}>Importe Otros Tributos: $             0,00</Text>
          <Text style={styles.totalLabel}>Importe Total: $                          {total}</Text>
        </View>
      </View>
      <View style={styles.footerInfo}>
        <View style={styles.leftInfo}>
          <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjmjEM0aGgo0zfvkYdZNBySlJBgon5IF5SXQ&usqp=CAU" style={{ width: 100 }} />
        </View>
        <View style={styles.page}>
          <Text style={styles.pageText}> Pag. {currentPage}/{quantityOfPages} </Text>
        </View>
        <View style={styles.rightInfo}>
          <View style={styles.rightInfoUp}>
            <View style={styles.rightInfoUpLogo}>
              <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK4NSzWy0j3E8SL1-5Uqb4HrFk_PkwAMErhg&usqp=CAU" style={{ width: 120 }} />
            </View>
            <View style={styles.rightInfoUpCae}>
              <Text> CAE N°: 71084200728406 </Text>
              <Text> Fecha de Vto. de CAE: 07/03/2021 </Text>
            </View>
          </View>
          <View style={styles.rightInfoDown}>
            <Text style={styles.mdText}> Comprobante Autorizado </Text>
            <Text style={styles.smText}> Esta Administración Federal no se responsabiliza por los datos ingresados en el detalle de la operación </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
  },
  totalInfo: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    border: '1 solid black',
    padding: 20,
  },
  totalRow: {
    padding: 20,
  },
  totalLabel: {
    fontWeight: 'bold',
  },
  totaInfo: {
    paddingLeft: 20,
  },
  footerInfo: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
  },
  leftInfo: {
    width: '20%',
  },
  rightInfo: {
    width: '80%',
    flexDirection: 'column',
  },
  rightInfoUp: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightInfoDown: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  rightInfoUpLogo: {
    paddingTop: 10
  },
  rightInfoUpCae: {
  },
  page: {
    position: 'absolute',
    left: '45%',
    margin: '0 auto',
    width: 70,
    textAlign: 'center',
    paddingTop: 5,
  },
  pageText: {
    fontSize: 12,
  },
  xsText: {
    fontSize: 6
  },
  smText: {
    fontSize: 9
  },
  mdText: {
    fontSize: 12
  },
  lgText: {
    fontSize: 15
  },
});

export default InvoiceFooter;