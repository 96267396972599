import { addDoc, collection } from "firebase/firestore";
import { db } from "../base";
import './style.css'

export async function sendEmailUtils(email, subject, body, nameFile, file) {


  const collectionRef = collection(db, 'mail');
  const emailContent = {

    to: [email, 'jamizrahi@gmail.com'], //[] para mas de uno
    message: {
      from: '"Kani Health 🩺" no-responder@kani-health', // sender address
      subject: subject,
      text: body,
      attachments: [
        {   // utf-8 string as an attachment
          filename: nameFile,
          content: file
        },]
    }
  }
  return await addDoc(collectionRef, emailContent)


}

export async function sendEmailUtilswithPdf(email, subject, downloadUrl, nameFile, file) {
  const now = new Date();
  const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()}`;

  const collectionRef = collection(db, 'mail');
  const emailContent = {

    to: [email, 'jamizrahi@gmail.com'],//[] para mas de uno
    message: {
      from: '"Kani Health 🩺" no-responder@kani-health', // sender address
      subject: subject,
      html: `
            <div class="card">
              <div class="card-header">SIFO + PRESTACIONES</div>
              <div class="card-body">
                <b>Se presentó SIFO y planilla de prestaciones</b>
                <br />
                <b>${formattedDate}</b>
              </div>
              <div class="card-button">
                <a href="${downloadUrl}" class="btn">Abrir planilla-prestaciones.PDF</a>
              </div>
            </div>
          `,
      attachments: [
        {   // utf-8 string as an attachment
          filename: nameFile,
          content: file
        },]
    }
  }
  return await addDoc(collectionRef, emailContent)


}



export async function sendEmailTurnos(email, doctor, paciente, date, time, lugar) {

  const collectionRef = collection(db, 'mail');
  const emailContent = {

    to: [email, 'jamizrahi@gmail.com'],//[] para mas de uno
    message: {
      from: '"Kani Health 🩺" no-responder@kani-health', // sender address
      subject: "Turnos",
      html: `
      <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <title></title>
        <style>
        body {
          font-family: 'Arial', sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f2f2f2; /* Fondo blanco */
          color: #000; /* Texto negro */
        }
        .email-container {
          max-width: 600px;
          margin: 20px auto;
          background-color: #fff; /* Tarjeta blanca */
          padding: 20px;
          border: 1px solid #ccc; /* Bordes grises */
          border-radius: 10px;
          text-align: center; /* Centrar todo el contenido */
          color: #000; /* Texto negro */
        }
        .email-header img {
          max-width: 100px; /* Ajusta esto según el tamaño deseado de tu logo */
          margin-bottom: 20px;
        }
        .email-button {
          background-color: #28a745; /* Verde */
          color: #fff !important;
          padding: 10px 20px;
          text-decoration: none;
          border-radius: 5px;
          display: inline-block;
          margin-top: 20px;
        }
        .email-footer {
          color: #666; /* Texto gris */
          font-size: 14px;
          margin-top: 40px;
          padding-top: 20px;
          border-top: 1px solid #ccc; /* Línea superior gris */
        }
        .email-footer a {
          color: #000; /* Texto negro */
          text-decoration: none;
        }        
        </style>
      </head>
      <body>        
        <div class="email-container">
          <div class="email-footer">
            <h2 style="color: #28a745;"> KANI HEALTH</h2>
            <h3>TURNO ASIGNADO con Dr/a ${doctor}.</h3>
            <p>PACIENTE ${paciente}</p><br />
            <p>DÍA: ${date}</p><br />
            <p>HORA: ${time}</p><br />
            <p>LUGAR: ${lugar}</p><br />
            <p>Recordatorio de estar 15 minutos antes.</p>
            Saludos,<br>
            KANI HEALTH
            <p>&copy; 2024 KANI. Derechos reservados.</p>
          </div>
        </div>
      </body>
      </html>
    `,
    }
  }
  return await addDoc(collectionRef, emailContent)


}



