
import { auth, db } from '../base';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { Button, Grid } from 'antd-mobile';
import Swal from 'sweetalert2';

const organizations = require('../mock/organizations.json');
const claims = require('../mock/prestaciones.json');
const specialities = require('../mock/specialities.json');
const modules = require('../mock/modulos.json');

export const SuperActions = () => {

  const loadOrganizations = () => {
    organizations.forEach(async (x) => {
      await addDoc(collection(db, 'organizations'), {
        cuit: x.cuit,
        name: x.name.toUpperCase(),
        alias: x.alias,
        adress: x.adress.toUpperCase(),
        active: x.active,
      });
    });
  };

  const loadClaims = () => {
    claims.forEach(async (x) => {
      await addDoc(collection(db, 'claims'), {
        module: x.module,
        code: x.code,
        name: x.name.toUpperCase(),
        type: x.type,
        active: true,
      });
    });
    console.log('claims loaded');
  };

  const loadSpecialities = () => {
    specialities.forEach(async (x) => {
      await addDoc(collection(db, 'specialities'), {
        name: x.name.toUpperCase(),
        active: true,
      });
    });
    console.log('specialities loaded');
  };

  const loadModules = () => {
    try {
      modules.forEach(async (x) => {
        await addDoc(collection(db, 'modules'), {
          code: x.code,
          description: x.description.toUpperCase(),
          active: true,
        });
      });
      Swal.fire('modules loaded', '', 'success');
    } catch (error) {
      Swal.fire('Modules not loaded', error, 'error');
    }


  };


  const changeUserProf = async () => {
    try {
      const { uid } = auth.currentUser;
      const ref = doc(db, `users/${uid}`);
      const user = await getDoc(ref);
      const { profession } = user.data();
      let newProfession = profession === 'Medicina' ? 'Psicología' : 'Medicina';
      await updateDoc(ref, { profession: newProfession });
      Swal.fire('Profession changed', '', 'success');
    } catch (error) {
      Swal.fire('Error', error, 'error');


    }
  }

  const changeResponsesToActive = async () => {
    const { uid } = auth.currentUser;

    try {
      const claims = await getDocs(
        collection(db, `users/${uid}/claim-responses`)
      );

      claims.forEach(async ({ id }) => {
        const ref = doc(db, `users/${uid}/claim-responses`, `${id}`);
        await updateDoc(ref, {
          status: 'active',
        });
      });
      Swal.fire('Responses changed to active', '', 'success');
    } catch (error) {
      Swal.fire('Responses not changed', error, 'error');
    }
  };

  const deleteClaims = async () => {
    const { uid } = auth.currentUser;

    try {
      const claims = await getDocs(
        collection(db, `users/${uid}/claim-responses`)
      );

      claims.forEach(async ({ id }) => {
        const ref = doc(db, `users/${uid}/claim-responses`, `${id}`);
        await deleteDoc(ref);
      });
      Swal.fire('All Claims were deleted', '', 'success');
    } catch (error) {
      Swal.fire('Error', error, 'error');
    }
  }

  return (
    <Grid columns={3} gap={2} style={{ margin: 10 }}>
      <Grid.Item><Button onClick={changeResponsesToActive} color='primary'> RESP ACTIVE      </Button></Grid.Item>
      <Grid.Item><Button onClick={changeUserProf} color='primary'> CHANGE PROF      </Button></Grid.Item>
      <Grid.Item><Button onClick={deleteClaims} color='primary'> DELETE CLAIMS      </Button></Grid.Item>
      <Grid.Item><Button onClick={loadOrganizations} color='primary' disabled> LOAD ORGS        </Button></Grid.Item>
      <Grid.Item><Button onClick={loadClaims} color='primary' disabled> LOAD CLAIMS      </Button></Grid.Item>
      <Grid.Item><Button onClick={loadSpecialities} color='primary' disabled> LOAD SPECS       </Button></Grid.Item>
      <Grid.Item><Button onClick={loadModules} color='primary' disabled> LOAD MODULES       </Button></Grid.Item>
    </Grid>
  );
};
