/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./EditPatient.css";

// libraries
import { Button, Divider, Space } from "antd-mobile";
import { withRouter } from "react-router";
import { useForm } from 'react-hook-form';
import { doc, setDoc } from "@firebase/firestore";
import { DatePicker } from "antd";
import Swal from 'sweetalert2';

// utils
import { db } from "../../base";
import { AppContext } from '../../context/provider';

// components
import Header from '../../layout/Header/Header';

// constant
import texts from '../../constants/texts';
import { Grid } from "@mui/material";

const CustomSelect = ({ options, setSelectedOpt, fieldName, defaultOpt }) => {
  return (
    <select
      name='empty'
      onChange={(e) => setSelectedOpt(options.filter(opt => opt.name === e.target.value)[0])}
      value={defaultOpt && defaultOpt.name}
      multiple={false}
    >
      <option hidden> Seleccione una opción </option>
      {options.map(opt => (
        <option
          key={opt.id}
          className='form-field__input'
          value={opt.name}
          name={fieldName}
        >
          {opt.name}
        </option>
      ))}
    </select>
  );
};

const EditPatient = ({ history }) => {
  const { register, handleSubmit } = useForm();
  const [state, setState] = useContext(AppContext);
  const { selectedPatient } = state;
  const [userInfo, setUserInfo] = useState(selectedPatient);
  const [organizations, setOrganizations] = useState([]);
  const { pages: { editPatient: {
    title,
    form: {
      nameLabel,
      affiliateLabel,
      dniLabel,
      addressLabel,
      localityLabel,
      provinceLabel,
      phoneLabel,
      phonePlaceholder,
      emailLabel,
      genderLabel,
      bloodgroupLabel,
      medicalrecordText,
      saveText,
      codeLabel,
      selectOptions: {
        genders,
        bloodTypes,
      }
    },
  } } } = texts;
  const [selectedGender, setSelectedGender] = useState(selectedPatient ? selectedPatient.gender : null);
  const [selectedBloodType, setSelectedBloodType] = useState(selectedPatient ? selectedPatient.bloodType : null);
  //const [selectedBirthDate, setSelectedBirthDate] = useState(selectedpatien ? selectedPatient.birthdate : null)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSelect = () => {
    setUserInfo({
      ...userInfo,
      gender: selectedGender,
      bloodType: selectedBloodType
    });
  };



  const onChangeDate = (date, dateString) => {
    if (dateString) {
      const dateObject = new Date(dateString);
      const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
      console.log(formattedDate);
    }
    setUserInfo({ ...userInfo, birthdate: dateString })
  }


  useEffect(() => {
    handleSelect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender, selectedBloodType]);

  useEffect(() => {

    setOrganizations(state.organizations);
    // console.log('User info : ', JSON.stringify(userInfo, null, 6));
  }, []);

  const handleSetPatient = async _ => {
    const patiendRef = doc(db, "patients", userInfo.dni);

    if (userInfo.birthdate && userInfo.dni && userInfo.gender && userInfo.bloodType) {

      await setDoc(patiendRef, { ...userInfo, gender: selectedGender, bloodType: selectedBloodType });

      Swal.fire({
        title: 'Usuario modificado con exito',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      setState({ ...state, selectedPatient: userInfo });

      history.push('/')
    } else {
      Swal.fire({
        title: 'Completar los datos requeridos',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };


  const onSubmit = _ => handleSetPatient();

  const updateSelectedOrganization = organization => {
    setUserInfo({
      ...userInfo,
      organization,
    });
  };

  const handleSelectOrg = e => {
    const organizationId = e.target.value;
    const organization = organizations.find(
      organization => organization.id === organizationId
    );
    updateSelectedOrganization(organization.name);
  };

  const SelectOrganization = () => {
    return (
      <select className='organizations' onChange={handleSelectOrg}>
        <option value="">Selecciona una opción</option>
        <option hidden> {userInfo && userInfo.organization} </option>
        {organizations.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        ))}
      </select>
    );
  };

  const medicalRecord = () => {
    history.push('/medical-record');
  }


  return (

    <Grid container>
      <Header title={title} />
      <Space />
      <Grid item xs={12} md={3} sx={{ backgroundColor: '#fff' }}>
      </Grid>
      <Grid item xs={12} md={6} sx={{ backgroundColor: '#fff' }}>
        <div className="form-title">{userInfo && userInfo.name}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <li className="form-field">
            <label>{nameLabel}</label>
            <input
              value={userInfo && userInfo.name}
              {...register('name')}
              name="name"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{affiliateLabel}</label>
            <input
              value={userInfo && userInfo.affiliate}
              {...register('affiliate')}
              name="affiliate"
              type="number"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{dniLabel}</label>
            <input value={userInfo && userInfo.dni}
              {...register('dni', { required: "Por favor ingrese su DNI" })}
              name="dni"
              type="number"
              className="form-field__input"
              onChange={handleInputChange} />
          </li>
          <li className="form-field">
            <label>OBRA SOCIAL</label>
            <SelectOrganization />
          </li>
          <li className="form-field">
            <label>{addressLabel}</label>
            <input
              {...register('address')}
              value={userInfo && userInfo.address}
              name="address"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{codeLabel}</label>
            <input
              {...register('postalCode')}
              value={userInfo && userInfo.postalCode}
              name="postalCode"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{localityLabel}</label>
            <input
              {...register('locality')}
              value={userInfo && userInfo.locality}
              name="locality"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{provinceLabel}</label>
            <input
              {...register('province')}
              value={userInfo && userInfo.province}
              name="province"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{phoneLabel}</label>
            <input
              value={userInfo && userInfo.phone}
              {...register('phone')}
              name="phone"
              type="number"
              placeholder={phonePlaceholder}
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <li className="form-field">
            <label>{emailLabel}</label>
            <input
              value={userInfo && userInfo.email}
              {...register('email')}
              name="email"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>
          <Divider />
          <li className="form-field">
            <label>Fecha de Nacimiento *</label>
            <DatePicker onChange={onChangeDate}
              placeholder="Fecha de nacimiento"
            />
          </li>
          <Divider />
          <li className="form-field">
            <label>{genderLabel}</label>
            <CustomSelect
              options={genders}
              setSelectedOpt={setSelectedGender}
              fieldName='gender'
              defaultOpt={selectedGender}
            />
          </li>
          <li className="form-field">
            <label >{bloodgroupLabel} *</label>
            <CustomSelect
              options={bloodTypes}
              setSelectedOpt={setSelectedBloodType}
              fieldName='bloodType'
              defaultOpt={selectedBloodType}
            />
          </li>
          <li className="form-field" style={{ flexDirection: 'row' }}>
            <label>{medicalrecordText}</label>
            <Button className="form-button__medicalRecord" onClick={medicalRecord} color="primary"> VER </Button>
          </li>
          <Divider />
          <Button type="submit" block color='success'>{saveText}</Button>
          <Space />
        </form>
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center' }}>
      </Grid>
    </Grid>

  );
};

export default withRouter(EditPatient);
