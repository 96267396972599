import accounting from 'accounting-js';



export const useAccounting = () => {
    const format = {
        thousand: '.',
        decimal: ',',
      }
    
    const formatedCurrency = (currency = 0) => {
        return accounting.format(currency, format)
    }
  
  
  
  
    return {
        formatedCurrency,
  }
}
