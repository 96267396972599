import React, { useContext } from 'react';

// libraries
import { Link } from 'react-router-dom';
import 'dayjs/locale/es';

import { ReactComponent as Bullet } from '../../assets/logo_item.svg';
import { useAccounting, usePayments } from '../../hooks';
import { AppContext } from '../../context/provider';

export const PanelColumnElement = ({date, claims}) => {
  const [state, setState] = useContext(AppContext)
  const { formatedCurrency } = useAccounting();
  const { getTotalAmount } = usePayments();
  const amount = getTotalAmount(claims)

  return (
    <div>
      <Link
        to={{
          pathname: '/billing',
          // state: { date, amount, month, claims},
        }}
        style={{ textDecoration: 'none' }} 
      >
        <div className='panel_column' onClick={() => setState({...state, claimsReport: claims})}>
          <div className='bullet-logo'>
            <Bullet />
          </div>
          <div className='panel_column--date'>
            <h4 style={{color:'#ab9f80'}}>  {date.toUpperCase()} </h4>
          </div>
          <div className='panel_column--subtotal'>
            <h3 style={{color:'#ab9f80'}}> {formatedCurrency(amount)} </h3>
          </div>
        </div>
        <div className='panel_column--line'></div>
      </Link>
    </div>
  );
};
