import React, { useContext } from 'react'
import { Col, Collapse, Row } from 'antd';
import './Table.css'
import { ActiveClaimCard } from '../';
import { useAccounting, useClaims } from '../../hooks';
import { AppContext } from '../../context/provider';
import { Checkbox } from 'antd-mobile';

export const UnlinkedList = ({list}) => {
  const { formatedCurrency } = useAccounting()
  const { checkAllClaimsOrganization } = useClaims()
  const [state] = useContext(AppContext)
  const { presentation } = state;
  const { presentationClaims } = presentation;
  const { Panel } = Collapse;
  

  const getQuantity = ({organization}) => presentationClaims.find( e => e.organization === organization)?.quantity || 0;
  

  const getTotalAmount = ({organization}) => presentationClaims.find( e => e.organization === organization)?.total || 0;

  const handleCheckOrg = ( org, isCheck ) => {
    checkAllClaimsOrganization(org, isCheck)
  }
    return (
        <ul style={{padding: '0'}}>
          {list.map((org) => (
              <li className="bill-detail-os__patient-item" key={org.id}>
                <Collapse defaultActiveKey={[]} ghost >
                    <Panel showArrow={false} header={
                        <Row style={{display: 'flex', alignItems: 'center'}}>
                          <Col span={3}><Checkbox defaultChecked className='selectAll' onChange={(isCheck) => handleCheckOrg(org, isCheck)}/></Col>
                          <Col span={12} className="claim__organization" style={{fontSize: '0.8rem'}}>{org.organization}</Col>
                          <Col span={4} className="claim" style={{fontSize: '1rem'}}>{getQuantity(org)}</Col>
                          <Col span={5} className="amount" style={{fontSize: '1rem'}}>{formatedCurrency(getTotalAmount(org))}</Col>
                        </Row>
                    } key={org.id}>
                      <ActiveClaimCard data={org.data} isCheck={true}/>
                    </Panel>
                </Collapse>
              </li>
            ))
          }
        </ul>
      )
}
