import { useContext } from 'react'
import { collection, getDocs, query, where } from "firebase/firestore";
import { AppContext } from '../context/provider';
import { db } from '../base';

const usePatient = () => {

    const [state, setState] = useContext(AppContext);
    const { currentPatient } = state;

    const TraerImages = async () => {

        const imagesQuery = query(
            collection(db, "images"),
            where("userId", "==", currentPatient.id)
        );
        const querySnapshot = await getDocs(imagesQuery);

        let arrayImages = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const imagesData = doc.data();
            const imagesWithId = { id: doc.id, ...imagesData }; // Agrega el ID al objeto
            arrayImages.push(imagesWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayImages.length > 0) {

            setState({ ...state, images: arrayImages });
        }
    };

    const TraerRecetas = async () => {

        const imagesQuery = query(
            collection(db, "recetas"),
            where("userId", "==", currentPatient.id)
        );
        const querySnapshot = await getDocs(imagesQuery);

        let arrayImages = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const imagesData = doc.data();
            const imagesWithId = { id: doc.id, ...imagesData }; // Agrega el ID al objeto
            arrayImages.push(imagesWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayImages.length > 0) {

            setState({ ...state, recetas: arrayImages });
        }
    };

    const TraerLaboratorio = async () => {

        const imagesQuery = query(
            collection(db, "laboratorio"),
            where("userId", "==", currentPatient.id)
        );
        const querySnapshot = await getDocs(imagesQuery);

        let arrayImages = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const imagesData = doc.data();
            const imagesWithId = { id: doc.id, ...imagesData }; // Agrega el ID al objeto
            arrayImages.push(imagesWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayImages.length > 0) {

            setState({ ...state, laboratorio: arrayImages });
        }
    };

    const getContacts = async () => {

        const contactsQuery = query(
            collection(db, "contacts"),
            where("userId", "==", currentPatient.id)
        );
        const querySnapshot = await getDocs(contactsQuery);

        let arrayContacts = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const contactsData = doc.data();
            const contactsWithId = { id: doc.id, ...contactsData }; // Agrega el ID al objeto
            arrayContacts.push(contactsWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayContacts.length > 0) {

            setState({ ...state, contacts: arrayContacts });
        }
    };

    const getChicldrens = async () => {

        const childrensQuery = query(
            collection(db, "patients"),
            where("idParents", "==", currentPatient.id)
        );
        const querySnapshot = await getDocs(childrensQuery);

        let arrayChildrens = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const childrensData = doc.data();
            const childWithId = { id: doc.id, ...childrensData }; // Agrega el ID al objeto
            arrayChildrens.push(childWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayChildrens.length > 0) {

            setState({ ...state, childrens: arrayChildrens });
        }
    };


    return { TraerImages, TraerLaboratorio, TraerRecetas, getChicldrens, getContacts }
}

export default usePatient