import React from 'react';

// libraries
import { StyleSheet, View, Text, Image, } from '@react-pdf/renderer';

// const
import { ReactComponent as Logo } from '../../src/assets/logo_item.svg';
const TableRow = ({
  code,
  product,
  date,
  patient,
  dni,
  authorizationCode,
  medicalLicence,
  quantity,
  messure,
  price,
  bonificationPercentage,
  bonificationTax,
  subtotal,
}) => {
  return (
    <View style={styles.row}>
      <View style={styles.rowOne}>
        <Text style={styles.smText}>{date}</Text>
      </View>
      <View style={styles.rowTwo}>
        <Text style={styles.smText}></Text>
      </View>
      <View style={styles.rowThree}>
        <Text style={styles.smText}>{patient}</Text>
      </View>
      <View style={styles.rowFour}>
        <Text style={styles.smText}>{dni}</Text>
      </View>
      <View style={styles.rowFive}>
        <Text style={styles.smText}></Text>
      </View>
      <View style={styles.rowSix}>
        <Text style={styles.smText}>{authorizationCode}</Text>
      </View>
      <View style={styles.rowSeven}>
        <Text style={styles.smText}>{medicalLicence}</Text>
      </View>
      <View style={styles.rowEight}>
        <Text style={styles.smText}></Text>
      </View>
      <View style={styles.rowNine}>
        <Text style={styles.smText}></Text>
      </View>
    </View>
  );
};

const InvoiceHeader = ({
  selectedClaims,
  name,
  speciality,
  medicalLicence,
  FchServDesde,
  FchServHasta,
  FchVtoPago,
  CbteTipo,
  organization,
}) => {

  return(
    <View style={styles.headerContainer}>
      <View style={styles.title}>
        <View>
          <Text> PLANILLA DE FACTURACIÓN: PRÁCTICAS EN CONSULTORIO </Text>
          <Text> Detalle de Prestaciones - Prácticas </Text>
        </View>
        <View style={styles.titleImageContainer}> 
          <Image style={styles.titleImage} src={Logo}/>
        </View>
      </View>

      <View style={styles.invoiceOrigin}>
        <View style={styles.invoiceOriginLeft}>
          <Text style={styles.smText}> Nº de PRESTADOR: </Text>
          <Text style={styles.smText}> MATRÍCULA: {medicalLicence} </Text>
        </View>
        <View style={styles.invoiceOriginCenter}>
          <Text style={styles.smText}> RAZÓN SOCIAL: {name} </Text>
          <Text style={styles.smText}> ESPECIALIDAD: {speciality} </Text>
        </View>
        <View style={styles.invoiceOriginRight}>
          <Text style={styles.smText}> C.U.I.T.: </Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.header}>
          <View style={styles.headOne}>
            <Text style={styles.smText}> Fecha </Text>
          </View>
          <View style={styles.headTwo}>
            <Text style={styles.smText}> Plan </Text>
          </View>
          <View style={styles.headThree}>
            <Text style={styles.smText}> Nombre y Apellido </Text>
          </View>
          <View style={styles.headFour}>
            <Text style={styles.smText}> C.U.I.L. </Text>
          </View>
          <View style={styles.headFive}>
            <Text style={styles.smText}> Cód. Práctica </Text>
          </View>
          <View style={styles.headSix}>
            <Text style={styles.smText}> N° Autorización </Text>
          </View>
          <View style={styles.headSeven}>
            <Text style={styles.smText}> Mat. Prescriptor </Text>
          </View>
          <View style={styles.headEight}>
            <Text style={styles.smText}> Coseguro </Text>
          </View>
          <View style={styles.headNine}>
            <Text style={styles.smText}> Firma Afiliado </Text>
          </View>
        </View>
        
        {selectedClaims.map((row, index) => (
          <TableRow
            code={row.code}
            product={row.claim}
            date={row.date}
            patient={row.patient}
            dni={row.dni}
            medicalLicence={medicalLicence}
            authorizationCode={row.authorizationCode}
            quantity={row.quantity}
            messure={row.messure}
            price={row.price}
            bonificationPercentage={row.bonificationPercentage}
            bonificationTax={row.bonificationTax}
            subtotal={row.subtotal}
            key={index}
          />
        ))}

      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    right: 20,
  },
  bigText: {
    fontSize: 20,
  },
  title: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  titleImageContainer: {
    width: '20%',
  },
  titleImage: {
    height: 20,
  },
  invoiceOrigin: {
    flex: 1,
    flexDirection: 'row',
  },
  invoiceOriginLeft: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
  },
  invoiceOriginCenter: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
  },
  invoiceOriginRight: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
  },
  dates: {
    padding: 10,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  invoiceDestination: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
    marginBottom: 15,
  },
  invoiceDestinationLeft: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  invoiceDestinationRight: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  table: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'grey',
    color: 'black',
    border: '1 solid black',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  headOne: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '7%',
    textAlign: 'center',
    height: 25,
  },
  rowOne: {
    justifyContent: 'center',
    width: '7%',
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headTwo: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '12%',
    textAlign: 'center',
  },
  rowTwo: {
    width: '12%',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headThree: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowThree: {
    width: '10%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headFour: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowFour: {
    width: '10%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headFive: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '18%',
    textAlign: 'center',
  },
  rowFive: {
    width: '18%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headSix: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowSix: {
    width: '10%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headSeven: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowSeven: {
    width: '10%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headEight: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '12%',
    textAlign: 'center',
  },
  rowEight: {
    width: '12%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  headNine: {
    justifyContent: 'center',
    width: '11%',
    textAlign: 'center',
  },
  rowNine: {
    width: '11%',
    textAlign: 'center',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  smText: { fontSize: 9 },
  mdText: { fontSize: 12 },
  lgText: { fontSize: 15},
  boldText: { color: '#000000' } 
});

export default InvoiceHeader;
