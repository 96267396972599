/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { AppContext } from "../context/provider";
import { auth, db } from "../base";

const useGenerateSIFO = () => {


  const [items, setItems] = useState({
    cuentas: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const [codigoSifo, setCodigoSifo] = useState(
    {
      Tipo: '1',                     //1       - 1 OK
      Número_de_Socio: '',           //2       - 5 digitos enteros OK
      Código_de_OS: '',              //3       - 5 digitos enteros OK
      Periodo_de_Facturación: '',    //4       - 6 digitos AAAAMM OK
      Número_de_Autorización: '',    //5       - 14 (OK)digitos enteros OK, en caso de ser menor de 10 completar con ceros a la derecha OK
      Número_de_Afiliado: '',        //6       - 15 (OK)digitos enteros, en caso de ser menor de 15 completar con espacios a la derecha
      Fecha_de_prestación: '',       // 7 && 8 - 16 (OK) digitos AAAAMMDDAAAAMMDD
      Médico_Solicitante: '',        //9       - 6 digitos enteros (OK)
      Diagnostico: '',               //10      - 30 digitos alfanumericos, en caso de ser menor de 30 completar con espacios a la derecha
      Cero_1: '0',                   //11A     - 0 (OK)
      Código_de_prestación: '',      //11B     - 6 (OK) digitos enteros, en caso de ser menor de 6 completar con ceros a la izquierda
      Funcion: '1',                  //12      - 2 digito entero
      Cantidad: '',                  //13      - 1 por ahora (pero es del 0 al 9)
      Recargo: '',                   //15 - 3 digitos enteros, en caso de ser menor de 3 completar con ceros a la izquierda
      Cero_2: '00',                  //16 - 00
      Primera_consulta: '',          //17 - S | N
      Cero_3: '00000000',            //18 - 00000000
      Nombre_del_Paciente: '',       //19 - 30 digitos alfanumericos, en caso de ser menor de 30 completar con espacios a la derecha
      Separador_70: '00',            //20 - OK 
      orden: '1',                    //21 - 1
      Tipo_de_Matricula: '',         //22 - P | N
      Provincia: 'T',                //23 - T
      Numero_de_matricula: '',       //24 - 6 digitos enteros
      Numero_de_orden: '',           //25 - 8 digitos enteros autogenerados 
      Espacio_en_blanco_2: '   ',    //26 - 3 espacios en blanco
      DNI_del_afiliado: '',          //27 - 8 digitos enteros
      Espacio_en_blanco_3: '   ',    //28 - 3 espacios en blanco
      Cero_4: '00000',               //29 - 00000
    });



  const state = useContext(AppContext);

  const json = state.currentUser === undefined ? state[0] : state
  const { uid } = auth.currentUser;

  const consultarUltimoNumOrden = async () => {
    const ref = doc(db, `users/${uid}`);
    const user = await getDoc(ref);
    const userData = user.data();
    const { numOrden } = userData;
    return numOrden;
  }

  const generarCodigoSifo = async () => {
    const numeroOrden = await consultarUltimoNumOrden();

    if (numeroOrden === undefined || isNaN(numeroOrden)) {
      console.log('no hay proceso');
      return;
    }
    console.log('orden', numeroOrden);

    const today = new Date();
    const mes = (today.getMonth()).toString(); 
    const anio = today.getFullYear().toString();


    console.log(`mes : ${mes} y año : ${anio}`)

    if
      (mes === null || anio === null || mes === 0 || anio === 'AÑO') { alert('Sin datos en este periodo') }
    else {
      if (json.currentUser.associateNumber === undefined) {
        alert('Regresar al menu principal y vuelva a intentarlo!')
      } else {

        setIsLoading(true)

        console.log(`*****************************************START*******************************************`);
        console.log('1 - 1 digito tipo OK', codigoSifo.Tipo)
        console.log('2 - 5 digitos enteros - Número de Socio OK', json.currentUser.associateNumber);
        let longitud = json.currentUser.selectedOrganizations[0].providerNumber
        let longString = longitud.toString()
        let obraSocial = (longString.length < 5) ? longString.padStart(5, '0') : longString.substring(0, 5)
        console.log('3 - 5 digitos enteros - Código de OS OK', obraSocial);
        let regex = /^\d{2}\/\d{2}\/\d{4}$/;
        let filtroserviceDate = json.userClaims.filter((item) => item.serviceDate !== undefined);
        let filtroserviceDateItem = filtroserviceDate.map((item) => (regex.test(item.serviceDate)) ? new Date(item.serviceDate).getTime() : item.serviceDate.seconds * 1000)
        let serviceDate = filtroserviceDateItem.map((item) => (new Date(item)))
        let serviceDateFilter = serviceDate.map((item) => item.getFullYear() + `${(item.getMonth() + 1) < 10 ? '0' + (item.getMonth() + 1) : (item.getMonth() + 1)}`);
        let formatserviceDate = serviceDateFilter.filter((item) => item === `${anio}${mes < 10 ? '0' + mes : mes}`);

        console.log('4 - 6 digitos AAAAMM - Periodo de Facturación AAAAMM OK', formatserviceDate);

        const arrayTransformado = filtroserviceDate.map((item) => {
          const nuevaFecha = regex.test(item.serviceDate) ? new Date(item.serviceDate).getTime() : item.serviceDate.seconds * 1000
          const { serviceDate, ...newItem } = item; 
          return { ...newItem, serviceDate: nuevaFecha };
        });
        let transform2 = arrayTransformado.map((item) => {
          const nuevaFecha = (new Date(item.serviceDate))
          const { serviceDate, ...newItem } = item; 
          return { ...newItem, serviceDate: nuevaFecha };
        });

        let transform3 = transform2.map((item) => {
          const nuevaFecha = item.serviceDate.getFullYear() + `${(item.serviceDate.getMonth() + 1) < 10 ? '0' + (item.serviceDate.getMonth() + 1) : (item.serviceDate.getMonth() + 1)}`
          const { serviceDate, ...newItem } = item; 
          return { ...newItem, serviceDate: nuevaFecha };
        });
        let numAutorization = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.autorizationCode : null);
        let numAutorizationFilter = numAutorization.filter((item) => item != null);
        let numAutorizationFormater = numAutorizationFilter.map((item) => (item.toString().length > 14) ? item.toString().substring(0, 14) : item.toString().padStart(14, '0'))
        console.log('5 - 14 digitos enteros - Número de Autorización OK', numAutorizationFormater); 
        let numeroAfiliado2 = (json.selectedPatient === undefined) ? '000000000000000' : json.selectedPatient.affiliate;
        let numeroAfiliado = (numeroAfiliado2 === undefined) ? '000000000000000' : numeroAfiliado2;
        console.log('6 - 15 digitos numero de afiliado OK', numeroAfiliado);

        let fecha_de_autorizacion = transform2.map(
          (item) =>
            item.serviceDate.getFullYear() + `${(item.serviceDate.getMonth() + 1) < 10 ? '0' + (item.serviceDate.getMonth() + 1) : (item.serviceDate.getMonth() + 1)}${item.serviceDate.getDate() < 10 ? '0' + item.serviceDate.getDate() : item.serviceDate.getDate()}`);

        const mesConCero = mes.padStart(2, "0");
        const filtro = anio + mesConCero
        let date = fecha_de_autorizacion.filter(elemento => { return elemento.startsWith(filtro); })
        let dateMapo = date.map((item) => item + item);
        console.log('7 y 8 - 16 en total digitos AAAAMMDD ', dateMapo);
        let medicoSolicitante = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.registration : null);
        let medSolicitante = medicoSolicitante.filter((item) => item != null)
        let formatMEDsolicitante = medSolicitante.map((item) => (item.toString().length > 6) ? item.toString().substring(0, 6) : item.toString().padStart(6, '0'))
        console.log('9 - 6 digitos Médico Solicitante', formatMEDsolicitante);
        let description = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.description : null);
        let diagnostico = description.filter((item) => item != null)
        let formatdiagnostico = diagnostico.map((item) => (item.toString().length > 30) ? item.toString().substring(0, 30) : item.toString().padEnd(30, ' '))
        console.log('10 - 30 digitos - Diagnostico ', formatdiagnostico);
        console.log('11A - 1 digito - 0', codigoSifo.Cero_1);
        let cliam = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.claim : null);
        console.log('cliam', cliam);
        let claimnotNull = cliam.filter((item) => item != null)
        console.log('claimnotNull', claimnotNull);
        let code = json.professionClaims.map((item) => item.data)
        console.log('code', code);

        const pass_to_uppercase = (item) => {
          if (item !== null && item !== undefined) {
            return item.toUpperCase();
          }
          return item;
        }

        let itemCode = [];
        for (let i = 0; i < claimnotNull.length; i++) {
          for (let j = 0; j < code.length; j++) {
            if (pass_to_uppercase(code[j].nombre).includes(pass_to_uppercase(claimnotNull[i]))) {
              itemCode.push(code[j]);
            }
          }
        }
        console.log('itemCode', itemCode);
        let filterCodeString = itemCode.map((item) => item.prestacion)
        let filterCode = filterCodeString.map((item) => (item.toString().length < 6) ? item.toString().padStart(6, '0') : item.toString().substring(0, 6))

        console.log('11B - 6 digito - codigo de prestacion', filterCode); 
        console.log('12 - 2 digito - Funcion', codigoSifo.Funcion);
        let cantidad = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.quantity : null);
        let cantidad2 = cantidad.filter((item) => item != null)
        let quantity = cantidad2.map((item) => (item.toString().length < 2) ? item.toString().padStart(2, '0') : item.toString().substring(0, 2))
        console.log('13 - 2 digito - cantidad', quantity);
        let charge = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.charge : null);
        let carga_aux = charge.filter((item) => item != null)
        let carga = carga_aux.map((item) => (item.toString().length < 3) ? item.toString().padStart(3, '0') : item.toString().substring(0, 3))
        console.log('14A - 3 digito - Recargo', carga);
        console.log('14B - 2 digito -', codigoSifo.Cero_2);
        let readio = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.radioOption : null);
        let primera_consulta = readio.filter((item) => item != null)
        let primera_consulta_formater = primera_consulta.map((item) => item === 'si' ? 'S' : 'N')
        console.log('15 - 1 digito - S|N primera consulta', primera_consulta_formater);
        console.log('16 - 8 digito - 0', codigoSifo.Cero_3);
        let nombre = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.patient : null);
        let paciente = nombre.filter((item) => item != null)
        let formatpatiente = paciente.map((item) => (item.toString().length > 30) ? item.toString().substring(0, 30) : item.toString().padEnd(30, ' '))
        console.log('17 - 30 digito - nombre del paciente', formatpatiente);
        let dniFilter = transform3.map(item => item.serviceDate === `${anio}${mes < 10 ? '0' + mes : mes}` ? item.dni : null);
        let pacienteDni = dniFilter.filter((item) => item != null);
        let itemSexo = pacienteDni.map((dni) => {
          if (!json?.selectedPatient) {
            return 'F';
          }

          if (Array.isArray(json.selectedPatient)) {
            let foundPatient = json.selectedPatient.find((patient) => patient.dni === dni);
            return foundPatient ? (foundPatient.gender.name === 'Masculino ' ? 'M' : 'F') : 'F';
          } else if (json.selectedPatient.dni === dni) {
            return json.selectedPatient.gender.name === 'Masculino' ? 'M' : 'F';
          } else if (!json?.selectedPatient) {
            return 'F';
          }
        });

        console.log('18 - sexo:', itemSexo); 
        let sexo = itemSexo;
        console.log('19 Y 20 - 2 digito - separadador', codigoSifo.Separador_70);
        let Tipo_de_Matricula = json.currentUser.registration === 'Provincial' ? 'P' : 'N';
        console.log('21 - 1 digito - tipo de matricula', Tipo_de_Matricula);
        console.log('22 - 1 digito - Provincia ', codigoSifo.Provincia);
        let auxiliar_calculo = json.currentUser.medicalLicence ? json.currentUser.medicalLicence : '000000';
        let Numero_de_matricula = ((auxiliar_calculo.toString().length > 6)
          ? auxiliar_calculo.toString().substring(0, 6)
          : auxiliar_calculo.toString().padStart(6, '0'));

        console.log('23 - 6 digito numero de matricula', Numero_de_matricula); 
        const mi_longitud = numAutorizationFormater.length;
        const numero_de_orden = Number(numeroOrden) + Number(mi_longitud);

        let lista_de_numeros = [];

        for (let i = numeroOrden; i < numero_de_orden; i++) {

          lista_de_numeros.push((i.toString().length > 8)
            ? i.toString().substring(0, 8)
            : i.toString().padStart(8, '0'));
        }
        console.log('24 - 8 digito - numero de orden', lista_de_numeros);
        console.log('25 - DNI');
        let dni = transform3.map(item => item.serviceDate == `${anio}${mes < 10 ? '0' + mes : mes}` ? item.dni : null);
        let dnipatient = dni.filter((item) => item != null)
        console.log('26 - 8 digito - dni del afiliado', dnipatient);

        let itemFecha = pacienteDni.map((dni) => {
          if (!json?.selectedPatient) {
            return '01011930';
          }

          if (Array.isArray(json.selectedPatient)) {
            let foundPatient = json.selectedPatient.find((patient) => patient.dni === dni);
            return foundPatient ? foundPatient.gender.birthdate : '01011930';
          } else if (json.selectedPatient.dni === dni) {
            return json.selectedPatient.gender.birthdate;
          } else {
            return '01011930';
          }
        });

        console.log('27 - fecha de nacimiento:', itemFecha); 
        let fecha_de_nacimiento = itemFecha;



        let codigo_Sifo = {
          Tipo: json.currentUser.category,                     //1 OK        
          Número_de_Socio: json.currentUser.associateNumber,   //2 OK
          Código_de_OS: obraSocial,                            //3 OK
          Periodo_de_Facturación: formatserviceDate,           //4 OK // debe ser siempre el mes anterior al actual
          Número_de_Autorización: numAutorizationFormater,     //5 OK
          Número_de_Afiliado: numeroAfiliado,                  //6 OK
          Fecha_de_prestación: dateMapo,                       //7 && 8 OK
          Médico_Solicitante: formatMEDsolicitante,            //9 OK 
          Diagnostico: formatdiagnostico,                      //10  OK
          Cero_1: '0',                                         //11A OK           
          Código_de_prestación: filterCode,                    //11B OK
          Funcion: '1',                                        //12 OK
          Cantidad: quantity,                                  //13 OK
          Recargo: carga,                                      //14A OK
          Cero_2: '00',                                        //14B OK
          Primera_consulta: primera_consulta_formater,         //15 OK
          Cero_3: '00000000',                                  //16 OK
          Nombre_del_Paciente: formatpatiente,                 //17 OK
          sexo: sexo,                                           //18 OK
          Separador_70: '00',                                  //19 y 20 OK
          Tipo_de_Matricula: Tipo_de_Matricula,                //21 OK
          Provincia: 'T',                                      //22 OK
          Numero_de_matricula: Numero_de_matricula,            //23 OK
          Numero_de_orden: lista_de_numeros,                   //24 OK
          Espacio_en_blanco_2: 'DNI',                          //25 OK
          DNI_del_afiliado: dnipatient,                        //26 OK
          fecha_de_nacimiento: fecha_de_nacimiento             //27
        }

        console.log('completado', JSON.stringify(codigo_Sifo, null, 4))
        setCodigoSifo(codigo_Sifo);
        handleActualizarNumOrden(numero_de_orden);


        console.log(`*****************************************END*******************************************`);


      }
    }
  }

  const handleActualizarNumOrden = async (ultimo) => {
    const { uid } = auth.currentUser;
    const taskDocRef = doc(db, 'users', uid);


    const numOrden = parseInt(ultimo);

    if (isNaN(numOrden)) {
      alert('El valor ingresado no es un número válido.');
      return;
    }


    const numOrdenStr = numOrden.toString().padStart(8, '0');

    try {
      await updateDoc(taskDocRef, {
        numOrden: numOrdenStr,
      });
      setIsLoading(false);

    } catch (err) {
      alert('Ha ocurrido un error.');
      setIsLoading(false);
    }
  };

  const procesarSifo = async () => {

    const cuentaData = [];
    codigoSifo.Periodo_de_Facturación.map((item, index) =>
      cuentaData.push({
        codigo:
          codigoSifo.Tipo                                //1  OK
          + codigoSifo.Número_de_Socio                   //2  OK
          + codigoSifo.Código_de_OS                      //3  OK
          + codigoSifo.Periodo_de_Facturación[index]     //4  OK puede ser item
          + codigoSifo.Número_de_Autorización[index]     //5  OK
          + codigoSifo.Número_de_Afiliado                //6  OK
          + codigoSifo.Fecha_de_prestación[index]        //7  OK
          + codigoSifo.Médico_Solicitante[index]         //8  OK
          + codigoSifo.Diagnostico[index]                //9  OK
          + codigoSifo.Cero_1                            //10 OK
          + `${codigoSifo.Código_de_prestación[index] == undefined ? '270606' : codigoSifo.Código_de_prestación[index]}` //11 OK
          + codigoSifo.Funcion                           //12 OK
          + codigoSifo.Cantidad[index]                   //13 OK              
          + codigoSifo.Recargo[index]                    //14 OK
          + codigoSifo.Cero_2                            //15 OK DECIMALES DEL RECARGO
          + codigoSifo.Primera_consulta[index]           //16 OK
          + codigoSifo.Cero_3                            //17 OK IMPORTE
          + codigoSifo.Nombre_del_Paciente[index]        //18 OK
          + codigoSifo.sexo[index]                       //19 OK
          + codigoSifo.Separador_70                      //20 OK 00
          + codigoSifo.Tipo_de_Matricula                 //21 OK
          + codigoSifo.Provincia                         //22 OK
          + codigoSifo.Numero_de_matricula               //23 OK
          + codigoSifo.Numero_de_orden[index]            //24 OK
          + codigoSifo.Espacio_en_blanco_2               //25 OK DNI
          + codigoSifo.DNI_del_afiliado[index]           //26 OK
          + codigoSifo.fecha_de_nacimiento[index]        //27 OK
      })
    );

    setItems({
      cuentas: cuentaData,
    });
  }


  return {
    codigoSifo,
    isLoading,
    generarCodigoSifo,
    handleActualizarNumOrden,
    items,
    procesarSifo
  };
}

export default useGenerateSIFO;