import React from "react";

// libraries
import { Row, Col, Collapse } from "antd";
import _ from "lodash";

// constants
import texts from '../../constants/texts';

export const ListClaimsDetail = ({ organization, claims }) => {
  const { Panel } = Collapse;
  const { pages: { billDetailOS: { components: {
    table: {
      pacientText,
      amountText,
    }
  }}}} = texts;
  
  const claimsByOrgs = claims.filter( obj => obj.organization === organization.organization );
  // console.log('claimsByOrgs:::', claimsByOrgs);
  const List = () => {
    return (
      <ul style={{ padding: "0" }}>
        {claimsByOrgs.map((claim) => (
          <li className="bill-detail-os__patient-item" key={claim.id}>
            <Collapse defaultActiveKey={[]} expandIconPosition="" ghost>
              <Panel
                showArrow={false}
                header={
                  <Row justify="space-between">
                    <Col span={18} className="name">
                      {_.truncate(claim.patient, {
                        length: 50,
                        omission: "...",
                      })}
                    </Col>
                    <Col span={6} className="amount">
                      {`$${claim.amount}`}
                    </Col>
                  </Row>
                }
                key={claim.id}
              >
                <p>{claim.claim}</p>
                <p>Codigo de autorización: {claim.authorizationCode}</p>
              </Panel>
            </Collapse>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <h5 className="bill-detail-os__os-type-title">
        {organization.organization.toUpperCase()}
      </h5>
      <Row justify="space-between">
        <Col span={18} className="title-name">
          { pacientText }
        </Col>
        <Col span={6} className="title-amount">
          { amountText }
        </Col>
      </Row>
      <List />
    </div>
  );
};
