import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Box from '@mui/material/Box';
import { Drawer, ListItem, ListItemText } from '@mui/material';
import { List } from 'antd-mobile';
import { Cash } from '../Cash';
import { AppContext } from '../../context/provider';

const Header = () => {
  const state = useContext(AppContext);
  const json = state.currentUser === undefined ? state[0] : state;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem component={Link} to="/profile">
          <ListItemText primary="Mi Perfil" />
        </ListItem>
        <ListItem component={Link} to="/#">
          <ListItemText primary="SIFO" />
        </ListItem>
        <ListItem component={Link} to="/#">
          <ListItemText primary="Salir" />
        </ListItem>
      </List>
    </Box>
  );
  console.log(json);

  return (
    <>
      <AppBar position="static" sx={{bgcolor: '#6a7474', boxShadow: 0}}>
        <Toolbar>
          {/* Botón de menú */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>

          {/* Título */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="div">
              DR/A {json?.currentUser?.name ?? '...'}
              
            </Typography>
          </Box>

          {/* Botón de QR*/}
          <IconButton edge="end" color="inherit" aria-label="qr">
            <QrCodeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* Drawer lateral */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerItems}
      </Drawer>
      <Cash />
    </>
  );
};

export default Header;
