import React from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Button } from 'react-bootstrap';

export const SelectSignUp = () => {
  return (
    <div className='options-container'>
      <div className='logo-container'>
        <Logo className='logo' />
      </div>
      <div >
        <h2 className='option-title'>
          Registrarse como
        </h2>
        <div className='buttons-container'>
          <Button size='lg' className='option-buttons' variant='success'><Link className='go-link' to='/signup'>Medico</Link></Button>
          <Button size='lg' className='option-buttons' variant='success' ><Link className='go-link' to='/institutionsignup'>Consultorio</Link></Button>
        </div>
      </div>
      <Link className='link' style={{ marginBottom: '2rem' }} to='/login'>
        o inicia sesión
      </Link>
    </div>
  )
}
