import React, { useState } from 'react';
import './OrganizationsManager.css';

// libraries
import { AutoCenter, Button, Space } from 'antd-mobile';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

// assets
// import { ReactComponent as LogoWithoutText } from '../../assets/logo_item.svg';

export const OrganizationsManager = ({
  organizations,
  addSelectedOrganization,
  updateProviderNumber,
  selectedOrganizations,
}) => {
  const [isEnable, setIsEnable] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm();
  // const { fields, remove } = useFieldArray({
  //   control: register({ name: 'organizations' }),
  //   name: 'organizations',
  // });

  const handleProviderNumberChange = (e) => {
    const { name, value } = e.target;
    updateProviderNumber(name, value);
  };

  const SelectOrganization = () => {
    return (
      <select className='organizations' onChange={handleSelect}>
        <option hidden> Seleccione una Obra Social </option>
        {organizations.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        ))}
      </select>
    );
  };

  const handleSelect = (e) => {
    const organizationId = e.target.value;
    const organization = organizations.find(
      (organization) => organization.id === organizationId
    );
    setIsEnable(true);
    addSelectedOrganization(organization);
  };

  const handleAdd = () => {
    //reset({ providerNumber: '' });
    setIsEnable(false);
  };

  return (
    <>
      <Space />
      {/* <LogoWithoutText className='logo-without-text' /> */}
      <AutoCenter className='h4'>
        Agregue las Obras Sociales que desea gestionar con Kani.
      </AutoCenter>

      <div className='organizations-manager'>
        {selectedOrganizations.map((org) => (
          <div className='selected-org'>
            <div className='org-name'>{_.truncate(org.name, {
              length: 30,
              separator: '...',
            })}
            </div>
            <span className='org-provider-number'>{org.providerNumber}</span>
            <span className='org-remove' onClick={() => { }}>
              x
            </span>
          </div>
        ))}
      </div>
      <Space />
      {isEnable && (
        <div className='add-org'>
          <div>
            <label>Nro de Prestador</label>
            <input
              {...register('providerNumber', { required: true })}
              name='providerNumber'
              type='number'
              className='form-field__input'
              onChange={handleProviderNumberChange}
            />
            <p className='form-field__error'>
              {errors.providerNumber?.message}
            </p>
          </div>
          <Button onClick={handleAdd} className='' color='success'>
            Agregar Obra Social
          </Button>
        </div>
      )}
      <SelectOrganization />
    </>
  );
};
