import { IconButton, ListItem, ListItemText } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './DiaCash.css'

export const DiaCash = () => {
  return (
    <ListItem sx={{ borderBottom: '3px solid #ddead9' }}>
      <ListItemText primary="01/08/2024" sx={{color: '#aba183'}}/>
      <IconButton variant="contained" sx={{backgroundColor: '#d3cdbc', color: 'white', padding: 0, textAlign: 'right'}}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <ListItemText primary="$9999.99" sx={{color: '#aba183'}} />
    </ListItem>
  )
}
