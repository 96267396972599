import React from "react";
import "./App.css";

// libraries
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'firebase/database';

// components
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Subscription from "./pages/Subscription/Subscription";
import SuccessSubscription from "./pages/Subscription/SuccessSubscription";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import BillDetailOS from "./pages/BillDetails/BillDetailOS";
// import GenerateInvoice from './pages/GenerateInvoice/GenerateInvoice';
// import InvoiceSuccess from './pages/InvoiceSuccess/InvoiceSuccess';
import Billing from "./pages/Billing/Billing";
import EditPatient from "./pages/EditPatient/EditPatient";
import NewPatient from "./pages/EditPatient/NewPatient";
import { BillSuccessCard } from "./pages/BillSuccessCard/BillSuccessCard";
import { BillingConfig } from "./pages/BillingConfig/BillingConfig";

// util
import { AuthProvider } from "./authentication/Auth";
import PrivateRoute from "./PrivateRoute";
import ContextProvider from "./context/provider";
import { AddOrganizations } from "./pages/AddOrganizations/AddOrganizations";
import { PaymentReconciliations } from "./pages/PaymentReconciliations/PaymentReconciliations";
import { CreateInvoice, Invoice } from "./pages";
import GenerateSIFO from "./components/generateSifo/GenerateSIFO";
import { FilingHistory } from "./components/filinghistory/FilingHistory";
import Turnos from "./components/turnos/Turnos";
import { MedicalRecord } from "./components/MedicalRecord/MedicalRecord";
import InstitutionSignUp from "./pages/SignUp/InstitutionSignUp";
import { SelectSignUp } from "./pages/SignUp/SelectSignUp";
import ProfilePage from "./pages/profile";
import Plus from "./pages/plus";
import EditTurnos from "./components/turnos/EditTurnos";
import RegisterPatient from "./pages/patient/RegisterPatient";
import HomePatient from "./pages/patient/HomePatient";
import PatienLogin from "./pages/patient/PatienLogin";
import QrScannerComponent from "./pages/ReadQr";


const App = () => {

  return (
    <ContextProvider>
      <AuthProvider>
        <Router>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/edit-patient" component={EditPatient} />
          <PrivateRoute exact path="/new-patient" component={NewPatient} />
          <PrivateRoute exact path="/medical-record" component={MedicalRecord} />

          {/* auth routes */}
          <Route exact path="/selectsignup" component={SelectSignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/add-organizations" component={AddOrganizations} />
          <Route exact path="/institutionsignup" component={InstitutionSignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/patient-register" component={RegisterPatient} />
          <Route exact path="/patient-home" component={HomePatient} />
          <Route exact path="/patient-login" component={PatienLogin} />


          {/* subscription routes */}
          <PrivateRoute exact path="/subscription" component={Subscription} />
          <PrivateRoute exact path="/subscription-success" component={SuccessSubscription} />

          {/* to invoice and to collect routes */}
          <PrivateRoute exact path="/payment-reconciliations" component={PaymentReconciliations} />
          <PrivateRoute exact path="/billcard-success" component={BillSuccessCard} />
          <PrivateRoute exact path="/billing-details-os" component={BillDetailOS} />
          <PrivateRoute exact path="/billing-config" component={BillingConfig} />
          <PrivateRoute exact path="/billing" component={Billing} />

          {/* generate invoice routes */}
          <PrivateRoute exact path="/generate-invoice" component={CreateInvoice} />
          <PrivateRoute exact path="/invoice-success" component={Invoice} />
          <PrivateRoute exact path="/plus" component={Plus} />

          {/* ruta a la que se redirige cuando se hace click en el 
                  boton de generar planilla de imputacion */}
          <PrivateRoute exact path="/generate-sifo" component={GenerateSIFO} />
          <PrivateRoute exact path="/filing-history" component={FilingHistory} />
          <PrivateRoute exact path="/turnos" component={Turnos} />
          <PrivateRoute exact path="/turnos/edit" component={EditTurnos} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/scann-qr" component={QrScannerComponent} />
        </Router>
      </AuthProvider>
    </ContextProvider>
  );
};

export default App;
