import React, { useCallback, useContext, useState } from 'react';
import './Login.css';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import LogoGoogle from '../../assets/googleIcon.PNG';

// libraries
import { Button } from 'antd-mobile';
import { withRouter, Redirect, useHistory } from 'react-router';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// constants
import texts from '../../constants/texts';

// utils
import { db, auth } from '../../base.js';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../authentication/Auth.js';
import { AppContext } from '../../context/provider';
import { useLocalStorage } from '../../hooks';
import { Box, Grid } from '@mui/material';

// hooks
import useGoogleAccount from '../../hooks/useGoogleAccount.js';

const Login = () => {
  const [state, setState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();  
  const {handleGoogle} = useGoogleAccount();
  const [storedCurrentUser, setStoredCurrentUser] = useLocalStorage(
    'storedCurrentUser',
    {}
  );
  const {
    pages: {
      login: {
        forgotPasswordText, getIntoText, signupText, loginText
      }
    }
  } = texts;

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      const { email, password } = event.target.elements;
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async (userCredential) => {
            const userRef = doc(db, 'users', userCredential.user.uid);
            const userSnap = await getDoc(userRef);
            const user = userCredential.user;
            if (userSnap.exists() && user.emailVerified) {
              setState({
                ...state,
                currentUser: {
                  ...userSnap.data(),
                  id: userCredential.user.uid,
                },
              });
              setStoredCurrentUser({
                ...userSnap.data(),
                id: userCredential.user.uid,
              });
              history.replace('/');
            } else {
              Swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: 'Necesitas verificar tu correo electrónico para poder iniciar sesión',
              });
              setLoading(false)
            }
          })
          .catch((error) => {
            Swal.fire({
              title: `Error ${error.code}`,
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            setLoading(false)
          });
      } catch (error) {
        setLoading(false)
        throw new Error(`Error: ${error}, CurrentUser: ${storedCurrentUser}`);

      };
    },
    [history] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) <Redirect to='/' />;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <div className='login-container'>
            <div className="login-container-panel">
              <div className='login-container-panel__logo'>
                <Logo />
              </div>
              <h2>{getIntoText}</h2>
              <form onSubmit={handleLogin} className='login'>
                <input
                  name='email'
                  type='email'
                  placeholder='Email'
                  className='login__field'
                  disabled={loading ? true : false}
                />
                <input
                  name='password'
                  type='password'
                  placeholder='Password'
                  className='login__field'
                  disabled={loading ? true : false}
                />
                <Button
                  type='submit'
                  block
                  color='success'
                  disabled={loading ? true : false}
                >
                  {loginText}
                </Button>
              </form>
              <>
                <Link className='link' to='/selectsignup'>
                  {signupText}
                </Link>
                <Link className='link' to='/forgot-password'>
                  {forgotPasswordText}
                </Link>
                {/* <Box
                  sx={{
                    my: 2,
                    border: '2px solid green',
                    borderRadius: '20px',
                    display: 'inline-block',
                    p: 1,
                  }}>
                  <Link className='link' to='/patient-login' style={{ textDecoration: 'none' }} >
                    <Typography sx={{ fontWeight: 'bold', color: 'green' }}>
                      SOY PACIENTE
                    </Typography>
                  </Link>
                </Box> */}
              </>
              <>
                <Button onClick={handleGoogle}>
                  <img src={LogoGoogle} alt="Logo del google" />
                </Button>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>

  );
};

export default withRouter(Login);
