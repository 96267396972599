import React from 'react'

// libraries
import { Row, Col } from 'antd'

//utils
import './Table.css'


export const ListClaims = ({ title = '', children }) => {



  return (
    <>
      <div className="billDetailOS__container">
        {/* <h2 className="billDetailOS__OStypeTitle">{title} </h2> */}
        <Row justify="space-between" className='billDetailOS__header'>
          <Col span={14} className="title-name">OBRA SOCIAL | Monto</Col>
          {/* <Col span={5} className="title-claim">MONTO</Col> */}
          {/* <Col span={6} className="title-amount">MONTO</Col> */}
        </Row>
        <Row justify="space-between" className='billDetailOS__header'>
          <Col span={14} className="title-name">
            {children}</Col>
        </Row>
      </div>
    </>
  )
}
