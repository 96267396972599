import React from 'react';
import './ChartOrganization.css';

// libraries
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash'
// constants
import texts from '../../constants/texts';
import { useAccounting, usePayments } from '../../hooks';
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      align: 'end',
      padding: {
        right: 30,
        left: 30,
        top: 30,
        bottom: 30,
      },

      font: {
        size: 12,
      },
      labels: {
        value: {
          color: 'green',
        },
      },
      formatter: function (value, context) {
        let total = context.chart.data.datasets[0].data.reduce(
          (ac, x) => ac + x
        );
        return (
          _.truncate(context.chart.data.labels[context.dataIndex], { length: 30,
            separator: '...',}) +
          '\n' +
          ((value * 100) / total).toFixed(2) +
          '%'
        );
      },
    },
  },
  layout: {
    padding: 90,
  },
};



export const ChartOrganization = ({ claims , list }) => {
  const {
    pages: {
      billing: {
        components: {
          chartOrganization: { ordersText, amountText },
        },
      },
    },
  } = texts;

  const { getTotalAmount } = usePayments();
  const { formatedCurrency } = useAccounting()
  const labels = list.map(({organization}) => organization)
  const quantity = list.map(({quantity}) => quantity)
  const data = {
    labels,
    datasets: [
      {
        data: quantity,
        backgroundColor: ['#A89D80', '#CBC3B1', '#B9AF96', '#E6E3D9'],
        borderWidth: 3,
      },
    ],
  };

  return (
    <>
      <div className='chart__container'>
        <div className='chart__graph'>
          <Doughnut
            width='250px'
            height='250px'
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
          />
        </div>
        <div className='chart__info'>
          <h3> {ordersText}:</h3>
          <div
            className='chart__info--data'
            style={{ color: 'var(--adm-color-success)' }}
          >
            {claims.length}
          </div>
          <div className='separator'></div>
          <h3>{amountText}:</h3>
          <div className='chart__info--data'> {formatedCurrency(getTotalAmount(claims))} </div>
        </div>
      </div>
    </>
  );
};
