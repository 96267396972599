/*eslint-disble*/
import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { addDoc, collection } from 'firebase/firestore';
import { db, uploadImageFile } from '../../base';
import Cardimages from '../patientCards/Cardimages';
import Swal from 'sweetalert2';
import { AppContext } from '../../context/provider';
import usePatient from '../../hooks/usePatient';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

const LabComponent = () => {
  // eslint-disable-next-line
  const [state, setState] = useContext(AppContext);
  const { currentPatient, laboratorio } = state;
  const {TraerLaboratorio} = usePatient();
  const [imagenes, setImagenes] = useState([]);
  const [imagen, setImagen] = useState();
  const [imagenNAme, setImagenNAme] = useState();
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {

    if(laboratorio.length === 0){
      TraerLaboratorio()
    }else {
      setImagenes(laboratorio);
    }
    // eslint-disable-next-line
  }, [laboratorio])

  const saveImages = () => {
    Swal.fire({
      title: 'Seleccionar imagen',
      showCancelButton: true,
      confirmButtonText: 'Seleccionar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById('upload-button').click();
      }
    });
  };
  const limpiarCampos = () => {
    setImagen();
    setImagenNAme()
  }

  const changeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImagen(file)
        const fileName = file.name;
        setImagenNAme(fileName)
      } else {
        alert('El archivo seleccionado no es una imagen.');
      }
    } else {
      alert('No se ha seleccionado ningún archivo.');
    }
  };

  const saveToFirebase = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const url = await await uploadImageFile(imagen, imagenNAme, 'images')
      const currentDate = new Date();
      await addDoc(collection(db, 'laboratorio'), {
        userId: currentPatient.id,
        urlimagen: url,
        uploadDate: currentDate, // Agrega la fecha al documento
      });

      const newImagen = {
        userId: currentPatient.id,
        urlimagen: url,
        uploadDate: currentDate, // Agrega la fecha al documento
      };

      setState({ ...state, laboratorio: [...state.laboratorio, newImagen] });

      Swal.fire({
        text: `imagen guardada`,
        icon: 'success',
        confirmButtonText: 'Ok',
      });
      limpiarCampos();
      setLoading(false);
    } catch (error) {

      console.log(error);
      Swal.fire({
        text: `Error al guardar`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      limpiarCampos();
      setLoading(false);

    }

  }

  const handleShare = async () => {
    console.log('en funcionalidad')
  };


  return (
    <Box sx={{ border: "3px solid #616A6B" }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }} spacing={2} my={2}>
        <input
          type="file"
          multiple
          onChange={changeInput}
          style={{ display: 'none' }}
          id="upload-button"
        />
        <Button
          startIcon={imagenNAme ? <SaveIcon /> : <AddIcon />}
          onClick={imagenNAme ? saveToFirebase : saveImages}
          sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
          {imagenNAme ? "Guardar" : "Agregar"}
        </Button>
        <Button
          startIcon={<SendToMobileIcon />}
          onClick={handleShare}
          sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
          Compartir
        </Button>
      </Box>
      <Box direction="row" spacing={2} my={4}>
        <Typography>{loading ? "Cargando...." : "LABORATORIO"}</Typography>
        {imagenNAme && <Typography>{imagenNAme}</Typography>}
      </Box>
      <Grid container spacing={2} px={1}>
        {imagenes.length === 0
          ? null
          : imagenes.map((card) => (<Cardimages key={card.id} card={card} />))}
      </Grid>
    </Box>
  )
}

export default LabComponent