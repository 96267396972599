import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import useFilingHistory from '../../hooks/useFlingHistory';
import './FilingHistory.css';
import FilingStyle from './FilingStyle';

export const FilingHistory = () => {

    const { meses, anios, arrdni, arrobraS, arroName } = useFilingHistory()

    const [mes, setMes] = useState(null)
    const [anio, setAnio] = useState(null)
    const [dni, setDni] = useState(null)
    const [obra, setObra] = useState(null)
    const [name, setName] = useState(null)



    const handleChange = (e) => {
        console.log(`Seleccionaste año ${e.target.value}`);
        setAnio(e.target.value);
    }

    const handleChangeMes = (e) => {
        console.log(`Seleccionaste mes ${e.target.value}`);
        setMes(e.target.value);
    }

    const handleChangeDni = (e) => {
        console.log(`Seleccionaste dni ${e.target.value}`);
        setDni(e.target.value);
    }

    const handleChangeOs = (e) => {
        console.log(`Seleccionaste obra social ${e.target.value}`);
        setObra(e.target.value);
    }
    const handleChangeName = (e) => {
        console.log(`Seleccionaste año ${e.target.value}`);
        setName(e.target.value);
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log(`Seleccionaste año ${anio} y mes ${mes}`);
    // }

    return (
        <div className="container">
            <h3>Historial de presentados</h3>
            {arrdni === undefined
                ? <div className="col-12">
                    <h5 style={FilingStyle.texto}>Cargando...</h5>
                </div>
                : <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Selecciona el año</h5>
                            <select value={anio} onChange={handleChange}>
                                {anios.map(anio => <option key={anio.value} value={anio.anio}>{anio.anio}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Selecciona el mes</h5>
                            <select value={mes} onChange={handleChangeMes}>
                                {meses.map(mes => <option key={mes.Id} value={mes.Id}>{mes.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Selecciona el dia</h5>
                            <select value={mes} onChange={handleChangeMes}>
                                {meses.map(mes => <option key={mes.Id} value={mes.Id}>{mes.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Seleccione obra social</h5>
                            <select value={obra} onChange={handleChangeOs}>
                                {arrobraS.map(item => <option key={item.value} value={item.value}>{item.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>DNI del paciente </h5>
                            <select value={dni} onChange={handleChangeDni}>
                                {arrdni.map(item => <option key={item.value} value={item.value}>{item.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Nombre del paciente</h5>
                            <select value={name} onChange={handleChangeName}>
                                {arroName.map(item => <option key={item.value} value={item.value}>{item.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <label className='label'>
                            <h5 style={FilingStyle.texto}>Codigo de prestación</h5>
                            <select value={mes} onChange={handleChangeMes}>
                                {meses.map(mes => <option key={mes.Id} value={mes.Id}>{mes.Title}</option>)}
                            </select>
                        </label>
                    </div>
                    <div className="col-12">
                        <Button
                            style={{ width: '100%' }}
                            variant="success">Filtrar</Button>
                    </div>
                </div>}
        </div >
    )
}
