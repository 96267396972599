import { StyleSheet } from '@react-pdf/renderer'

const FilingStyle = StyleSheet.create({
    texto :{
        width: '100%',
        textAlign: 'center',
        paddingInline: '5px',
        paddingBlock: '10px',
        background: '#7F8C8D',
        color: 'white'
    },
})
  

export default FilingStyle