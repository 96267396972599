import axios from 'axios';
import qs from 'qs';
import { useContext } from 'react';
import afipParams from '../constants/afip-params';
import { AppContext } from '../context/provider';

export const useAFIP = () => {
  const [state, setState] = useContext(AppContext)


  //TODO: data structure for invoice - refactor
  let data = qs.stringify({
    'Auth[Token]': afipParams.token,
    'Auth[Sign]': afipParams.sign,
    'Auth[Cuit]': afipParams.cuit,
    'FeCAEReq[FeCabReq][CantReg]': '1',
    'FeCAEReq[FeCabReq][PtoVta]': '1',
    'FeCAEReq[FeCabReq][CbteTipo]': '15',
    'FeCAEReq[FeDetReq][FECAEDetRequest][Concepto]': '2',
    'FeCAEReq[FeDetReq][FECAEDetRequest][DocTipo]': '96',
    'FeCAEReq[FeDetReq][FECAEDetRequest][DocNro]': '36867905',
    'FeCAEReq[FeDetReq][FECAEDetRequest][CbteDesde]': afipParams.ultimoCBTE,
    'FeCAEReq[FeDetReq][FECAEDetRequest][CbteHasta]': afipParams.ultimoCBTE,
    'FeCAEReq[FeDetReq][FECAEDetRequest][CbteFch]': '20211106',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpTotal]': 'total',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpTotConc]': '0',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpNeto]': '8000',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpOpEx]': '0',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpTrib]': '0',
    'FeCAEReq[FeDetReq][FECAEDetRequest][ImpIVA]': '0',
    'FeCAEReq[FeDetReq][FECAEDetRequest][FchServDesde]': afipParams.FchServDesde,
    'FeCAEReq[FeDetReq][FECAEDetRequest][FchServHasta]':afipParams.FchServHasta,
    'FeCAEReq[FeDetReq][FECAEDetRequest][FchVtoPago]': afipParams.FchVtoPago,
    'FeCAEReq[FeDetReq][FECAEDetRequest][MonId]': 'PES',
    'FeCAEReq[FeDetReq][FECAEDetRequest][MonCotiz]': '1',
  });

  const config = {
    method: 'post',
    url: 'https://kani-afip-api.herokuapp.com/api/wsfev1/FECAESolicitar',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  };

  const generateInvoice = (total) => {
    axios(config)
      .then((response) => {
        setState({ ...state, responseData: response.data, total });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
    return {
        generateInvoice,
  }
}
