import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Selector,
  Space,
} from 'antd-mobile';
import { Select } from 'antd';
import Header from '../../layout/Header/Header';

import './BillingConfig.css';

export const BillingConfig = () => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <>
      <Header title='Configuracion AFIP'>Configuracion AFIP</Header>
      <div>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='form__container'
          footer={
            <Button
              block
              color='success'
              type='submit'
              className='form__button'
            >
              GUARDAR
            </Button>
          }
        >
          <Form.Item name='cuit' label='CUIT'>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  value='2036867905'
                  placeholder='XX-XXXXXXXX-X'
                />
              )}
              name='cuit'
              control={control}
            />
          </Form.Item>
          <Form.Item name='ptovta' label='Punto de Venta'>
            <Controller
              render={({ field }) => (
                <Selector
                    {...field}
                  options={[
                      {value: 1, label: 1},
                      {value: 2, label: 2},
                      {value: 3, label: 3}
                    ]}
                  defaultValue={['1']}
                  onChange={(arr, extend) => console.log(arr, extend.items)}
                />
              )}
              name='cuit'
              control={control}
            />
          </Form.Item>
          <Form.Item name='select' label='SELECT'>
            <Controller
              name='iceCreamType'
              render={({ field }) => (
                <Select
                  {...field}
                  style={{ width: '100%' }}
                  options={[
                    { value: 'choco-late', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' },
                  ]}
                />
              )}
              control={control}
              defaultValue=''
            />
          </Form.Item>
          <Controller
            name='Checkbox'
            control={control}
            render={({ checked }) => <Checkbox {...checked} />}
          />
        </Form>
        <Space size='large' />
        <Divider> MOMENTANEO - QUITAR LUEGO </Divider>
        <Card>
          <Button block color='warning'>
            REGENERAR TOKEN
          </Button>
        </Card>
      </div>
    </>
  );
};
