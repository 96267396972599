import { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/provider";

const useFilingHistory = ()=>{


  const state = useContext(AppContext);
  const json = state.currentUser === undefined ? state[0] : state
  const [arrdni, setDni] = useState() 
  const [arrobraS, setObraS] = useState() 
  const [arroName, setNAme] = useState() 

  useEffect(() => {
    
  const dniarr = [] 
  const osarr = [] 
  const namearr = [] 

    
  json.userClaims.map(
    (item) => { 
      dniarr.push({ value: item.dni, Title: item.patient +' - DNI: '+ item.dni  });
      osarr.push({ value: item.autorizationCode, Title: item.organization });
      namearr.push({ value: item.patient, Title: item.patient });

      // Agregamos un valor de retorno
      return null;
    }
    
    );
  

  setDni(dniarr)
  setObraS(osarr)
  setNAme(namearr)
  
    console.log('---> ',JSON.stringify(arrdni, null, 5));
    console.log('---> ',JSON.stringify(osarr, null, 5));
    console.log('---> ',JSON.stringify(namearr, null, 5));
    // eslint-disable-next-line
  }, [json.userClaims])
  // eslint-disable-next-line
  
    const meses = [
        {Id: 0, Title: "MESES"},
        {Id: 1, Title: "Enero"},
        {Id: 2, Title: "Febrero"},
        {Id: 3, Title: "Marzo"},
        {Id: 4, Title: "Abril"},
        {Id: 5, Title: "Mayo"},
        {Id: 6, Title: "Junio"},
        {Id: 7, Title: "Julio"},
        {Id: 8, Title: "Agosto"},
        {Id: 9, Title: "Septiembre"},
        {Id: 10, Title: "Octubre"},
        {Id: 11, Title: "Noviembre"},
        {Id: 12, Title: "Diciembre"},
    
      ];
      const anios = [
        {value: 1000,  anio: "AÑO"},
        {value: 2024,  anio: "2024"},
        {value: 2023,  anio: "2023"},
        {value: 2022,  anio: "2022"},
        {value: 2021,  anio: "2021"},
        {value: 2020,  anio: "2020"},
        {value: 2019,  anio: "2019"},
        {value: 2018,  anio: "2018"},
        {value: 2017,  anio: "2017"},
        {value: 2016,  anio: "2016"},
        {value: 2015,  anio: "2015"},
        {value: 2014,  anio: "2014"},
        {value: 2013, anio: "2013"},
        {value: 2012, anio: "2012"},
        {value: 2011, anio: "2011"},
        {value: 2010, anio: "2010"},
        {value: 2009, anio: "2009"},
        {value: 2008, anio: "2008"},
        {value: 2007, anio: "2007"},
        {value: 2006, anio: "2006"},
        {value: 2005, anio: "2005"},
        {value: 2004, anio: "2004"},
        {value: 2003, anio: "2003"},
        {value: 2002, anio: "2002"},
        {value: 2001, anio: "2001"},
        {value: 2000, anio: "2000"},
        ];
  
       




  return {meses, anios, arrdni, arrobraS, arroName}

}

export default useFilingHistory;