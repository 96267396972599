import React from 'react'
import accounting from 'accounting-js';
import { Link } from 'react-router-dom';
import { Col, Collapse, Row } from 'antd';
import './Table.css'

export const PatientsList = ({list, claims}) => {
  const { Panel } = Collapse;

    return (
        <ul style={{padding: '0'}}>
          {list.map((org) => (
            <Link
              key={org.id}
              to={{
                pathname: '/billing-details-os',
                state: { organization: org, claims: claims }
              }}
            >
              <li className="bill-detail-os__patient-item" key={org.id}>
                <Collapse collapsible="disabled" defaultActiveKey={[]} ghost>
                  <Panel showArrow={false} header={
                    <Row style={{display: 'flex', alignItems: 'center'}}>
                      <Col span={14} className="name" style={{fontSize: '0.8rem'}}>{org.organization}</Col>
                      <Col span={5} className="claim" style={{fontSize: '1rem'}}>{org.quantity}</Col>
                      <Col span={5} className="amount" style={{fontSize: '1rem'}}>{accounting.format(org.total)}</Col>
                    </Row>
                  } key={org.id}/>
                </Collapse>
              </li>
             </Link>
            ))
          }
        </ul>
      )
}
