import React from 'react';

// libraries
import { Document, Page, StyleSheet, /*View, Text*/ } from '@react-pdf/renderer';

// utils
// import { formatDateDDMMYYYY } from '../utils/dateUtils';
import InvoiceHeader2 from './InvoiceHeader2';
import InvoiceFooter2 from './InvoiceFooter2';

export const PdfStructure = ({ responseData, requestData, total, selectedClaims }) => {
  const {
    currentUser: {
      name,
      speciality,
      medicalLicence,
    },
    responseData: {
      FeCabResp: {
        CbteTipo
      }
    }
  } = responseData;

  //incluir en responseData
  // const CAE = responseData.responseData.FeDetResp.FECAEDetResponse[0].CAE;
  
  const {
    FchServDesde,
    FchServHasta,
    FchVtoPago
  } = requestData
  
  const organizationsToPrint = selectedClaims && selectedClaims.map(claim => claim.organization);
  const nonDuplicatedSet = new Set(organizationsToPrint);
  const nonDuplicatedArray = Array.from(nonDuplicatedSet);

  return(
    <Document
      author='Kani salud'
      subject='Kani salud'
      title='Planilla de presentacion'
    >
      {
        nonDuplicatedArray.map((org, i) => {
          const filteredSelectedClaims = selectedClaims.filter(claim => claim.organization === org);
          const subtotal = filteredSelectedClaims.reduce((acc, claim) => acc + claim.price, 0);

          return(
            <Page style={styles.page} size='A4' key={i} orientation="landscape">
              <InvoiceHeader2
                selectedClaims={filteredSelectedClaims}
                name={name}
                speciality={speciality}
                medicalLicence={medicalLicence}
                FchServDesde={FchServDesde}
                FchServHasta={FchServHasta}
                FchVtoPago={FchVtoPago}
                CbteTipo={CbteTipo}
                organization={org}
              />
              <InvoiceFooter2
                total={subtotal}
                quantityOfPages={nonDuplicatedArray.length}
                currentPage={nonDuplicatedArray.indexOf(org)+1}
              />
              {/* <View>
                <Text> Razon Social: {name} </Text>
                <Text> Tipo comprobante: 0{ CbteTipo } {  } </Text>
                <Text> Periodo facturado desde: { formatDateDDMMYYYY(FchServDesde) }  -  hasta: { formatDateDDMMYYYY(FchServHasta) } </Text>
                <Text> Fecha de vencimiento: { formatDateDDMMYYYY(FchVtoPago) } </Text>
                <Text> CAE: { CAE } </Text>
                <Text> Subtotal: {  } </Text>
              </View> */}
            </Page>
        )})
      }
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'space-around',
    padding: 60,
    color: '#8D8F8D',
    fontSize: 14,
  },
});
