import React, { useCallback, useContext, useState } from 'react';
import './Login.css';
// import { ReactComponent as Logo } from '../../assets/logo.svg';
import LogoPatient from '../../assets/captura.png';

// libraries
import { Button } from 'antd-mobile';
import { Redirect, useHistory } from 'react-router';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


// utils
import { db, auth } from '../../base.js';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../authentication/Auth.js';
import { AppContext } from '../../context/provider';
import { useLocalStorage } from '../../hooks';
import { Box, Grid } from '@mui/material';

// hooks

const PatienLogin = () => {
  const [state, setState] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [storedcurrentPatient, setStoredcurrentPatient] = useLocalStorage(
    'storedcurrentPatient',
    {}
  );


  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      const { email, password } = event.target.elements;
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async (userCredential) => {
            const patientRef = doc(db, 'patients', userCredential.user.uid);
            const patientSnap = await getDoc(patientRef);
            const user = userCredential.user;
            if (patientSnap.exists() && user.emailVerified) {
              setState({
                ...state,
                currentPatient: {
                  ...patientSnap.data(),
                  id: userCredential.user.uid,
                },
                currentPatientParent: {
                  ...patientSnap.data(),
                  id: userCredential.user.uid,
                },
              });
              setStoredcurrentPatient({
                ...patientSnap.data(),
                id: userCredential.user.uid,
              });
              history.replace('/patient-home');
            } else {
              Swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: 'Necesitas verificar tu correo electrónico para poder iniciar sesión',
              });
              setLoading(false)
            }
          })
          .catch((error) => {
            Swal.fire({
              title: `Error ${error.code}`,
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            setLoading(false)
          });
      } catch (error) {
        setLoading(false)
        throw new Error(`Error: ${error}, currentPatient: ${storedcurrentPatient}`);

      };
    },
    [history] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { currentPatient } = useContext(AuthContext);

  if (currentPatient) <Redirect to='/patient-home' />;
  

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <div className='login-container'>
            <div className="login-container-panel">
              {/* <div className='login-container-panel__logo'> */}
                <img src={LogoPatient} alt="Logo del paciente" />
              {/* </div> */}
              <h2 style={{ color: 'grey' }}>Iniciar sesión</h2>
              <form onSubmit={handleLogin} className='login'>
                <input
                  name='email'
                  type='email'
                  placeholder='Email'
                  className='login__field'
                  disabled={loading ? true : false}
                />
                <input
                  name='password'
                  type='password'
                  placeholder='Password'
                  className='login__field'
                  disabled={loading ? true : false}
                />

                <Button
                  type='submit'
                  block
                  color='success'
                  disabled={loading ? true : false}
                >
                  {loading ? "Cargando..." : "Acceder"}
                </Button>
              </form>
              <>
                <Link className='link' to='/patient-register' style={{ textDecoration: 'none' }}  >
                  <h5 style={{ color: 'grey' }}>Registrarme</h5>
                </Link>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>

  );
};



export default PatienLogin