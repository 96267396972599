/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./EditPatient.css";

// libraries
import { Button, Divider, Space } from "antd-mobile";
import { withRouter } from "react-router";
import { useForm } from 'react-hook-form';
import { doc, setDoc } from "@firebase/firestore";
import Swal from 'sweetalert2';

// utils
import { db } from "../../base";
import { AppContext } from '../../context/provider';

// components
import Header from '../../layout/Header/Header';

// constant
import texts from '../../constants/texts';
import { Grid } from "@mui/material";

const CustomSelect = ({ options, setSelectedOpt, fieldName, defaultOpt }) => {
  return (
    <select
      name='empty'
      onChange={(e) => setSelectedOpt(options.filter(opt => opt.name === e.target.value)[0])}
      value={defaultOpt && defaultOpt.name}
      multiple={false}
    >
      <option hidden> seleccione una opción </option>
      {options.map(opt => (
        <option
          key={opt.id}
          className='form-field__input'
          value={opt.name}
          name={fieldName}
        >
          {opt.name}
        </option>
      ))}
    </select>
  );
};

const EditPatient = ({ history }) => {
  const { register, handleSubmit } = useForm();
  const [state, setState] = useContext(AppContext);
  const { selectedPatient } = state;
  const [userInfo, setUserInfo] = useState(selectedPatient);
  const [organizations, setOrganizations] = useState([]);
  const { pages: { editPatient: {
    title,
    form: {
      nameLabel,
      affiliateLabel,
      dniLabel,
      addressLabel,
      localityLabel,
      provinceLabel,
      phoneLabel,
      phonePlaceholder,
      emailLabel,
      genderLabel,
      bloodgroupLabel,
      /* medicalrecordText, */
      saveText,
      selectOptions: {
        genders,
        bloodTypes,
      }
    },
  } } } = texts;
  const [selectedGender, setSelectedGender] = useState(/* selectedPatient.gender || null */ "");
  const [selectedBloodType, setSelectedBloodType] = useState(/* selectedPatient.bloodType || null */ "");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSelect = () => {
    setUserInfo({
      ...userInfo,
      gender: selectedGender,
      bloodType: selectedBloodType
    });
  };

  useEffect(() => {
    handleSelect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender, selectedBloodType]);

  useEffect(() => {
    setOrganizations(state.organizations);
  }, []);

  const handleSetPatient = async _ => {
    const patiendRef = doc(db, "patients", userInfo.dni);
    await setDoc(patiendRef, { ...userInfo, gender: selectedGender, bloodType: selectedBloodType });

    Swal.fire({
      title: 'Usuario modificado con exito',
      icon: 'success',
      confirmButtonText: 'Ok',
    });


    setState({ ...state, selectedPatient: userInfo });

    history.push('/')
  };

  const onSubmit = _ => handleSetPatient();

  const updateSelectedOrganization = organization => {
    setUserInfo({
      ...userInfo,
      organization,
    });
  };

  const handleSelectOrg = e => {
    const organizationId = e.target.value;
    const organization = organizations.find(
      organization => organization.id === organizationId
    );
    updateSelectedOrganization(organization.name);
  };

  const SelectOrganization = () => {
    return (
      <select className='organizations' onChange={handleSelectOrg}>
        <option hidden> {userInfo && userInfo.organization} </option>
        {organizations.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        ))}
      </select>
    );
  };

  return (
    <Grid container>
      <Header title={title} />
      <Space />
      <Grid item xs={12} md={3} sx={{ backgroundColor: '#fff' }}>
      </Grid>
      <Grid item xs={12} md={6} sx={{ backgroundColor: '#fff' }}>
        <div className="form-title"> Nuevo Paciente </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <li className="form-field">
            <label>{nameLabel}</label>
            <input
              /* value={userInfo.name} */
              {...register('name')}
              name="name"
              type="text"
              className="form-field__input" onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{affiliateLabel}</label>
            <input
              /* value={userInfo.affiliate} */
              {...register('affiliate')}
              name="affiliate"
              type="number"
              className="form-field__input" onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{dniLabel}</label>
            <input /* value={userInfo.dni} */ {...register('dni')} name="dni" type="number" className="form-field__input" onChange={handleInputChange} />
          </li>

          <SelectOrganization />

          {/* <li className="form-field">
            <label>{ organizationLabel }</label>
            <input
              {...register('organization')}
              //value={userInfo.organization}
              name="organization"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li> */}

          <li className="form-field">
            <label>{addressLabel}</label>
            <input
              {...register('address')}
              /* value={userInfo.address} */
              name="address"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{localityLabel}</label>
            <input
              {...register('locality')}
              /* value={userInfo.locality} */
              name="locality"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{provinceLabel}</label>
            <input
              {...register('province')}
              /* value={userInfo.province} */
              name="province"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{phoneLabel}</label>
            <input
              /* value={userInfo.phone} */
              {...register('phone')}
              name="phone"
              type="number"
              placeholder={phonePlaceholder}
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{emailLabel}</label>
            <input
              /* value={userInfo.email} */
              {...register('email')}
              name="email"
              type="text"
              className="form-field__input"
              onChange={handleInputChange}
            />
          </li>

          <li className="form-field">
            <label>{genderLabel}</label>
            <CustomSelect
              options={genders}
              setSelectedOpt={setSelectedGender}
              fieldName='gender'
              defaultOpt={selectedGender}
            />
          </li>

          <li className="form-field">
            <label>{bloodgroupLabel}</label>
            <CustomSelect
              options={bloodTypes}
              setSelectedOpt={setSelectedBloodType}
              fieldName='bloodType'
              defaultOpt={selectedBloodType}
            />
          </li>

          {/* <li className="form-field" style={{ flexDirection: 'row' }}>
            <label>{ medicalrecordText }</label>
            <Button className="form-button__medicalRecord" onClick={() => {}} color="primary"> VER </Button>
          </li> */}

          <Divider />
          <Button type="submit" block color='success'>{saveText}</Button>
          <Space />
          {/* <Button onClick={cancelEdit} block color='primary'>{ cancelText }</Button> */}
        </form>
        </Grid>
      <Grid item xs={12} md={3} sx={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center' }}>
      </Grid>
    </Grid>
  );
};

export default withRouter(EditPatient);
