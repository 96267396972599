/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { Button, Divider, Space } from 'antd-mobile';

import Swal from 'sweetalert2';
import { AppContext } from '../../context/provider';
import { useAccounting } from '../../hooks';
import Header from '../../layout/Header/Header';
import './InvoiceSuccess.css';
import { TextOutline } from 'antd-mobile-icons'
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfStructure } from '../../pdf';
//mock
import pdfMock from '../../mock/pdf-mock.json';
import afipParams from '../../constants/afip-params';
import { uploadFile } from '../../base';

import { saveAs } from 'file-saver';
import { sendEmailUtilswithPdf } from '../../utils/sendEmail';
import useGenerateSIFO from '../../hooks/useSifo';




export const Invoice = () => {
  return (
    <div className='generate-invoice'>
      <Header title={'Presentación'} isSubscripter={true} />
      <div className='container animate__animated animate__fadeIn animate__faster body'>
        <h2 className='title'>
          ¡Bien Hecho! <br />  <span>Documentación presentada</span>
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card />
        </div>

        <Actions />
      </div>
    </div>
  );
};

const Card = () => {
  const [state] = useContext(AppContext);
  const { presentation } = state;
  const { formatedCurrency } = useAccounting();
  const { presentationClaims, total } = presentation;

  return (
    <div className='presentation-card'>
      {presentationClaims.map(({ organization, quantity, total }, i) => (
        <div key={i}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col className='claim__organization' style={{ fontSize: '0.8rem' }}>
              {organization}
            </Col>
          </Row>
          <Row className='detail'>
            <Col span={5} className='claim' style={{ fontSize: '1rem' }}>
              x{quantity}
            </Col>
            <Col span={8} className='amount' style={{ fontSize: '1rem' }}>
              {formatedCurrency(total)}
            </Col>
          </Row>
          <Divider className='separator' />
        </div>
      ))}
      <Row className='detail-footer'>
        <Col className='claim-footer' style={{ fontSize: '1.25rem' }}>
          Total:
        </Col>
        <Col className='amount-footer' style={{ fontSize: '1.25rem' }}>
          {formatedCurrency(total)}
        </Col>
      </Row>
    </div>
  );
};


const Actions = () => {
  // const [show, setShow] = useState(true)


  return (
    <Space direction='vertical' className='footer' justify='between' style={{ width: '95%', marginBottom: '1.5rem', float: 'inline-end' }}>
      {/* <Button block color='primary' onClick={() => setShow(prev => !prev)}>
        ENVIAR POR MAIL
      </Button> */}
      <Documentation />
      <PDFComponent />
    </Space>
  )
}


const Documentation = ({ style }) => {
  const [file, setFile] = useState(null)
  const [disableButton, setDisableButton] = useState(true)

  const [state] = useContext(AppContext)
  const {
    codigoSifo,
    generarCodigoSifo,
  } = useGenerateSIFO()


  useEffect(() => {
    generarCodigoSifo()
  }, [])


  function obtenerFechaMesAnterior() {
    let hoy = new Date();

    // Obtener el último día del mes anterior
    let ultimoDiaMesAnterior = new Date(hoy.getFullYear(), hoy.getMonth(), 0);

    // Formatear la fecha en AAAAMM
    let año = ultimoDiaMesAnterior.getFullYear().toString();
    let mes = (ultimoDiaMesAnterior.getMonth() + 1).toString().padStart(2, '0');

    return año + mes;
  }

  const sendEmail = async (event) => {
    event.preventDefault();

    // await procesarSifo();

    const blob = new Blob([file], { type: 'application/pdf' });

    try {
      const downloadUrl = await uploadFile(blob)
      sendEmail2(downloadUrl)
    } catch (error) {
      console.log('error', error)
    }


  }

  const sendEmail2 = async (downloadUrl) => {

    console.log('sifo....', codigoSifo)
    const fecha_anterior = obtenerFechaMesAnterior()

    const cuentaData = [];
    codigoSifo.Periodo_de_Facturación.map((index) =>
      cuentaData.push({
        codigo:
          codigoSifo.Tipo                              //1  OK
          + codigoSifo.Número_de_Socio                   //2  OK
          + codigoSifo.Código_de_OS                      //3  OK
          // + codigoSifo.Periodo_de_Facturación[index]     //4  OK puede ser item
          + fecha_anterior
          + codigoSifo.Número_de_Autorización[index]     //5  OK
          + codigoSifo.Número_de_Afiliado                //6  OK
          + codigoSifo.Fecha_de_prestación[index]        //7  OK
          + codigoSifo.Médico_Solicitante[index]         //8  OK
          + codigoSifo.Diagnostico[index]                //9  OK
          + codigoSifo.Cero_1                            //10 OK
          + codigoSifo.Código_de_prestación[index]       //11 OK
          + codigoSifo.Funcion                           //12 OK
          + codigoSifo.Cantidad[index]                   //13 OK              
          + codigoSifo.Recargo[index]                    //14 OK
          + codigoSifo.Cero_2                            //15 OK DECIMALES DEL RECARGO
          + codigoSifo.Primera_consulta[index]           //16 OK
          + codigoSifo.Cero_3                            //17 OK IMPORTE
          + codigoSifo.Nombre_del_Paciente[index]        //18 OK
          + codigoSifo.sexo[index]                       //19 OK
          + codigoSifo.Separador_70                      //20 OK 00
          + codigoSifo.Tipo_de_Matricula                 //21 OK
          + codigoSifo.Provincia                         //22 OK
          + codigoSifo.Numero_de_matricula               //23 OK
          + codigoSifo.Numero_de_orden[index]            //24 OK
          + codigoSifo.Espacio_en_blanco_2               //25 OK DNI
          + codigoSifo.DNI_del_afiliado[index]           //26 OK
          + codigoSifo.fecha_de_nacimiento[index]        //27 OK
      })
    );

    const { currentUser } = state;

    const email = currentUser.email;

    const txtContent = codigoSifo.Periodo_de_Facturación[0] == undefined ? 'No hay prestaciones en el corriente mes' : cuentaData.map(item => item.codigo).join('\n');
    const blob = new Blob([txtContent], { type: 'text/plain' });
    const fecha_de_presentacion = obtenerFechaMesAnterior()

    try {

      sendEmailUtilswithPdf(currentUser.email,
        'Prestaciones y SIFO',
        downloadUrl,
        `${codigoSifo.Periodo_de_Facturación[0] == undefined ? 'SIFO' : codigoSifo.Número_de_Socio + fecha_de_presentacion}.txt`,
        txtContent)
      Swal.fire({
        text: `el email se envió exitosamente a: ${email} `,
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
      alert('ocurrió un error al enviar el email ', error)
    }


  }

  const handleFileSelect = ({ target }) => {
    setFile(target.files[0])
    setDisableButton(false)
  }

  return (
    <div style={style}>
      <form
        onSubmit={sendEmail}
        className='generate_invoice__button'
      >
        <label>
          <TextOutline fontSize={30} />
          <input
            className='inputFile'
            type='file'
            onChange={handleFileSelect}
          />
        </label>
        <Button
          className=''
          size='small'
          color='success'
          type='submit'
          disabled={disableButton}
        >
          ENVIAR
        </Button>
      </form>
    </div>
  )
}

const PDFComponent = () => {
  const [state] = useContext(AppContext)
  const { currentUser, presentation } = state;
  const { total } = presentation


  const pdfInfo = { ...pdfMock, currentUser };
  const selectedClaims = useMemo(() => {
    const { presentation } = state;
    const { presentationClaims } = presentation;
    const arr = presentationClaims.map(c => c.data)
    return [].concat.apply([], arr)
  }, [state])

  const getFileName = () => {
    const date = new Date();
    const day = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return `planilla-presentacion-${year}-${month}-${day}_${hour}-${minutes}.pdf`;
  };

  const handleDownloadClick = async ({ blob }) => {
    const pdfFileName = await getFileName();
    saveAs(blob, pdfFileName);
    sendPdfByEmail(blob);
  };

  const sendPdfByEmail = async (fileContent) => {
    console.log('fileContent:', fileContent);

    const blob = new Blob([fileContent], { type: 'application/pdf' });

    try {
      const downloadUrl = await uploadFile(blob)
      sendEmail(downloadUrl)
    } catch (error) {
      console.log('error', error)
    }
  };


  const sendEmail = async (downloadUrl) => {

    try {

      const email = currentUser.email;

      await axios.post('http://localhost:5001/tani-3a53a/us-central1/sendEmail', { email, downloadUrl });
      Swal.fire({
        text: `el email se envió exitosamente a: ${email} `,
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
      alert('ocurrió un error al enviar el email ', error)
    }

  }



  return (

    <PDFDownloadLink
      document={
        <PdfStructure
          responseData={pdfInfo}
          requestData={afipParams}
          total={total}
          selectedClaims={selectedClaims}
        />
      }
      fileName={getFileName()}
    >
      <Button
        block
        color='success'
        onClick={() => handleDownloadClick({ blob: Blob })}
      >
        DESCARGAR DOCUMENTACIÓN
      </Button>

    </PDFDownloadLink>
  )
}