import React from 'react';
import { Card, Grid, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Typography } from 'antd';
import { formatFirebaseDate } from '../../utils/dateUtils';
import ShareIcon from '@mui/icons-material/Share';

const Cardimages = ({ card }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleShareImage = async () => {
        try {
            await navigator.share({
                title: 'Compartir imagen',
                text: 'Echa un vistazo a esta imagen',
                url: card.urlimagen,
            });
        } catch (error) {
            console.error('Error al intentar compartir:', error);
        }
    };

    return (
        <>
            < Grid item xs={6} sm={3} >
                <Card sx={{ height: { xs: 200, sm: 200 } }} onClick={() => { handleOpen() }}>
                    <img
                        src={card.urlimagen}
                        alt="Imagen 1"
                        style={{ width: '100%', objectFit: 'contain' }}
                    />
                </Card>
                {card.uploadDate && <Typography sx={{ color: 'black' }}>{formatFirebaseDate(card.uploadDate)}</Typography>}
            </Grid >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <img
                        src={card.urlimagen}
                        alt="Imagen 1"
                        style={{ width: '100%', objectFit: 'contain' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Button onClick={handleClose}>Cerrar</Button>
                        <Button endIcon={<ShareIcon />} onClick={handleShareImage}>Compartir</Button>
                    </Box>
                </Box>
            </Modal>
        </>);
}

export default Cardimages;
