import React, { useContext } from 'react';
import './Billing.css';

// libraries

import accounting from 'accounting-js';

// constants
import texts from '../../constants/texts';

// components
import Header from '../../layout/Header/Header';
import { ChartOrganization, ListClaims, PatientsList } from '../../components';
import { useClaims, usePayments } from '../../hooks';
import { AppContext } from '../../context/provider';
import { Grid, Box } from '@mui/material';


const Billing = () => {

  const [state] = useContext(AppContext);
  const { claimsReport } = state;
  const { getDayStr, getMonthStr, getTotalAmount } = usePayments()
  const { getClaimsOrganizationList } = useClaims();


  const ftotal = accounting.formatMoney(getTotalAmount(claimsReport));
  const fmonth = getMonthStr(claimsReport);
  const fday = getDayStr(claimsReport[0].presentationDay);

  const { pages: { billing: { processed } } } = texts;

  const list = getClaimsOrganizationList(claimsReport);

  return (
    <div className="billing">
      <Header title={processed} />
      <Grid container
      >
        <Grid item xs={12} md={4} >
          <Box p={1} borderWidth="1px" borderRadius="lg">
            <div className='header_data'>
              <div className='header__body--primary'>{fmonth}</div>
              <div>
                <div className='header__body--primary' style={{ marginLeft: '20px' }}> {fday} </div>
                <div className='header__body--secondary'>{ftotal} </div>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} >
          <Box p={1} borderWidth="1px" borderRadius="lg">
            <div className="container billing__container animate__animated animate__fadeIn animate__faster">
              <ListClaims>
                <PatientsList list={list} claims={claimsReport} />
              </ListClaims>
              {/* <ChartOrganization list={list} claims={claimsReport} /> */}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} >
          <Box p={1} borderWidth="1px" borderRadius="lg">
            <div className="container billing__container animate__animated animate__fadeIn animate__faster">
              <ChartOrganization list={list} claims={claimsReport} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Billing;