/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import './SignUp.css';

// libraries
import { Button } from 'antd-mobile';
import { withRouter } from 'react-router';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { sendEmailVerification, createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

// constants
import { PROVINCE } from '../../constants/mock-data';
import texts from '../../constants/texts';

// utils
import { db, uploadImageFile } from '../../base';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { AppContext } from '../../context/provider';

const initialState = {
  name: '',
  email: '',
  phone: '',
  adress: '',
  imageURL: '',
  subscriptionIsActive: false,
};

const CustomSelect = ({ options, setSelectedOpt, fieldName }) => {
  return (
    <>
      <select name='empty'
        onChange={(e) => setSelectedOpt(e.target.value)}>
        <option hidden> seleccione una opción </option>
        {options.map((opt) => (
          <option
            key={opt.id}
            className='form-field__input'
            value={opt.name}
            name={fieldName}>
            {opt.name}
          </option>
        ))}
      </select>
    </>
  );
};

const InstitutionSignUp = ({ history }) => {

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  //const { register, handleSubmit } = useForm()
  const [state] = useContext(AppContext);
  const { userGoogle } = state;
  const [formStep, setFormStep] = useState(1);
  const [userInfo, setUserInfo] = useState(initialState);
  //const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProv, setSelectedProv] = useState(null);
  const [messageError, setMessageError] = useState(false);
  const [imagesName, setImagesName] = useState();
  const [imageData, setImageData] = useState(userGoogle ? `${userGoogle.urlphoto}` : 'https://via.placeholder.com/200x200');
  const {
    pages: {
      signup: {
        form: {
          emailText,
          passwordText,
          phonePlaceholder,
          signupTextSubmit,
          loginLinkText,
          //categoryText,
          provinceText
        },
      },
    },
  } = texts;

  //Hacer validaciones de todos los inputs

  const jumpPrevStep = () => { setFormStep(formStep - 1) };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSelect = () => {
    setUserInfo({
      ...userInfo,
      //category: selectedCategory,
      province: selectedProv,
    });
  };

  useEffect(() => {
    register('name');
    register('email');
    register('phone');
    register('imageURL');
  }, [register]);
  
  useEffect(() => {
    if (userGoogle) {
      console.log('userGoogle', JSON.stringify(userGoogle, null, 5));
      setValue('name', userGoogle?.name);
      setValue('email', userGoogle?.email);
      setValue('phone', userGoogle?.phone ?? ''); 
      setValue('imageURL', userGoogle?.urlphoto ?? ''); 
    }
  }, [userGoogle, setValue]);


  useEffect(() => {
    handleSelect();
  }, [selectedProv]);

  const handleSaveUser = useCallback(
    async (user) => {
      try {
        const url = await uploadImageFile(imageData, imagesName, 'ProfileFolder');
        await setDoc(doc(db, 'users', user.uid), {
          ...userInfo,
          name: userInfo.name,
          email: userInfo.email,
          phone: userInfo.phone,
          adress: userInfo.adress,
          type: 'institution',
          imageURL: url,
          subscriptionIsActive: false,
        });
        Swal.fire({
          title: 'Usuario creado con exito',
          text: 'Un correo de verificacion fue enviado a ' + user.email,
          icon: 'success',
          confirmButtonText: 'Ok',
        });

        history.push('/login');
      } catch (error) {
        Swal.fire({
          title: `Error ${error.code}`,
          text: 'No se pudo crear el usuario: ' + error.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      };
    },
    [history, userInfo]
  );


  const handleSignUp =
    async (data) => {
      const { email, password } = data;
      try {
        const auth = getAuth();
        await createUserWithEmailAndPassword(auth, email, password)
          .then((userCredencial) => {
            const user = userCredencial.user;
            handleSaveUser(user);
            sendEmailVerification(user)
              .then(() => {
                // Email verification sent!
                console.log('Un link de verificacion fue enviado a ' + user.email)
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                throw new Error(`Error ${errorCode}: ${errorMessage}`);
              });
          });
      } catch (error) {
        let errorText;
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorText = 'El email ya existe.';
            break;
          case "auth/invalid-email":
            errorText = 'Formato de email no valido.'
            break;
          case "auth/weak-password":
            errorText = 'Contraseña débil.';
            break;
          default:
            errorText = 'Ocurrió un error.';
            break;
        };
        Swal.fire({
          title: 'Error al crear el usuario',
          text: errorText,
          icon: 'error',
          confirmButtonText: 'Ok',
        }).then(_ => {
          jumpPrevStep();
        });
      };
    };

  const onSubmit = (data) => {
    if ((selectedProv === null || selectedProv === '')) {
      setMessageError(true);
    } else {
      handleSignUp(data);
    }
  };

  const openImagePicker = () => {
    Swal.fire({
      title: 'Seleccionar imagen',
      showCancelButton: true,
      confirmButtonText: 'Seleccionar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById('upload-button').click();
      }
    });
  };

  const changeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImageData(file)
        const fileName = file.name;
        setImagesName(fileName)

      } else {
        alert('El archivo seleccionado no es una imagen.');
      }
    } else {
      alert('No se ha seleccionado ningún archivo.');
    }
  };

  return (
    <div className='page-container'>
      <div className='form-container'>
        <div className='form-container__logo'>
          <Logo className='logo' />
        </div>
        <div>
          <h2>
            Registro de centro medico
          </h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}
          autoComplete='off'
        >
          <section >
            <div
              style={
                {
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }
              }>
              <Card style={{
                width: '200px',
                backgroundSize: 'contain',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px'
              }
              }>
                {typeof imageData === 'string' ? (
                  <img
                    src={imageData}
                    alt="Imagen"
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(imageData)}
                    alt="Imagen"
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'contain',
                    }}
                  />
                )}
                <input
                  type="file"
                  multiple
                  onChange={changeInput}
                  style={{ display: 'none' }}
                  id="upload-button"
                />
                <Button size="small" onClick={openImagePicker}>
                  SELECCIONAR IMAGEN
                </Button>
              </Card>
            </div>

            <div className='form-field'>
              <label>Nombre</label>
              <input
                {...register('name', {
                  required: true,
                })}
                name='name'
                type='text'
                placeholder='Nombre de la institución'
                className='form-field__input'
                onChange={handleInputChange}
              />

              {errors.name && <p className='form-field__error'>Debe ingresar el nombre del centro médico</p>}
            </div>

            <div className='form-field'>
              <label>{emailText}</label>
              <input
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Debe ingresar un email',
                  }
                })}
                name='email'
                type='email'
                className='form-field__input'
                placeholder='ejemplo@ejemplo.com'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>{errors.email?.message}</p>
            </div>

            <div className='form-field'>
              <label>{passwordText}</label>
              <input
                {...register('password', {
                  required: 'Debe ingresar una contraseña',
                })}
                name='password'
                type='password'
                className='form-field__input'
                autoComplete='new-password'
              />
              <p className='form-field__error'>{errors.password?.message}</p>
            </div>


            {/* <div className='form-field'>
            <label>{categoryText}</label>
            <CustomSelect
              options={INSTITUTIONCATEGORY}
              setSelectedOpt={setSelectedCategory}
              fieldName='category'
            />
            {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
          </div> */}

            <div className='form-field'>
              <label>TELÉFONO</label>
              <input
                {...register('phone', {
                  required: 'Debe ingresar un telefono',
                })}
                name='phone'
                type='number'
                placeholder={phonePlaceholder}
                className='form-field__input'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>{errors.phone?.message}</p>
            </div>

            <div className='form-field'>
              <label>DIRECCIÓN</label>
              <input
                {...register('adress', {
                  required: 'Debe ingresar su dirección'
                })}
                name='adress'
                type='text'
                placeholder='Dirección del establecimiento'
                className='form-field__input'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>
                {errors.adress?.message}
              </p>
            </div>

            <div className='form-field'>
              <label>PISO</label>
              <input
                style={{
                  marginBottom: '10px'
                }}
                name='floor'
                type='number'
                placeholder='Piso'
                className='form-field__input'
                onChange={handleInputChange}
                onWheel={e => e.target.blur()}
              />
            </div>

            <div className='form-field'>
              <label>DEPARTAMENTO</label>
              <input
                style={{
                  marginBottom: '10px'
                }}
                name='apartment'
                type='text'
                placeholder='Departamento'
                className='form-field__input'
                onChange={handleInputChange}
              />
            </div>

            <div className='form-field'>
              <label>{provinceText}</label>
              <CustomSelect

                options={PROVINCE}
                setSelectedOpt={setSelectedProv}
                fieldName='province'
              />
              {messageError ? <p className='form-field__error'>Debe seleccionar una opción</p> : null}
            </div>

            <div className='form-field'>
              <label>LOCALIDAD</label>
              <input
                {...register('city', {
                  required: 'Debe ingresar su localidad'
                })}
                name='city'
                type='text'
                placeholder='Localidad'
                className='form-field__input'
                onChange={handleInputChange}
              />
              <p className='form-field__error'>
                {errors.associateNumber?.message}
              </p>
            </div>

            <div className='form-field'>
              <label>CANTIDAD DE CONSULTORIOS</label>
              <input
                name='consultingroom'
                type='number'
                placeholder='Ej: 4'
                className='form-field__input'
                onWheel={e => e.target.blur()}
                onChange={handleInputChange}
              />
            </div>

            <Button
              type='submit'
              block
              color='success'
            >
              {signupTextSubmit}
            </Button>
          </section>

        </form>

        <Link className='link' style={{ marginBottom: '2rem' }} to='/login'>
          {loginLinkText}
        </Link>
      </div >
    </div>
  );
};

export default withRouter(InstitutionSignUp);
