import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { CheckList, Divider } from 'antd-mobile';
import { useAccounting, useClaims } from '../../hooks';
import './ClaimCard.css';
import { CheckCircleFill } from 'antd-mobile-icons';

export const ActiveClaimCard = ({ data, isCheck }) => {
  const { updateInvoiceStatus } = useClaims();
  const [organization, setOrganization] = useState('');
  const [claims, setClaims] = useState([]);

  const handleChange = (claims) => {
    setClaims(claims);
  };
  useEffect(() => {
    updateInvoiceStatus(claims, organization);
  }, [organization, claims]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckList
      multiple
      className='checklist'
      onChange={handleChange}
      defaultValue={isCheck ? data : []}
      activeIcon={
        <CheckCircleFill color='#00a92b' style={{ marginLeft: '2rem' }} />
      }
    >
      <Header />
      {data.map((c, i) => (
        <CheckList.Item
          className='checklist__item'
          key={i}
          value={c}
          onClick={() => setOrganization(c.organization)}
        >
          <PatientClaimCard {...c} />
        </CheckList.Item>
      ))}
    </CheckList>
  );
};

const Header = () => {
  return (
    <Row justify='space-around'>
      <Col span={14} className='claim__name'>
        Paciente
      </Col>
      <Col span={5} className='claim__amount'>
        Monto
      </Col>
    </Row>
  );
};

const PatientClaimCard = ({ amount, claim, patient }) => {
  const { formatedCurrency } = useAccounting();
  return (
    <>
      <Row justify='center' style={{ fontSize: '.8rem' }}>
        {claim}
      </Row>
      <Divider
        direction='horizontal'
        style={{ marginTop: 5, marginBottom: 5 }}
      />
      <Row justify='space-between'>
        <Col span={14}> {patient} </Col>
        <Col span={5}> {formatedCurrency(amount)} </Col>
      </Row>
    </>
  );
};
