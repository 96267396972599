import React from 'react';
import './Subscription.css';

// libraries
import { Link } from 'react-router-dom';

// components
import Header from '../../layout/Header/Header';

// constants
import texts from '../../constants/texts';

const SuccessSubscription = () => {
  /* const price = 2000; */
  const { pages: { subscription: { success: {
    text,
    goToHomeText,
  }}}} = texts;

  return(
    <>
      <Header title={'Suscripción exitosa'} />
      <div className="subscription">
        <div className="message">
            { text }
            <Link to='/'>
              { goToHomeText }
            </Link>

          {/* <h4><i><strong>(*)</strong> a partir de que te suscribas, se te va a debitar el monto de <strong>${price}</strong> por mes para seguir usando las funcionalidades sin límites</i></h4> */}
        </div>
      </div>
    </>
  );
};

export default SuccessSubscription;
