import React, { useContext, useEffect } from 'react';
import { Button, Space } from 'antd-mobile';
import { ListClaims, UnlinkedList } from '../../components';
import Header from '../../layout/Header/Header';
import { AppContext } from '../../context/provider';
import { useAccounting, useAFIP, useClaims, useFirestore } from '../../hooks';
import './GenerateInvoice.css'
import { useHistory } from 'react-router';

export const CreateInvoice = () => {
  const [state, setState] = useContext(AppContext);
  const { formatedCurrency } = useAccounting();
  const { updateClaims } = useFirestore();
  const { getClaimsOrganizationList, getPrepareClaims } = useClaims();
  const history = useHistory();
  const { generateInvoice } = useAFIP();



  const { active, totalActive, presentation } = state;
  const { total } = presentation
  const claimsByOrganization = getClaimsOrganizationList(active);


  // useEffect(() => {
  //   localStorage.setItem('persistState', JSON.stringify(state));
  // }, [state]);

  useEffect(() => {
    const presentation = { presentationClaims: claimsByOrganization, total: totalActive }
    setState({ ...state, presentation })
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  //comentario prueba

  const handleCancelClaims = async () => {
    const selectedClaims = getPrepareClaims()
    await updateClaims(selectedClaims, 'cancelled')
    generateInvoice()
    history.push('/invoice-success');
  }

  return (
    <div className='generate-invoice'>
      <Header title={'PRESENTAR PRESTACIONES'} />
      <div className='animate__animated animate__fadeIn animate__faster body'>
        <Space
          align='center'
          direction='vertical'
          className='container'
          style={{ width: '90%', float: 'inline-start' }}
        >
          <ListClaims>
            <UnlinkedList list={claimsByOrganization} />
          </ListClaims>
        </Space>
        <Space direction='vertical' className='footer' justify='between' style={{ width: '95%', marginBottom: '1.5rem', float: 'inline-end' }}>
          <div className='invoice__total'>Total a presentar: <span className='value'>{formatedCurrency(total)}</span></div>
          <Button block color='success' disabled={total === 0} onClick={handleCancelClaims}>
            PRESENTAR
          </Button>
        </Space>
      </div>
    </div>
  );
};