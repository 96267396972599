import { useContext } from 'react';
import { AppContext } from '../context/provider';
import { getUniqueListBy } from '../utils';

export const useClaims = () => {
  const [state, setState] = useContext(AppContext);

  const getClaimsOrganizationList = (claims) => {
    const organizationList = getUniqueListBy(claims, 'organization').map(
      (obj) => obj.organization
    );

    const totalClaims = claims
      .map(({ organization }) => organization)
      .reduce((orgs, o) => {
        const count = orgs[o] || 0;
        orgs[o] = count + 1;
        return orgs;
      }, {});

    const totalAmounts = claims
      .map((organization) => organization)
      .reduce(function (map, o) {
        const name = o.organization;
        const amount = +o.amount;
        map[name] = (map[name] || 0) + amount;
        return map;
      }, {});

    return organizationList.map((org, i) => {
      const data = claims.filter((c) => c.organization === org);

      return {
        id: i + 1,
        data,
        organization: org,
        quantity: totalClaims[org],
        total: totalAmounts[org],
      };
    });
  };

  const getClaimsByDay = (arr) => {
    const unique = getUniqueListBy(arr, 'date');
    return unique.map((e) => arr.filter((ar) => ar.date === e.date));
  };



  const getPrepareClaims = () => {
    const { presentation } = state;
    const { presentationClaims } = presentation;
    const arr = presentationClaims.map(c => c.data)
    const unitOfClaims = [].concat.apply([], arr)

    const claims = unitOfClaims.map( c => ({
      code: '0001',
      product: c,
      quantity: '1,00',
      messure: 'unidades',
      price: c.amount,
      bonificationPercentage: '0,00',
      bonificationTax: '0,00',
      subtotal: c.amount,
    }))

    return claims
  }

  const updateInvoiceStatus = (data, orgData) => {
    const { presentation } = state; 
    const { presentationClaims } = presentation;
    const leftovers = presentationClaims.filter(({organization}) => organization !== orgData)
    if(data.length === 0) {
      const totalL = leftovers.reduce((acc, element) => acc + element.total, 0)
      setState({...state, presentation: {presentationClaims: leftovers, total:totalL}})
      return; 
    }
    
    const claim = [{
        id: Date.now(),
        organization: orgData,
        data,
        quantity: data.length,
        total: data.reduce((acc, {amount}) => acc + amount, 0),
      }]
   


    const claims = [...leftovers, ...claim]
    const total = claims.reduce((acc, element) => acc + element.total, 0)
    setState({...state, presentation: {presentationClaims: claims, total}})
  }

  const checkAllClaimsOrganization = ({organization: org}, isCheck) => {
    const { presentation, active } = state; 
    const { presentationClaims } = presentation;
    const leftovers = presentationClaims.filter(({organization}) => organization !== org)

    if(isCheck) {
      const activeClaims = active.filter(({organization}) => organization === org)
      const claim = [{
        id: Date.now(),
        organization: org,
        data: activeClaims,
        quantity: activeClaims.length,
        total: activeClaims.reduce((acc, {amount}) => acc + amount, 0),
      }];
      const claims = [...leftovers, ...claim]
      const total = claims.reduce((acc, element) => acc + element.total, 0)
      setState({...state, presentation: {presentationClaims: claims, total}})

      return;
    } else {
      const totalL = leftovers.reduce((acc, element) => acc + element.total, 0)
      setState({...state, presentation: {presentationClaims: leftovers, total:totalL}})
      return;
    }
  }



  return {
    getClaimsOrganizationList,
    getClaimsByDay,
    getPrepareClaims,
    updateInvoiceStatus,
    checkAllClaimsOrganization,
  };
};
