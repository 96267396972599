import React from "react";


// components
import { PanelColumnElement } from "./PanelColumnElement";
import { Divider } from "antd-mobile";

// utils
import { useClaims, usePayments } from "../../hooks";

export const PanelRowElement = ({ data }) => {
  const { getMonthStr, getDayStr, getSortedAndUniqueArrayofDates } = usePayments()
  const { getClaimsByDay } = useClaims()
  const claimsByDay = getClaimsByDay(data);
  const days = getSortedAndUniqueArrayofDates(data);

  if (data.length === 0) return null;
  return (
    <div className="panel_row" style={{ background: 'none', textAlign: 'left', marginLeft: '20px' }}>
      <Divider />
      <h4 className="panel_row--title" style={{ textAlign: 'left' }}> {data.length > 0 && getMonthStr(data)} </h4>
      <div className="panel_row--element">
        {days.map((el, i) => (
          <PanelColumnElement
            key={el.id}
            date={getDayStr(el.presentationDay)}
            claims={claimsByDay[i]}
          />
        ))}
      </div>
    </div>
  );
};