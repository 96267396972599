const pdfMock = {
  origin: {
    commercialAddress: 'Virgen De La Merced 721',
    ivaCondition: 'Responsable Monotributo',
  },
  destination: {
    pointOfSale: '00002',
    voucherNumber: '00000119',
    brutIncome: '20290605904',
    startDate: '02/07/2012'
  },
  invoiceData: {

  },
}

export default pdfMock;