/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FormControl, InputLabel, Select, MenuItem, Alert, AlertTitle, Grid, Box } from '@mui/material';
import Header from "../../layout/Header/Header";
import './Turnos.css'
import { AppContext } from "../../context/provider";
import { db } from "../../base";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import axios from "axios";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Swal from "sweetalert2";
import { sendEmailTurnos } from "../../utils/sendEmail";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';;
import dayjs from 'dayjs';
import { useLocation, useHistory } from 'react-router-dom';


const today = dayjs();
const tomorrow = dayjs().add(30, 'day');

const EditTurnos = () => {

    const location = useLocation();
    const history = useHistory();
    const { item } = location.state;
    const [state, setState] = useContext(AppContext);
    const [fechaSeleccionada, setFechaSeleccionada] = useState()
    const { currentUser, appointments } = state;
    const [patient, setPatient] = useState(item);
    const [dniInput, setDniInput] = useState(patient.dniPatient ?? '');
    const [fecha, setFecha] = useState(patient.date)
    const [doctorInput, setdoctorInput] = useState(currentUser.name);
    const [email, setEmail] = useState(patient.email ?? '');
    const [nombre, setNombre] = useState(patient.name ?? '');
    const [telefono, setTelefono] = useState(patient.phone ?? '');
    const [obraSocial, setObraSocial] = useState(patient.organization ?? '');
    const [ubication, setUbication] = useState(currentUser.address);
    const [showDoctor, setShowDoctor] = useState(false);
    const [loadingDni, setLoadingDni] = useState(false);
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [fechaElegidaBool, setfechaElegidaBool] = useState(false);
    const [horaElegidaBool, sethoraElegidaBool] = useState(false);
    const [cargandoHora, setcargandoHora] = useState(false);
    const [selectedHour, setSelectedHour] = useState(patient.time ?? null);
    const [hayTurnos, setHayTurnos] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();


    const generateHourOptions = () => {
        const hours = [];

        const fechaFormateada = dayjs(currentDate).format('DD/MM/YYYY');
        const interval = 30; // Set the time interval to 30 minutes

        if (fechaSeleccionada !== fechaFormateada) {
            for (let hour = 7; hour <= 22; hour++) {
                for (let minute = 0; minute < 60; minute += interval) {
                    const formattedHour = `${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;

                    if (!hayTurnos.some((turno) => turno.time === formattedHour)) {
                        hours.push(
                            <MenuItem key={formattedHour} value={formattedHour}>
                                {formattedHour}
                            </MenuItem>
                        );
                    }
                }
            }
        } else {
            for (let hour = currentHour; hour <= 23; hour++) {
                const startMinute = hour === currentHour ? Math.ceil(currentMinute / interval) * interval : 0;

                for (let minute = startMinute; minute < 60; minute += interval) {
                    const formattedHour = `${hour < 10 ? `0${hour}` : hour}:${minute === 0 ? '00' : minute}`;

                    if (!hayTurnos.some((turno) => turno.time === formattedHour)) {
                        hours.push(
                            <MenuItem key={formattedHour} value={formattedHour}>
                                {formattedHour}
                            </MenuItem>
                        );
                    }
                }
            }
        }

        return (
            <FormControl className="mb-3" style={{ width: '260px' }} variant="outlined">
                <InputLabel htmlFor="hour-select">Hora</InputLabel>
                <Select
                    label="Hora"
                    value={selectedHour}
                    onChange={(e) => {
                        setSelectedHour(e.target.value);
                        sethoraElegidaBool(true);
                    }}
                    inputProps={{
                        id: "hour-select",
                    }}
                >
                    {hours}
                </Select>
            </FormControl>
        );
    };


    const horasCargadas = async () => {
        setcargandoHora(true);

        if (currentUser.id == undefined || fechaSeleccionada == undefined) {
            setcargandoHora(false);
            setfechaElegidaBool(false);
            return (
                <Alert severity="info">
                    <AlertTitle>Aviso</AlertTitle>
                    Ocurrió un incidente — <strong>Elija fecha nuevamente</strong>
                </Alert>
            );
        }

        const appointmentQuery = query(
            collection(db, "appointments"),
            where("idDoctor", "==", currentUser.id),
            where("date", "==", fechaSeleccionada)
        );

        try {
            const querySnapshot = await getDocs(appointmentQuery);

            let arrayAppointment = [];

            querySnapshot.forEach((doc) => {
                arrayAppointment.push(doc.data());
            });

            setHayTurnos(arrayAppointment);
        } catch (error) {
            console.error(error);
        } finally {
            setcargandoHora(false);
        }
    };


    const buildName = () => {
        return 'Gestión de turnos';
    };

    useEffect(() => {
        traerFechaActual()
    }, [])

    const traerFechaActual = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        setFecha(formattedDate)


    }

    const searchPatient = async (dni) => {
        setLoadingDni(true)

        const patientsQuery = query(
            collection(db, "patients"),
            where("dni", "==", dni)
        );
        const querySnapshot = await getDocs(patientsQuery);

        let selectedPatient;
        querySnapshot.forEach((doc) => {
            selectedPatient = doc.data();
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (selectedPatient) {
            setState({ ...state, selectedPatient: selectedPatient });
            setPatient(selectedPatient);
            setDniInput(dni)
            setNombre(selectedPatient.name ?? '')
            setEmail(selectedPatient.email ?? '')
            setTelefono(selectedPatient.phone ?? '')
            setObraSocial(selectedPatient.organization ?? '')
            setLoadingDni(false)

        } else {
            // SI NO EXISTE EN FIRESTORE LO BUSCA EN EL API DE NEUQUEN
            const config = {
                url: `https://us-central1-tani-3a53a.cloudfunctions.net/patient?dni=${dni}`,
                method: "get",
            };

            fetchData(config);
        }

    };

    const fetchData = (config) => {
        axios(config)
            .then((response) => {
                const { data } = response.data;
                setState({
                    ...state,
                    selectedPatient: {
                        name: data.name,
                        dni: data.dni,
                        phone: 0,
                        organization: data.organization,
                        gender: null,
                        birthdate: null,
                    },
                });
                setPatient({
                    name: data.name,
                    dni: data.dni,
                    phone: 0,
                    organization: data.organization,
                    gender: null,
                    birthdate: null,
                });
                setDniInput(dni)
                setNombre(data.name ?? '')
                setObraSocial(data.organization ?? '')
                setLoadingDni(false)
            })
            .catch((error) => {
                console.log('error')
                setLoadingDni(false)
            });
    };


    const handleSearch = async (doctor) => {
        setLoadingDoctor(true)

        try {
            const profileQuery = query(
                collection(db, "users"),
                where("name", "==", doctor)
            );
            const querySnapshot = await getDocs(profileQuery);

            let selectedProfile;
            querySnapshot.forEach((doc) => {
                selectedProfile = doc.data();
            });
            if (selectedProfile) {
                setdoctorInput(selectedProfile.name)
            } else {
                setdoctorInput('Sin coincidencias')
            }
            setLoadingDoctor(false)

        } catch (error) {
            console.log('error ', error)
            setdoctorInput('Sin coincidencias')
            setLoadingDoctor(false)
        }


    };
    const mostrarCalendario = () => {
        setShowCalendar(!showCalendar)
    }

    const updateData = async () => {
        try {
            const usersDocument = doc(db, 'appointments', item.id);

            const dataToUpdate = {
                date: fecha,
                time: selectedHour,
                dniPatient: dniInput,
                email: email,
                name: nombre,
                phone: telefono,
                organization: obraSocial,
                nameDoctor: doctorInput,
                idDoctor: currentUser.id,
                ubication: ubication,
            };

            await updateDoc(usersDocument, dataToUpdate);


            Swal.fire({
                text: `Turno actualizado`,
                icon: 'success',
                confirmButtonText: 'Ok',
            });

            sendEmailTurnos(email, doctorInput, nombre, fecha, selectedHour, ubication);
            const newAppointment = {
                date: fecha,
                time: selectedHour,
                dniPatient: dniInput,
                email: email,
                name: nombre,
                phone: telefono,
                organization: obraSocial,
                nameDoctor: doctorInput,
                idDoctor: currentUser.id,
                ubication: ubication,
            };
            // Encuentra el índice del elemento en el estado actual
            const updatedAppointmentIndex = state.appointments.findIndex((appointment) => appointment.id === item.id);

            if (updatedAppointmentIndex !== -1) {
                // Actualiza el elemento específico en el estado
                const updatedAppointments = [...state.appointments];
                updatedAppointments[updatedAppointmentIndex] = {
                    date: fecha,
                    time: selectedHour,
                    dniPatient: dniInput,
                    email: email,
                    name: nombre,
                    phone: telefono,
                    organization: obraSocial,
                    nameDoctor: doctorInput,
                    idDoctor: currentUser.id,
                    ubication: ubication,
                    id: item.id, // Asegúrate de incluir el ID actualizado
                };

                // Actualiza el estado con el nuevo array de appointments
                setState({ ...state, appointments: updatedAppointments });
            }

            setfechaElegidaBool(false)
            sethoraElegidaBool(false)
            setcargandoHora(false);

            clearField()
            history.push('/')

        } catch (error) {
            console.log(error)
            Swal.fire({
                text: `No se pudo agendar`,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        }

    }
    const clearField = () => {
        setDniInput("");
        setdoctorInput(currentUser.name);
        setEmail("");
        setNombre("");
        setTelefono("");
        setObraSocial("");
        setUbication(ubication);
        setFecha(null);

    }

    return (
        <Grid container>
            <Header title={buildName()} noback={false} isHome={false} />
            <Grid item xs={12} md={4} sx={{ backgroundColor: '#fff' }}>
            </Grid>
            <Grid item xs={12} md={4} sx={{ backgroundColor: '#FFF' }}>
                <h3 style={{ backgroundColor: '#ab9f80', color: 'white', fontWeight: 'bold' }}>Editar turno</h3>
                <Form>
                    {
                        showDoctor &&
                        <>
                            <Form.Group className="mb-3" controlId="doctor">
                                <Form.Label>Nombre del doctor</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="ingrese nombre del doctor "
                                    value={doctorInput}
                                    onChange={(e) => setdoctorInput(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button style={{ width: '100%', fontWeight: 'bold', backgroundColor: `${!loadingDoctor ? '#00a92b' : '#AAB7B8'}` }}
                                onClick={() => handleSearch(doctorInput)} className="mb-3"> {!loadingDoctor ? "Buscar Doctor" : "Cargando..."}</Button>
                        </>
                    }

                    <Button style={{ width: '100%', fontWeight: 'bold', backgroundColor: '#00a92b' }} className="mb-3"
                        onClick={() => setShowDoctor(!showDoctor)}>
                        {showDoctor ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                    <h6 className="mb-3" style={{ color: 'black', fontWeight: 'bold' }}>Dr {doctorInput != undefined ? doctorInput : currentUser.name}</h6>
                    <Form.Group className="mb-3" controlId="dni">
                        <Form.Label>Dni del paciente</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="ingrese DNI sin espacios ni puntos"
                            value={dniInput}
                            onChange={(e) => setDniInput(e.target.value)}
                            required />
                    </Form.Group>
                    <Button style={{ width: '100%', fontWeight: 'bold', backgroundColor: `${!loadingDni ? '#00a92b' : '#AAB7B8'}` }}
                        onClick={() => searchPatient(dniInput)} className="mb-3">{!loadingDni ? "Buscar DNI" : "Cargando..."}</Button>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" placeholder="Juan Jose" value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="organization">
                        <Form.Label>Obra social</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Osde"
                            value={obraSocial}
                            onChange={(e) => setObraSocial(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email del paciente</Form.Label>
                        <Form.Control
                            type="email" placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="telefono">
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control type="number" placeholder="3814447775" value={telefono}
                            onChange={(e) => setTelefono(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ubication">
                        <Form.Label>Lugar de atención</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Lugar de atencion"
                            value={ubication}
                            onChange={(e) => setUbication(e.target.value)}
                            required />
                    </Form.Group>
                    {showCalendar && <div className="mb-3 custom-datepicker-container">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                slotProps={{
                                    textField: {
                                        helperText: 'MES/DIA/AÑO',
                                    },
                                }}
                                defaultValue={today}
                                minDate={today}
                                maxDate={tomorrow}
                                views={['year', 'month', 'day']}
                                onChange={(newValue) => {
                                    const formattedDate = dayjs(newValue).format('DD/MM/YYYY');
                                    setFecha(formattedDate);
                                    setFechaSeleccionada(formattedDate)
                                    setfechaElegidaBool(true)
                                    sethoraElegidaBool(false)
                                    horasCargadas()
                                }}
                            />
                        </LocalizationProvider>
                    </div>}

                    {!showCalendar && <Box>
                        <Box>
                            <h5 style={{ backgroundColor: '#ab9f80', color: 'white', fontWeight: 'bold' }}>Fecha turno {fecha}</h5>
                            <h5 style={{ backgroundColor: '#ab9f80', color: 'white', fontWeight: 'bold' }}>Hora turno  {selectedHour}</h5>
                        </Box>
                        <Box>
                            <Button 
                            style={{ width: '100%', fontWeight: 'bold', backgroundColor: '#00a92b' }}
                            onClick={mostrarCalendario}>Cambiar Fecha y/o hora</Button>
                        </Box>
                    </Box>}
                    {(fechaElegidaBool && !cargandoHora) && generateHourOptions()}
                    {(fechaElegidaBool && cargandoHora) && <h5 style={{ color: 'black', fontWeight: 'bold' }}>Cargando horarios....</h5>}
                    <Button
                        style={{ width: '100%', fontWeight: 'bold', backgroundColor: `${horaElegidaBool ? '#00a92b' : 'grey'}` }} disabled={!horaElegidaBool}
                        onClick={updateData}>Guardar turno</Button>
                </Form>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center' }}>
            </Grid>
        </Grid>
    );
}

export default EditTurnos;