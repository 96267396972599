import React from 'react';

// libraries
import { StyleSheet, View, Text } from '@react-pdf/renderer';

// utils
import { formatDateDDMMYYYY } from '../utils/dateUtils';

// const
import pdfMock from './pdfMock';
const TableRow = ({
  code,
  product,
  quantity,
  messure,
  price,
  bonificationPercentage,
  bonificationTax,
  subtotal,
}) => {
  return (
    <View style={styles.row}>
      <View style={styles.rowCodigo}>
        <Text>{code}</Text>
      </View>
      <View style={styles.rowProdServ}>
        <Text>{product}</Text>
      </View>
      <View style={styles.rowCantidad}>
        <Text>{quantity}</Text>
      </View>
      <View style={styles.rowMedida}>
        <Text>{messure}</Text>
      </View>
      <View style={styles.rowPrecio}>
        <Text>{price}</Text>
      </View>
      <View style={styles.rowPorcBonif}>
        <Text>{bonificationPercentage}</Text>
      </View>
      <View style={styles.rowImpBonif}>
        <Text>{bonificationTax}</Text>
      </View>
      <View style={styles.rowSubtotal}>
        <Text>{subtotal}</Text>
      </View>
    </View>
  );
};

const InvoiceHeader = ({
  selectedClaims,
  name,
  FchServDesde,
  FchServHasta,
  FchVtoPago,
  CbteTipo,
  organization,
}) => {

  return(
    <View style={styles.headerContainer}>
      <View style={styles.original}>
        <Text> ORIGINAL </Text>
      </View>
      <View style={styles.invoiceOrigin}>
        <View style={styles.cbtTipo}>
          <Text style={styles.cbtLetter}> C </Text>
          <Text style={styles.smText}> COD. 0{CbteTipo} </Text>
        </View>
        <View style={styles.invoiceOriginLeft}>
          <Text style={styles.bigText}>{name}</Text>
          <Text style={styles.smText}> Razón Social: {name} </Text>
          <Text style={styles.smText}>{`Domicilio Comercial: ${pdfMock.origin.commercialAddress}`}</Text>
          <Text style={styles.smText}>{`Condición frente al IVA: ${pdfMock.origin.ivaCondition}`}</Text>
        </View>
        <View style={styles.invoiceOriginRight}>
          <Text style={styles.bigText}> FACTURA </Text>
          <Text style={styles.smText}>{`Punto de Venta: ${pdfMock.destination.pointOfSale}         Comp. Nro: ${pdfMock.destination.voucherNumber}`}</Text>
          <Text style={styles.smText}> Fecha de Emisión: 25/02/2021 </Text>
          <Text style={styles.smText}> CUIT: 20290605904 </Text>
          <Text style={styles.smText}>{`Ingresos Brutos: ${pdfMock.destination.brutIncome}`}</Text>
          <Text style={styles.smText}>{`Fecha de Inicio de Actividades: ${pdfMock.destination.startDate}`}</Text>
        </View>
      </View>
      <View style={styles.dates}>
        <Text style={styles.smText}> Período Facturado Desde: {formatDateDDMMYYYY(FchServDesde)} | Hasta: {formatDateDDMMYYYY(FchServHasta)} | Fecha de Vto. para el pago: {formatDateDDMMYYYY(FchVtoPago)} </Text>
      </View>
      <View style={styles.invoiceDestination}>
        <View style={styles.invoiceDestinationLeft}>
          <Text style={styles.smText}> CUIT: 30536187339 </Text>
          <Text style={styles.smText}> Condición frente al IVA: IVA Sujeto Exento </Text>
          <Text style={styles.smText}> Condición de venta: Otra </Text>
        </View>
        <View style={styles.invoiceDestinationRight}>
          <Text style={styles.smText}> Apellido y Nombre / Razón Social: {organization} </Text>
          <Text style={styles.smText}> Domicilio:  Piso: Dpto: </Text>
        </View>
      </View>
      <View style={styles.table}>
        
        <View style={styles.header}>
          <View style={styles.headCodigo}>
            <Text style={styles.smText}> Codigo </Text>
          </View>
          <View style={styles.headProdServ}>
            <Text style={styles.smText}> Producto/Servicio </Text>
          </View>
          <View style={styles.headCantidad}>
            <Text style={styles.smText}> Cantidad </Text>
          </View>
          <View style={styles.headMedida}>
            <Text style={styles.smText}> U. Medida </Text>
          </View>
          <View style={styles.headPrecio}>
            <Text style={styles.smText}> Precio Unit. </Text>
          </View>
          <View style={styles.headPorcBonif}>
            <Text style={styles.smText}> % Bonif </Text>
          </View>
          <View style={styles.headImpBonif}>
            <Text style={styles.smText}> Imp. Bonif </Text>
          </View>
          <View style={styles.headSubtotal}>
            <Text style={styles.smText}> Subtotal </Text>
          </View>
        </View>

        {selectedClaims.map((row, index) => (
          <TableRow
            code={row.code}
            product={row.product.claim}
            quantity={row.quantity}
            messure={row.messure}
            price={row.price}
            bonificationPercentage={row.bonificationPercentage}
            bonificationTax={row.bonificationTax}
            subtotal={row.subtotal}
            key={index}
          />
        ))}

      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    right: 20,
  },
  bigText: {
    fontSize: 20,
  },
  original: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    border: '1 solid black',
    fontSize: 14,
  },
  cbtTipo: {
    position: 'absolute',
    left: '47%',
    margin: '0 auto',
    width: 50,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
    textAlign: 'center',
  },
  cbtLetter: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  invoiceOrigin: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  invoiceOriginLeft: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  invoiceOriginRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 50,
  },
  dates: {
    padding: 10,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
  },
  invoiceDestination: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderBottom: '1 solid black',
    marginBottom: 15,
  },
  invoiceDestinationLeft: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  invoiceDestinationRight: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  table: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'grey',
    color: 'black',
    border: '1 solid black',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  headCodigo: {
    justifyContent: 'center',
    borderRight: '1 solid black',

    width: '7%',
    textAlign: 'center',
    height: 25,
  },
  rowCodigo: {
    width: '7%',
  },
  headProdServ: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '23%',
    textAlign: 'center',
  },
  rowProdServ: {
    width: '16%',
  },
  headCantidad: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowCantidad: {
    width: '8%',
    textAlign: 'center',
  },
  headMedida: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowMedida: {
    width: '10%',
    textAlign: 'center',
  },
  headPrecio: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '18%',
    textAlign: 'center',
  },
  rowPrecio: {
    width: '19%',
    textAlign: 'center',
  },
  headPorcBonif: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowPorcBonif: {
    width: '10%',
    textAlign: 'center',
  },
  headImpBonif: {
    justifyContent: 'center',
    borderRight: '1 solid black',
    width: '10%',
    textAlign: 'center',
  },
  rowImpBonif: {
    width: '10%',
    textAlign: 'center',
  },
  headSubtotal: {
    justifyContent: 'center',

    width: '12%',
    textAlign: 'center',
  },
  rowSubtotal: {
    width: '18%',
    textAlign: 'center',
  },
  smText: { fontSize: 9 },
  mdText: { fontSize: 12 },
  lgText: { fontSize: 15},
  boldText: { color: '#000000' } 
});

export default InvoiceHeader;