/*eslint-disble*/
import React, { useState, useContext } from 'react';
import { AppContext } from '../../context/provider';
import { TextField, Button, Grid, Paper, Typography, MenuItem, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { db, uploadImageFile } from '../../base';
import { addDoc, collection } from 'firebase/firestore';
import { Card } from 'react-bootstrap';

const GenderOptions = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Femenino' },
  { value: 'other', label: 'Otro' },
];

const BloodTypeOptions = [
  { value: 'A+', label: 'A+' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B-', label: 'B-' },
  { value: 'AB+', label: 'AB+' },
  { value: 'AB-', label: 'AB-' },
  { value: 'O+', label: 'O+' },
  { value: 'O-', label: 'O-' },
];



const AddPatientComponent = () => {

  const [state, setState] = useContext(AppContext);
  const { currentPatientParent, currentPatient } = state;
  const [formData, setFormData] = useState({
    id: '',
    address: '',
    affiliate: '',
    birthdate: '',
    bloodType: '',
    dni: '',
    email: '',
    urlphoto: 'https://via.placeholder.com/200x200',
    gender: '',
    locality: '',
    name: '',
    organization: '',
    phone: '',
    postalcode: '',
    idParents: currentPatientParent.id
  });
  const [imagesName, setImagesName] = useState();
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);

  const openImagePicker = () => {
    Swal.fire({
      title: 'Seleccionar imagen',
      showCancelButton: true,
      confirmButtonText: 'Seleccionar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById('upload-button').click();
      }
    });
  };

  const changeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImageData(file)
        const fileName = file.name;
        setImagesName(fileName)


      } else {
        alert('El archivo seleccionado no es una imagen.');
      }
    } else {
      alert('No se ha seleccionado ningún archivo.');
    }
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

// eslint-disable-next-line
    if (currentPatient?.idParents == null || currentPatient?.idParents == undefined) {
      Swal.fire({
        title: 'Error',
        text: 'El usuario no es padre/tutor.',
        icon: 'error',
        confirmButtonText: 'OK',
    });
      return;
    }

    setLoading(true);
    setFormData((prevData) => ({
      ...prevData,
      'urlphoto': imageData ? imageData : formData.photo
    }));


    try {

      const url = imageData ? await uploadImageFile(imageData, imagesName, 'ProfileFolder') : formData.photo;
      const datatToUp = {
        address: formData.address,
        affiliate: formData.affiliate,
        birthdate: formData.birthdate,
        bloodType: formData.bloodType,
        dni: formData.dni,
        email: formData.email,
        urlphoto: url ? url : formData.urlphoto,
        gender: formData.gender,
        locality: formData.locality,
        name: formData.name,
        organization: formData.organization,
        phone: formData.phone,
        postalcode: formData.postalcode,
        idParents: formData.idParents
      };


      const docRef = await addDoc(collection(db, 'patients'), datatToUp);

      const idRef = docRef.id;

      const patientChicldren = {
        id: idRef,
        address: formData.address,
        affiliate: formData.affiliate,
        birthdate: formData.birthdate,
        bloodType: formData.bloodType,
        dni: formData.dni,
        email: formData.email,
        urlphoto: url ? url : formData.urlphoto,
        gender: formData.gender,
        locality: formData.locality,
        name: formData.name,
        organization: formData.organization,
        phone: formData.phone,
        postalcode: formData.postalcode,
        idParents: formData.idParents
      };

      setState((prevState) => ({
        ...prevState,
        childrens: [...prevState.childrens, patientChicldren],
      }));

      setLoading(false);
      Swal.fire({
        text: `Miembro agregado`,
        icon: 'success',
        confirmButtonText: 'Ok',
      });

    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        title: 'Ocurrió un error!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'OK',
      });
    }
  }


  return (
    <Grid container spacing={2} justifyContent="center">

      <Grid item xs={12} md={12}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" mb={4}>
            AGREGAR MIEMBRO
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div
              style={
                {
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }
              }>
              {imageData && <Card style={{
                width: '200px',
                backgroundSize: 'contain',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px'
              }
              }>
                <img
                  src={imageData === 'string' ? imageData : URL.createObjectURL(imageData)}
                  alt="Imagen"
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'contain',
                  }}
                />
              </Card>}
              {!imageData && <Card style={{
                width: '200px',
                backgroundSize: 'contain',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px'
              }
              }>
                <img
                  src={formData.urlphoto}
                  alt="Imagen"
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'contain',
                  }}
                />
                <input
                  type="file"
                  multiple
                  onChange={changeInput}
                  style={{ display: 'none' }}
                  id="upload-button"
                />
                <Button size="small" onClick={openImagePicker}>
                  SELECCIONAR IMAGEN
                </Button>
              </Card>}
            </div>
          </Box>
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            NOMBRE
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            fecha de nacimiento
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            type="date"
            fullWidth
            value={formData.birthdate}
            onChange={(e) => handleChange('birthdate', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            GENERO
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            select
            fullWidth
            value={formData.gender}
            onChange={(e) => handleChange('gender', e.target.value)}
          >
            {GenderOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Tipo de Sangre
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            select
            fullWidth
            value={formData.bloodType}
            onChange={(e) => handleChange('bloodType', e.target.value)}
          >
            {BloodTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            DNI
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.dni}
            onChange={(e) => handleChange('dni', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Dirección
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.address}
            onChange={(e) => handleChange('address', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Localidad
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.locality}
            onChange={(e) => handleChange('locality', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Código Postal
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.postalcode}
            onChange={(e) => handleChange('postalcode', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Teléfono
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Email
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Afiliado
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.affiliate}
            onChange={(e) => handleChange('affiliate', e.target.value)}
          />
          <Typography variant="button" display="block" gutterBottom mt={2} style={{ fontWeight: 'bold' }}>
            Organización
          </Typography>
          <TextField
            inputProps={{ style: { color: '#CA6F1E' } }}
            fullWidth
            value={formData.organization}
            onChange={(e) => handleChange('organization', e.target.value)}
          />
          <Button sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey", mt: 2 }} onClick={handleSubmit}>
            {loading ? "CARGANDO..." : "CONFIRMAR"}
          </Button>

        </Paper>
      </Grid>
    </Grid>
  );
};



export default AddPatientComponent