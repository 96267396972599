/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../context/provider';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Header from '../../layout/Header/Header';
import { useAccounting } from '../../hooks';

function getNombreMes(numeroMes) {
  const meses = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];
  return meses[parseInt(numeroMes, 10) - 1];
}


const obtenerFechaActual = () => {
  const fecha = new Date();
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
  const año = fecha.getFullYear();

  const fechaCompleta = new Date(`${mes}/01/${año}`);
  return fechaCompleta;
};


const Plus = () => {
  const [state] = useContext(AppContext);
  const { userClaims } = state;
  const { formatedCurrency } = useAccounting();
  const [plus, setPlus] = useState(0);
  const [meses, setMeses] = useState(0);
  const [arrayPlus, setArrayPlus] = useState([]);
  const claimsWithPlus = userClaims.filter(claim => claim.plus !== undefined && claim.plus !== null);

  useEffect(() => {
    const fechaActual = obtenerFechaActual();
    const mesActual = fechaActual.getMonth() + 1;  
    const añoActual = fechaActual.getFullYear();

    const porFecha = claimsWithPlus.filter((item) => {
      const [mesServicio, diaServicio, añoServicio] = item.serviceDate.split('/');
      const servicioFecha = new Date(`${mesServicio}/${diaServicio}/${añoServicio}`);
      return servicioFecha.getFullYear() === añoActual && servicioFecha.getMonth() + 1 === mesActual;
    });
    setArrayPlus(porFecha)

    const sumatoriaPlus = porFecha.reduce((acumulador, item) => acumulador + item.plus, 0);

    setPlus(sumatoriaPlus);

    const uniqueMonths = new Set(porFecha.map(item => {
      const [month, day, year] = item.serviceDate.split('/');
      return `${day} de ${getNombreMes(month)} de ${year}`;
    }));

    const sortedMonths = Array.from(uniqueMonths).sort((a, b) => {
      const [dayA, monthA, yearA] = a.split(' de ');
      const [dayB, monthB, yearB] = b.split(' de ');
      return new Date(`${yearA}-${getNumeroMes(monthA)}-${dayA}`) - new Date(`${yearB}-${getNumeroMes(monthB)}-${dayB}`);
    });

    function getNumeroMes(nombreMes) {
      const meses = {
        enero: "01", febrero: "02", marzo: "03", abril: "04",
        mayo: "05", junio: "06", julio: "07", agosto: "08",
        septiembre: "09", octubre: "10", noviembre: "11", diciembre: "12"
      };
      return meses[nombreMes];
    }

    setMeses(sortedMonths);


  }, [userClaims]);

  const buildName = () => {
    return 'Total cobrado en efectivo';
  };

  return (
    <Box>
      <Header title={buildName()} noback={false} isHome={false} />
      <Grid container>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ pt: 8 }}>
            <Card sx={{ minWidth: 275, backgroundColor: 'white' }}>
              <CardContent>
                <Typography sx={{ color: '#00a92b' }} variant="h3" gutterBottom>
                  {formatedCurrency(plus)}
                </Typography>
                <Typography sx={{ color: '#00a92b' }} variant="h5" >
                  a cobrar
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            {meses && meses.map(month => (
              <Accordion key={month} sx={{ mt: 4 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h4" sx={{ color: '#ab9f80' }}>{month}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: '100%' }}>
                    {claimsWithPlus
                      .filter(claim => {
                        const [claimMonth, claimDay, claimYear] = claim.serviceDate.split('/');
                        const formattedClaimDate = `${claimDay} de ${getNombreMes(claimMonth)} de ${claimYear}`;
                        return formattedClaimDate === month;
                      })
                      .map(claim => (
                        <Box key={claim.id} sx={{ display: 'flex', mt: 2, justifyContent: 'space-between', mx: 2 }}>
                          <Typography variant="h6" sx={{ color: '#ab9f80' }}>{claim.serviceDate}</Typography>
                          <Typography variant="body1">{formatedCurrency(claim.plus)}</Typography>
                        </Box>
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </Box>
  );
}

export default Plus;
