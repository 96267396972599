import React, { useCallback, useContext, useState } from 'react';
import './Login.css';
import { ReactComponent as Logo } from '../../assets/logo.svg';

// libraries
import { Button } from 'antd-mobile';
import { Redirect, useHistory } from 'react-router';
import { createUserWithEmailAndPassword,sendEmailVerification } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


// utils
import { db, auth } from '../../base.js';
import { AuthContext } from '../../authentication/Auth.js';
import { doc, setDoc } from 'firebase/firestore';
import { Box, Grid, Typography } from '@mui/material';

// hooks

const RegisterPatient = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const history = useHistory();

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setRepassword('');
  };

  const handleSavePatient = async (user) => {
    console.log(user)
      try {
        await setDoc(doc(db, 'patients', user.uid), {
         email: user.email
        });
        
      } catch (error) {
       console.log(error)
      };
    }
  

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();

      if (password !== repassword) {
        return setError('Las contraseñas no coinciden');
      }

      setLoading(true);

      try {
        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            
            const user = userCredential.user;
            await handleSavePatient(user);
            sendEmailVerification(user)
            
            .then(() => {
              console.log('Un link de verificacion fue enviado a ' + email)
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(`Error ${errorCode}: ${errorMessage}`);
            });
            
            Swal.fire({
              title: 'Paciente creado con éxito',
              text: email,
              icon: 'success',
              confirmButtonText: 'Ok',
            });

            resetForm(); 
            setLoading(false);
            history.replace('/patient-login');
          })
          .catch((error) => {
            console.log('error 1 ', error)
            setError('Ocurrió un error');
            setLoading(false);
            resetForm()
          });
      } catch (error) {
        console.log('error 2 ', error)
        setLoading(false);
        setError('Ocurrió un error');
        resetForm()
        throw new Error(`Error: ${error}`);

      }
    },
    [history, email, password, repassword] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) return <Redirect to='/patient-home' />;


  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <div className='login-container'>
            <div className="login-container-panel">
              <div className='login-container-panel__logo'>
                <Logo />
              </div>
              <h2>Registrarme</h2>
              {error && <Typography variant="caption" display="block" gutterBottom color="red">
                {error}
              </Typography>}
              <form onSubmit={handleRegister} className='login'>
                <input
                  name='email'
                  type='email'
                  placeholder='Email'
                  className='login__field'
                  disabled={loading ? true : false}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  name='password'
                  type='password'
                  placeholder='Password'
                  className='login__field'
                  disabled={loading ? true : false}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  name='repassword'
                  type='password'
                  placeholder='Repetir password'
                  className='login__field'
                  disabled={loading ? true : false}
                  value={repassword}
                  onChange={(e) => setRepassword(e.target.value)}
                />

                <Button
                  type='submit'
                  block
                  color='success'
                  disabled={loading ? true : false}
                >
                  {loading ? "Cargando..." : "Registrarme"}
                </Button>
              </form>
              <>
                <Link className='link' to='/patient-login'>
                  Regresar
                </Link>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>

  );
};


export default RegisterPatient