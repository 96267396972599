// /*eslint-disable*/
import { signInWithPopup } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { auth, provider } from '../base';
import useUser from './useUser';
import { AppContext } from '../context/provider';
import { useLocalStorage } from './useLocalStorage';

const useGoogleAccount = () => {
    const { findUser } = useUser();
    const [state, setState] = useContext(AppContext);
    const history = useHistory();
    // eslint-disable-next-line
    const [storedCurrentUser, setStoredCurrentUser] = useLocalStorage(
        'storedCurrentUser',
        {}
    );
    const handleGoogle = async () => {
        signInWithPopup(auth, provider)
            .then(async (data) => {
                const dataGoogle = {
                    googleId: data.user?.uid,
                    email: data.user?.email,
                    urlphoto: data.user?.photoURL,
                    name: data.user?.displayName,
                    phone: data.user?.phoneNumber
                }
                const userFound = await findUser(dataGoogle.email);
                if (userFound) {
                    setState({
                        ...state,
                        currentUser: userFound
                    });
                    setStoredCurrentUser({
                        ...userFound,
                        id: userFound.id,
                    });
                    sessionStorage.setItem("zxcasd", userFound.email);
                    sessionStorage.setItem("qwefgh", userFound.id);
                    history.replace('/');
                } else {
                    setState({
                        ...state,
                        userGoogle: dataGoogle
                    })
                    history.replace('/selectsignup');
                }
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('¡Error!', 'No se pudo ingresar', 'error');
            })
    }
    return { handleGoogle }
}

export default useGoogleAccount;