import React from 'react';
import './Subscription.css';
import { config } from '../../base'

// libraries
import { Button } from 'antd-mobile';

// components
import Header from '../../layout/Header/Header';

// constants
import texts from '../../constants/texts';

const Subscription = () => {
  const { pages: { subscription: {
    text,
    subText
  } } } = texts;
  const price = 2000;

  const handleSubscription = () => {
    fetch(config.mpApiUrl + '/subscription')
      .then(response => response.json())
      .then(data => {
        console.log("data recibida:", data)
        window.location.href = data.init_point;
      });
  };

  return (
    <>
      <Header title={'Suscripción'} isSubscripter={true} />
      <div className="subscription">
        <div className="message">
          {text}
          <Button
            onClick={handleSubscription}
            color='primary'
            className="button"
          >
            {`Suscribite por $${price} (*) `}
          </Button>
          {subText}
        </div>
      </div>
    </>
  );
};

export default Subscription;
