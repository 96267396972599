import React, { useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Swal from 'sweetalert2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const CardContactas = ({ card }) => {

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCall = () => {
        const phoneNumber = card.numberPhone;

        if (typeof window !== 'undefined' && 'ontouchstart' in window) {
            window.open(`tel:${phoneNumber}`);
        } else {
            console.log('Phone call not supported on this device.');
            Swal.fire({
                text: `Dispositivo sin soporte`,
                icon: 'error',
                confirmButtonText: 'Ok',
            });

        }
    };
    const handleWhatsapp = (number) => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${number}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <Grid item xs={12} sm={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button
                    sx={{ color: "grey", fontWeight: 'bold' }}>
                    DR . {card.name.toUpperCase()} {card.lastName.toUpperCase()}
                </Button>
                <ContactPageIcon sx={{ color: "green" }} onClick={handleOpenModal} />
                <PhoneForwardedIcon sx={{ color: "green" }} onClick={() => handleCall(card.numberPhone)} />
            </Box>
            <Modal open={isModalOpen} onClose={() => setModalOpen(false)} width="200px">
                <Box sx={{ background: 'white', py: 10, px: 5 }}>
                    <Typography>DR . {card.name.toUpperCase()} {card.lastName.toUpperCase()}</Typography>
                    <Typography>Email : {card.email}</Typography>
                    <Typography>Telefono {card.numberPhone}</Typography>
                    <Typography>Especialidad : {card.especiliadad}</Typography>
                    <Typography>Direccion: {card.address}</Typography>
                    <Button
                        fullWidth
                        endIcon={<WhatsAppIcon />}
                        sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                        onClick={() => handleWhatsapp(card.numberPhone)}>
                        Wathsapp
                    </Button>
                </Box>
            </Modal>
        </Grid>
    );
}

export default CardContactas;
