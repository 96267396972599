import React from 'react';
import './LeftMenu.css';

// libraries
import { Button, Space } from 'antd-mobile';
import { Menu } from 'antd';

import "react-datepicker/dist/react-datepicker.css";
import {
  Link,
  useHistory
} from 'react-router-dom';


// constants
import texts from '../../constants/texts';
import { signOut } from 'firebase/auth';
import { auth } from '../../base';


//import Swal from 'sweetalert2';
export const LeftMenu = ({ mode }) => {

  const history = useHistory();


  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        history.push('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { pages: { home: { leftMenu: {
    // subscribeText,
    logoutText,
    codigoSifo,
    // filinghistory,
    turnos
  } } } } = texts;

  return (
    <div className='left-menu'>
      <Space direction='vertical' className='left-menu__options'>
        <Menu mode={mode}>
          <Menu.Item key="profile" style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/profile">MI PERFIL</Link>
          </Menu.Item>
          <Menu.Item key="profile" style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/scann-qr">LEER QR</Link>
          </Menu.Item>
          <Menu.Item key="sifo" style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/generate-sifo">{codigoSifo}</Link>
          </Menu.Item>
          {/* <Menu.Item key="presentados" style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/filing-history">{filinghistory}</Link>
          </Menu.Item> */}
          <Menu.Item key="turnos" style={{ whiteSpace: 'pre-wrap' }}>
            <Link to="/turnos">{turnos}</Link>
          </Menu.Item>
          <Menu.Item key="Denunciar" style={{ whiteSpace: 'pre-wrap' }}>
          <Link to="/profile">DENUNCIAR</Link>
          </Menu.Item>
        </Menu>
      </Space>
      <div className="left-menu__button">
        <Button className='logout' type='text' onClick={handleSignOut}>
          {logoutText}
        </Button>
      </div>
    </div>
  );
};
