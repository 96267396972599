import { Box, Typography } from "@mui/material"
import { CashAccordion } from "./cashAccordion"

export const Cash = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: 2 }}>
      <Typography variant="h4" component="div" sx={{ color: 'green' }}>
        $45.328
      </Typography>
      <Typography variant="body1" component="div" sx={{ color: 'green' }}>
        a cobrar
      </Typography>
      <CashAccordion />
    </Box>
  )
}
