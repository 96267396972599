import { useContext, useState } from 'react'
import { db } from '../base';
import { collection, getDocs, query, where } from "firebase/firestore";
import { AppContext } from '../context/provider';

const useHDC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useContext(AppContext);
    const { selectedPatient } = state;

    const getHDC = async () => {

        setIsLoading(true);
        console.log('dno paciente ... ', selectedPatient.dni);
        const hdcQuery = query(
            collection(db, "medicalhistory"),
            where("dni", "==", selectedPatient.dni)
        );
        console.log('hdcQuery', hdcQuery);
        const querySnapshot = await getDocs(hdcQuery);

        let arrayHdc = [];

        querySnapshot.forEach((doc) => {
            const hdcData = doc.data();
            const hdcWithId = { id: doc.id, ...hdcData };
            arrayHdc.push(hdcWithId);
        });

        if (arrayHdc.length > 0) {

            setState({ ...state, hdcs: arrayHdc });
        }
        setIsLoading(false);
    };
    return { getHDC, isLoading }
}

export default useHDC