import React from 'react'
import Header from '../../layout/Header/Header';
import { Space } from 'antd-mobile';
import PatientComponent from '../../components/patient/PatientComponent';

const HomePatient = () => {

  const buildName = () => {
    return 'Mi perfil - Paciente';
  };

  return (
    <div className="App">
      <Header title={buildName()} noback={false} isHome={false} />
      <Space align='center' direction='vertical' className='container animate__animated animate__fadeIn animate__faster'></Space>
      {/* </header> */}
      <PatientComponent/>
    </div>
  )
}

export default HomePatient