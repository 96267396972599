import Button from 'react-bootstrap/Button';

export const ModalButton = ({disabled}) => {
  return (
    <>
      <Button disabled={disabled} variant="success" sx={{width: '100%'}}>
        Procesar
      </Button>
    </>
  )
}
