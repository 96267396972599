import React, { useContext, useState } from 'react';
import { Avatar, Typography, Container, Paper, Box } from '@mui/material';
import { AppContext } from '../../context/provider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditProfileForm from './EditProfileForm';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const UserProfile = () => {
  const state = useContext(AppContext);
  const json = state.currentUser === undefined ? state[0] : state;
  const [showEdit, setShowEdit] = useState(false); // Inicialmente ocultar el formulario de edición

  const user = {
    name: 'Dr Diaz Carlos',
    photoUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStGm2-r2mTJAgYOcMQkGkgbTtnfZbai0GAFA&usqp=CAU',
  };

  const toggleEditForm = () => {
    setShowEdit(!showEdit); // Cambiar el estado para mostrar u ocultar el formulario de edición
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
        <Box sx={{ position: 'relative' }}>
          {showEdit ? null : <Avatar alt="Foto de perfil" src={json.currentUser.photo != null ? json.currentUser.photo :user.photoUrl} sx={{ width: 150, height: 150, margin: '0 auto' }} />}
          <IconButton style={{ position: 'absolute', bottom: 0, right: 0, color:'#ab9f80 ' }} onClick={toggleEditForm}>
            {showEdit ?  <CheckBoxIcon /> :<EditIcon />}
          </IconButton>
        </Box>
        {showEdit ? (
          <EditProfileForm data={json.currentUser} onSubmit={() => setShowEdit(false)} />
        ) : (
          <>
            <Typography variant="h5" component="div" style={{ marginTop: '1rem' }}>
              DR/A {json.currentUser.name}
            </Typography>
            <Typography variant="h5" component="div" style={{ marginTop: '1rem', backgroundColor: '#00a92b', color:'white', fontWeight:'bold' }}>
              Profesion
            </Typography>
            <Typography variant="h5" component="div" style={{ marginTop: '1rem', color:'#ab9f80' }}>
              {json.currentUser.profession}
            </Typography>
            <Typography variant="h5" component="div" style={{ marginTop: '1rem', backgroundColor: '#00a92b',color:'white', fontWeight:'bold' }}>
              Especialidad
            </Typography>
            <Typography variant="h5" component="div" style={{ marginTop: '1rem', color:'#ab9f80' }}>
              {json.currentUser.speciality}
            </Typography>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default UserProfile;
