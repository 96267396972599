/*eslint-disable*/
import React, { useState, useContext } from 'react';
import './AuthClaimManager.css';
import '../../components/FormikForm/FormikForm.css'
import { ModalButton } from "../FormikForm/ModalButton";
import { Grid, Button, Space, Divider, Search } from 'antd-mobile';
import { AppContext } from '../../context/provider';
import texts from '../../constants/texts';
const MODULES = require('../../mock/modulos.json'); //! REMOVE PLEASE!!

export const AuthClaimManager = ({ setIsPanelVisible }) => {
  const [state, setState] = useContext(AppContext);
  const { professionClaims, selectedPatient, isLoading } = state;
  const [approved, setApproved] = useState(false);
  const [clickedId, setClickedId] = useState(-1);
  const [isEnable, setIsEnable] = useState(true);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const {
    pages: {
      home: {
        components: {
          authOrderManager: { unknownClaimText, authorizeText, searchClaim },
        },
      },
    },
  } = texts;

  const handleModal = () => {
    if (selectedPatient) {
      setApproved(true);
      setIsEnable(true);
      setClickedId(-1);

    }
  };

  const handleClick = async (event, id) => {
    setApproved(false);
    if (!selectedPatient) return;

    if (clickedId === id) {
      setClickedId(-1);
      setIsEnable(true);
    } else {
      const claimDescription = event.target.innerText;

      const moduleDescription = MODULES.find(
        (module) => module.description === claimDescription
      );

      //const claim = searchClaimByDescription(claimDescription);

      const claimResponse = {
        claim: {
          name: claimDescription,
          module: moduleDescription,
          //type: claim.type,  {se comenta por nomencladorAmbulatorio no tener "type"}
          //code: claim.code,
        },
        status: '',
        amount: '',
        date: '',
      };

      setFilteredSuggestions([]);
      setInput(event.target.innerText);
      setState({ ...state, claimResponse: claimResponse });
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      setClickedId(id);
      setIsEnable(false);
      authClaim(event);
    }
  };

  const authClaim = (claim) => {
    claim && setIsEnable(false);
  };

  const onChange = (claimToSearch) => {
    setApproved(false);
    const userInput = claimToSearch;

    const unLinked = professionClaims.filter(
      (suggestion) =>
        suggestion.data.nombre.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(claimToSearch);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className='suggestions'>
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          if (index === activeSuggestionIndex) {
            className = 'suggestion-active';
          }
          return (
            <li
              className={className}
              key={suggestion.id}
              onClick={(event) => {
                handleClick(event, suggestion.id);
              }}
            >
              {suggestion.data.nombre.toUpperCase()}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className='no-suggestions'>
        <em> {unknownClaimText} </em>
      </div>
    );
  };

  const claimsGrid = professionClaims?.slice(0, 9).map((claim) => {
    return (
      <Grid.Item key={claim.id}>
        <Button
          onClick={(event) => handleClick(event, claim.id)}
          color={claim.id === clickedId ? 'success' : 'primary'}
          className='opt'
        >
          {claim.data.nombre}
        </Button>
      </Grid.Item>
    );
  });

  // console.log(approved);

  return (
    <Space direction='vertical' align='center'>
      {!isLoading && selectedPatient?.gender && (
        <Grid columns={3} gap={5} style={{ display: selectedPatient ? '' : 'none' }}>
          {claimsGrid}
        </Grid>
      )}

      <>
        <Divider className='separator' />
        {selectedPatient?.gender &&
          <Search
            style={{ width: '95vw', display: selectedPatient ? '' : 'none' }}
            placeholder={searchClaim}
            onChange={onChange}
            value={input}
            onFocus={() => setIsPanelVisible(false)}
            onBlur={() => setIsPanelVisible(true)}

          />}
        {showSuggestions && input && <SuggestionsListComponent />}

        <Divider className='separator' />

        {selectedPatient?.gender &&
          < ModalButton
            block
            className='FormikForm'
            color={'success'}
            disabled={isEnable}
            onClick={handleModal}
          >
            {authorizeText}
          </ModalButton>}
      </>
    </Space >
  );
};

