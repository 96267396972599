const subscriptionSuccessText = () => {
  return (
    <>
      <h1>Bienvenido, Nos encanta tenerte aqui.</h1>
      <h4>
        En Kani encontrarás las soluciones a todos tus problemas transaccionales como así también toda la información financiera sobre las prestaciones que realizas día a día.
        Podrás ver cuando cobras, cuanto cobras y en concepto de que estas cobrando.
      </h4>
      <h4>
        También podrás analizar tu tiempo promedio de atención, la estacionalidad de tus atenciones y mucho más.
      </h4>
      <h3>
        Esperemos que disfrutes de esta herramienta tanto como nosotros disfrutamos ayudándote.
      </h3>
      <br />
      <br />
    </>
  )
}

const subscriptionSubText = () => {
  return (
    <>
      <h4><i><strong>(*)</strong> a partir de que te suscribas, se te va a debitar el monto por mes para seguir usando las funcionalidades sin límites</i></h4>
    </>
  )
}

const subscriptionText = () => {
  return (
    <>
      <h1>
        Hola, estás a un paso de tener toda tu información a un click de distancia.
      </h1>
      <h4>
        Te contamos que Kani es una herramienta súper potente para llevar al día tu información financiera, históricos de atención e información super importante para el desarrollo de tu profesión.
      </h4>
      <h4>
        Somos la primera plataforma que te permite transaccionar con solo un click.
        No entrarás a múltiples puertos, múltiples claves o múltiples códigos, errores de carga y otros inconvenientes que te generan dolor de cabeza diariamente.
      </h4>
    </>
  )
}

const texts = {
  pages: {
    home: {
      generateInvoiceText: "Presentar",
      signOutText: "salir",
      components: {
        patientManager: {
          unknownPatientText: 'Paciente no identificado. Ingresa un nuevo DNI',
          searchInProcess: 'Estamos buscando los datos del paciente. Un momento por favor',
          newPatientText: 'O crea un nuevo paciente',
          subscriptionAlertTitle: "Subscripción",
          subscriptionAlertText: "Para usar esta funcionalidad requiere estar subscripto.",
          subscriptionAlertButtonText: "Subscribirme",
        },
        authOrderManager: {
          unknownClaimText: "La prestación buscada no existe",
          authorizeText: "Procesar",
          searchClaim: "Buscar Prestación",
          confirmAuthOrderModal: {
            /* sureText: 'Por favor', */
            confirmClaimText:
              "Confirma que desea registrar la practica de %selected_claim% para el paciente %selected_patient%",
            cancelText: "Cancelar",
            acceptText: "Aceptar",
            approvedText: "Aprobado",
            RejectedText: "Rechazado",
            inputLabelText: "Ingrese el numero de autorización/bono",
            inputValidationText1: "Ingrese un codigo",
            inputValidationText2: "Ingrese un codigo superior a 4 caracteres",
          },
        },
        floatingPanel: {
          toCollectText: 'A cobrar',
          toInvoiceText: 'A presentar',
          toPlus: 'Total cobrado en efectivo',
          subscriptionAlertTitle: "Subscripción",
          subscriptionAlertText: "Para usar esta funcionalidad requiere estar subscripto.",
          subscriptionAlertButtonText: "Subscribirme",
        },
      },
      leftMenu: {
        codigoSifo: "IMPRIMIR SIFO",
        turnos: "TURNOS",
        filinghistory: "PRESENTADOS",
        subscribeText: "Suscribite",
        logoutText: "Salir",
      },
    },
    billing: {
      processed: "Procesado",
      components: {
        chartOrganization: {
          ordersText: "Ordenes",
          amountText: "Monto",
        },
      },
    },
    billDetailOS: {
      components: {
        table: {
          pacientText: "Paciente",
          amountText: "Monto",
        },
      },
    },
    generateInvoice: {
      components: {
        totalPanel: {
          generateInvoiceText: "Emitir Planilla",
        },
        claimsContainer: {
          allClaimsSelectedText:
            "Todas las prestaciones a presentar están seleccionadas",
        },
        claimCard: {
          nameText: "Nombre",
          dniText: "DNI",
          claimText: "Prestación",
        },
      },
    },
    login: {
      getIntoText: "Ingresar",
      loginText: "Inicia Sesión",
      signupText: "ó registrate",
      forgotPasswordText: "olvidé mi contraseña",
    },
    signup: {
      signupTitle: "Registrate",
      form: {
        nameText: 'Nombre Completo',
        cuilCuitText: 'CUIL/CUIT',
        emailText: 'Email',
        passwordText: 'Contraseña',
        medicalLicenceText: 'Nro matrícula profesional',
        registrationText: 'Matrícula',
        categoryText: 'Categoría',
        associateNumberText: 'Número de socio',
        professionText: 'Profesion',
        provinceText: 'Seleccione su provincia',
        specialityText: 'Especialidad',
        phoneText: 'Nro celular',
        collegeText: '¿Estás colegiado?',
        phonePlaceholder: 'Sin 0 ni 15. Ej 3815715068',
        signupTextNext: 'Continuar',
        signupTextBack: 'Volver',
        signupTextSubmit: 'Crear Cuenta',
        loginLinkText: 'ó inicia sesión',
      }
    },
    forgotPassword: {
      forgotPasswordTitle: "Restablecer Correo Electronico",
      restorePasswordText: "Restablecer contraseña",
      loginText: "inicia sesión",
      signupText: "registrate",
      checkEmailText:
        "Revisa tu casilla de correo electronico para más instrucciones",
      unknownUser:
        "No existe usuario correspondiente a esta direccion de correo electrónico",
    },
    editPatient: {
      title: "Paciente",
      form: {
        nameLabel: "nombre completo",
        affiliateLabel: "afiliado",
        dniLabel: "dni *",
        organizationLabel: "obra social",
        addressLabel: "dirección",
        localityLabel: "localidad",
        provinceLabel: "provincia",
        phoneLabel: "telefono",
        phonePlaceholder: "sin 0 ni 15. Ej 3815715068",
        emailLabel: "email",
        emailPlaceholder: "email@email.com",
        genderLabel: "sexo *",
        bloodgroupLabel: "grupo sanguineo",
        medicalrecordText: "historial clinico",
        codeLabel: "Código Postal",
        saveText: "Guardar",
        cancelText: "Cancelar",
        selectOptions: {
          genders: [
            { id: 0, name: 'Masculino' },
            { id: 1, name: 'Femenino' },
          ],
          bloodTypes: [
            { id: 0, name: 'O Negativo' },
            { id: 1, name: 'O Positivo' },
            { id: 2, name: 'A Negativo' },
            { id: 3, name: 'A Positivo' },
            { id: 4, name: 'B Negativo' },
            { id: 5, name: 'B Positivo' },
            { id: 6, name: 'AB Negativo' },
            { id: 7, name: 'AB Positivo' },
          ],
        },
      },
    },
    invoiceSuccess: {
      downloadDocumentationButtonText: "Descargar documentación",
      sendInvoiceByEmail: "Enviar documentación por Email",
      returnToHome: "volver al inicio",
    },
    subscription: {
      text: subscriptionText(),
      subText: subscriptionSubText(),
      success: {
        text: subscriptionSuccessText(),
        goToHomeText: 'Ir a la Página principal'
      }
    }
  },
};

export default texts;
