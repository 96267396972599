import { Space } from 'antd-mobile';
import React from 'react';
import Header from '../..//layout/Header/Header';
import { OrganizationsManager } from '../../components/OrganizationsManager/OrganizationsManager';

export const AddOrganizations = () => {

  return (
    <>
      <Header title={'Obras Sociales'} />
      <Space />

      <OrganizationsManager />
    </>
  );
};
