const afipParams = {
  token: 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pgo8c3NvIHZlcnNpb249IjIuMCI+CiAgICA8aWQgc3JjPSJDTj13c2FhaG9tbywgTz1BRklQLCBDPUFSLCBTRVJJQUxOVU1CRVI9Q1VJVCAzMzY5MzQ1MDIzOSIgZHN0PSJDTj13c2ZlLCBPPUFGSVAsIEM9QVIiIHVuaXF1ZV9pZD0iMzYzNzM0MjI4IiBnZW5fdGltZT0iMTYzNjQxMTk0NyIgZXhwX3RpbWU9IjE2MzY0NTUyMDciLz4KICAgIDxvcGVyYXRpb24gdHlwZT0ibG9naW4iIHZhbHVlPSJncmFudGVkIj4KICAgICAgICA8bG9naW4gZW50aXR5PSIzMzY5MzQ1MDIzOSIgc2VydmljZT0id3NmZSIgdWlkPSJTRVJJQUxOVU1CRVI9Q1VJVCAyMDM2ODY3OTA1NSwgQ049aW1lZGluYSIgYXV0aG1ldGhvZD0iY21zIiByZWdtZXRob2Q9IjIyIj4KICAgICAgICAgICAgPHJlbGF0aW9ucz4KICAgICAgICAgICAgICAgIDxyZWxhdGlvbiBrZXk9IjIwMzY4Njc5MDU1IiByZWx0eXBlPSI0Ii8+CiAgICAgICAgICAgIDwvcmVsYXRpb25zPgogICAgICAgIDwvbG9naW4+CiAgICA8L29wZXJhdGlvbj4KPC9zc28+Cg==',
  sign: 'T7YYG2DJjQ0yqfxCuFZ19z6J/YvLFyxdWOW4Z+7DzVy9iSpPhQx2hRF+iGMKbYyRqRtrKTmrebiBJld0StOVy0m8YR/8Zar2Q27lO9sqS1Jjo6gm2FnJ+pDg8fAE7Be1nYmV8WSNWLtFb0qly1Fh6v0sja/ifPri+iv53E1Dytw=',
  cuit: '20368679055',
  ultimoCBTE: '12',
  FchServDesde: '20211101',
  FchServHasta: '20211108',
  FchVtoPago: '20211108',
}

export default afipParams;
