/*eslint-disable*/
import React, { useEffect, useContext, useState } from "react";
import "./Home.css";

// utils
import { AppContext } from '../../context/provider';

// components
import { useFirestore } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { AuthClaimManager, PanelAnimate, PatientManager } from '../../components';
import GetTurnos from "../../components/turnos/GetTurnos";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import Button from 'react-bootstrap/Button';

const Home = () => {
  
  const [isPanelVisible, setIsPanelVisible] = useState(true); //TODO: para que es este state? 
  const [state] = useContext(AppContext);
  const history = useHistory();
  const { loadData } = useFirestore()
  const { currentUser: { name } } = state;

  const getStoredCurrentUser = () => {
    try {
      const currentUserLogued = localStorage.getItem('storedCurrentUser');
      return currentUserLogued ? JSON.parse(currentUserLogued) : null;
    } catch (error) {
      console.error(error);
    }
  };


  const startUser = () => {
    const user = getStoredCurrentUser();
    if (!user) return;
    loadData(user);
  };

  const buildName = () => {

    return name ? `Dr ${name}` : "Cargando...";
  };

  useEffect(() => {
    startUser();
    buildName();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickHDC = () => {
    history.push('/medical-record');
  }

  return (
    <>
      <Header />
      <Box>
        <PatientManager />
        <AuthClaimManager setIsPanelVisible={setIsPanelVisible} />
          {name && <Button variant="outlined" color="success" onClick={handleClickHDC}>
            HISTORIA CLINICA
          </Button>}
        {/* <GetTurnos /> */}
      </Box>
    </>
  );
};

export default Home;
