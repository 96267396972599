import { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../base';

const useUser = () => {
    const [loading, setloading] = useState(false);
    const [error, setError] = useState();
    const findUser = async (email) => {
        console.log('email que llega ', email);
        try {
            const userQuery = query(
                collection(db, "users"),
                where("email", "==", email)
            );
            const querySnapshot = await getDocs(userQuery);
            let userFound  = null; 
            querySnapshot.forEach((doc) => {
                userFound = { id: doc.id, ...doc.data() }; 
            });
            return userFound; 
        } catch (error) {
            console.log('error', error);
            setError(error);
            setloading(false);  
            return null;
        }
    }
    return { findUser, loading, error}
}

export default useUser