/*eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { ListGroup } from "react-bootstrap";
import { AppContext } from "../../context/provider";
import useTurnos from "../../hooks/useTurnos";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Box, Button, Grid, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';;
import dayjs from 'dayjs';

const today = dayjs();
const tomorrow = dayjs().add(30, 'day');
const yesterday = dayjs().subtract(30, 'day');

const GetTurnos = () => {

    const [state] = useContext(AppContext);
    const history = useHistory();
    const [fecha, setFecha] = useState()
    const { currentUser, appointments } = state;
    const { traerTurnos } = useTurnos();
    const [mostrarCalendario, setMostrarCalendario] = useState(false);

    useEffect(() => {

        traerFechaActual();

        (currentUser.id && fecha) ? traerTurnos(fecha) : null;


    }, [currentUser.id])


    const traerFechaActual = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        setFecha(formattedDate)

    }
    const handleNavigateBeforeClick = () => {
        console.log(fecha);
        const partesFecha = fecha.split('/');
        const dia = parseInt(partesFecha[0], 10);
        const mes = parseInt(partesFecha[1] - 1, 10);
        const anio = parseInt(partesFecha[2], 10);
        const fechaNew = new Date(anio, mes, dia);
        fechaNew.setDate(fechaNew.getDate() - 1);
        const nuevoDia = fechaNew.getDate().toString().padStart(2, '0');
        const nuevoMes = (fechaNew.getMonth() + 1).toString().padStart(2, '0');
        const nuevoAnio = fechaNew.getFullYear();
        const newDate = `${nuevoDia}/${nuevoMes}/${nuevoAnio}`;
        setFecha(newDate)
        traerTurnos(newDate)
        console.log(newDate);
    };


    const handleNavigateNextClick = () => {
        console.log(fecha);
        const partesFecha = fecha.split('/');
        const dia = parseInt(partesFecha[0], 10);
        const mes = parseInt(partesFecha[1] - 1, 10);
        const anio = parseInt(partesFecha[2], 10);
        const fechaNew = new Date(anio, mes, dia);
        fechaNew.setDate(fechaNew.getDate() + 1);
        const nuevoDia = fechaNew.getDate().toString().padStart(2, '0');
        const nuevoMes = (fechaNew.getMonth() + 1).toString().padStart(2, '0');
        const nuevoAnio = fechaNew.getFullYear();
        const newDate = `${nuevoDia}/${nuevoMes}/${nuevoAnio}`;
        setFecha(newDate)
        traerTurnos(newDate)
        console.log(newDate);
    };

    const getIconForTime = (item) => {

        return <Button
            onClick={() => handleEditNoteClick(item)}>
            <EditNoteIcon sx={{ color: '#28a745', fontSize: '2rem' }} />
        </Button>

    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    const handleCalendarMonthClick = () => {
        setMostrarCalendario(!mostrarCalendario);
    };

    const handleCalendarMonth = (newDate) => {
        traerTurnos(newDate)
        setMostrarCalendario(!mostrarCalendario);
    };

    const handleEditNoteClick = (item) => {        
        history.push('/turnos/edit/',  { item: item });
    }; 
    

    return (
        <Grid container sx={{ my: 4, py: 4 }}>
            <Grid item xs={12} md={4} sx={{ backgroundColor: '#fff' }}>
            </Grid>
            <Grid item xs={12} md={4} sx={{ backgroundColor: '#FFF' }}>
                <Box>
                    <Box sx={{ display: 'flex', width: '100%', px: 2, justifyContent: 'space-evenly' }}>
                        <Button onClick={handleNavigateBeforeClick}>
                            <NavigateBeforeIcon sx={{ color: '#28a745', fontSize: '2rem' }} />
                        </Button>
                        <h5 style={{
                            backgroundColor: 'white',
                            color: '#28a745',
                            fontWeight: 'bold',
                            borderBottom: `${appointments.length == 0 ? '0px solid #2ECC71' : '1px solid #2ECC71'}`,
                        }}>
                            {appointments.length == 0 ? "" : "TURNOS"}</h5>
                        <Button onClick={handleNavigateNextClick}>
                            <NavigateNextIcon sx={{ color: '#28a745', fontSize: '2rem' }} />
                        </Button>
                        <Button onClick={handleCalendarMonthClick}>
                            <CalendarMonthIcon sx={{ color: '#E74C3C', fontSize: '2rem' }} />
                        </Button>

                    </Box>
                    {fecha && <Typography>{fecha}</Typography>}

                    {mostrarCalendario && <div className="mb-3 custom-datepicker-container">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                slotProps={{
                                    textField: {
                                        helperText: 'MES/DIA/AÑO',
                                    },
                                }}
                                defaultValue={today}
                                minDate={yesterday}
                                maxDate={tomorrow}
                                views={['year', 'month', 'day']}
                                onChange={(newValue) => {
                                    const formattedDate = dayjs(newValue).format('DD/MM/YYYY');
                                    setFecha(formattedDate)
                                    handleCalendarMonth(formattedDate)
                                }}
                            />
                        </LocalizationProvider>
                    </div>}

                    {appointments.length > 0 && (
                        <ListGroup>
                            {appointments
                                .filter((item) => item.date == fecha)
                                .map((item, index) => (
                                    <ListGroup.Item key={item.dniPatient}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            borderBottom: '1px solid #2ECC71',
                                            alignItems: 'center',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%',
                                        }}>
                                            <p style={{ margin: 0 }}>{index + 1} - {truncateText(item.name, 20)}</p>
                                            <p style={{ margin: 0 }}>{item.time} {getIconForTime(item)}</p>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                        </ListGroup>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ backgroundColor: '#fff' }}>
            </Grid>
        </Grid>
    )
}

export default GetTurnos