import React from 'react'
import UserProfile from '../../components/profile/UserProfile'
import Header from '../../layout/Header/Header';
import { Space } from 'antd-mobile';

const ProfilePage = () => {

  const buildName = () => {
    return 'Mi perfil';
  };

  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <Header title={buildName()} noback={false} isHome={false} />
      <Space align='center' direction='vertical' className='container animate__animated animate__fadeIn animate__faster'></Space>
      {/* </header> */}
      <UserProfile />
    </div>
  )
}

export default ProfilePage