import { Accordion, AccordionDetails, Box, IconButton } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MesCash } from "./MesCash";
import { useState } from "react";

export const CashAccordion = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <>
      
      {/* Acordeón con lista de fechas */}
      {accordionOpen && (
        <Accordion expanded={accordionOpen}>
          <AccordionDetails>
            <MesCash Mes={'Febrero'}/>
            <MesCash Mes={'Marzo'}/>
          </AccordionDetails>
        </Accordion>
      )}
      {/* Botón que despliega el acordeón */}
      <Box sx={{ textAlign: 'center', padding: 1, borderBottom: '1px #d3cdbc solid', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', }}>
        <IconButton variant="contained" onClick={() => setAccordionOpen(!accordionOpen)} sx={{backgroundColor: '#d3cdbc', color: 'white', padding: 0}}>
          {accordionOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
    </>
  )
}
