import React, { useState } from 'react';
import './ForgotPassword.css';
import { ReactComponent as Logo } from '../../assets/logo.svg';

// libraries
import { Button } from 'antd-mobile';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

// constants
import texts from '../../constants/texts';

// util
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Box, Grid } from '@mui/material';

const ForgotPassword = () => {
  const history = useHistory();
  const [enabled, setEnabled] = useState(false);
  const {
    pages: {
      forgotPassword: {
        forgotPasswordTitle,
        restorePasswordText,
        loginText,
        signupText,
        checkEmailText,
        unknownUser,
      },
    },
  } = texts;

  async function handleSubmit(e) {
    e.preventDefault();
    const { email } = e.target.elements;

    try {
      setEnabled(false);

      const auth = getAuth();
      sendPasswordResetEmail(auth, email.value)
        .then(() => {
          Swal.fire({
            title: checkEmailText,
            text: '',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          history.replace('/login');
        })
        .catch((error) => {
          Swal.fire({
            title: 'Usuario no encontrado', //TODO: change to dictionary
            text: unknownUser,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        });
    } catch (error) {
      throw new Error(`error: ${error}`);
    }
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <div className='forgot-password'>
            <div className='forgot-password__logo'>
              <Logo />
            </div>
            <h3>{forgotPasswordTitle}</h3>
            <form onSubmit={handleSubmit}>
              <input
                disabled={enabled}
                name='email'
                type='email'
                placeholder='Email'
                className='forgot-password__field'
                required
              />
              <Button disabled={enabled} type='submit' block color='success'>
                {restorePasswordText}
              </Button>
            </form>

            <>
              <Link className='link' to='/login'>
                {loginText}
              </Link>
              <Link className='link' to='/signup'>
                {signupText}
              </Link>
            </>
          </div>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>

  );
};

export default withRouter(ForgotPassword);
