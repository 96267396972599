import React, { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { LeftMenu } from './LeftMenu';
import { MenuOutlined } from '@ant-design/icons';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link, useLocation } from 'react-router-dom';

const MenuButton = () => {
  let { pathname: location } = useLocation();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <>
      <Button className='menuButton' type='text' onClick={showDrawer} style={{fontWeight:'bold'}}>
        <MenuOutlined />
      </Button>
      <Drawer
        title={
          <Link to='/'>
            <Logo className='nav-logo' />
          </Link>
        }
        placement='left'
        width={200}
        closable={true}
        onClose={showDrawer}
        visible={visible}
        style={{ zIndex: 99999 }}
      >
        <LeftMenu mode={'inline'} />
      </Drawer>
    </>
  );
};

export default MenuButton;
