import React, { useState, createContext } from 'react';

const initialState = {
  currentUser: {
    cuil: '',
    email: '',
    name: '',
    lastName: '',
    medicalLicence: '',
    phone: '',
    profession: '',
    speciality: '',
  },
  userGoogle:null,
  userClaims: [],
  active: [],
  presentation: {
    presentationClaims: [],
    total: 0,
  },
  cancelled: [],
  professionClaims: [],
  isInitialState: true,
  isLoading: false,
  organizations: [],
  totalActive: 0,
  totalCancelled: 0,
  appointments:[],
  currentPatient:{
    id:'',
    address:'',
    affiliate:'',
    birthdate:'',
    bloodType:{},
    dni:'',
    email:'',
    urlphoto:'',
    gender:{},
    locality:'',
    name:'',
    lastname:'',
    organization:'',
    phone:'',
    postalcode:'',
    idParents:null

  },
  currentPatientParent:{
    id:'',
    address:'',
    affiliate:'',
    birthdate:'',
    bloodType:{},
    dni:'',
    email:'',
    urlphoto:'',
    gender:{},
    locality:'',
    name:'',
    lastname:'',
    organization:'',
    phone:'',
    postalcode:''
  },
  contacts:[],
  images:[],
  laboratorio:[],
  recetas:[],
  chequeos:{},
  childrens:[],
  hdcs:[],
};

const ContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  return (
    <AppContext.Provider value={[ state, setState ]}>
      { children }
    </AppContext.Provider>
  );
};

export default ContextProvider;
export const AppContext = createContext();

export { initialState };
