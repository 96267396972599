/*eslint-disble*/
import React, { useState, useContext, useEffect } from 'react';
import { Avatar, Box, Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AddPatientComponent from './AddPatientComponent';
import LabComponent from './LabComponent';
import ImagenesComponent from './ImagenesComponent';
import RecetasComponent from './RecetasComponent';
import ChequeosComponent from './ChequeosComponent';
import EditPatient from './EditPatient';
import { AppContext } from '../../context/provider';
import usePatient from '../../hooks/usePatient';
import AddContactsComponent from './AddContactsComponent';

const PatientComponent = () => {
    const [state, setState] = useContext(AppContext);
    const { childrens, currentPatientParent } = state;
    const [child, setChild] = useState([]);
    const { getChicldrens } = usePatient();

    useEffect(() => {

        if (childrens.length === 0) {
            getChicldrens();
        } else {
            setChild(childrens);
        }
        // console.log(JSON.stringify(childrens))
        // eslint-disable-next-line
    }, [childrens]);

    const updateCurrentUser = (item) => {

        const updatedCurrentUser = {
            ...state.currentPatient,
            id: item.id,
            address: item.address,
            affiliate: item.affiliate,
            birthdate: item.birthdate,
            bloodType: item.bloodType,
            dni: item.dni,
            email: item.email,
            urlphoto: item.urlphoto,
            gender: item.gender,
            locality: item.locality,
            name: item.name,
            organization: item.organization,
            phone: item.phone,
            postalcode: item.postalcode,
            idParents: item.idParents
        };

        setState((prevState) => ({
            ...prevState,
            currentPatient: updatedCurrentUser,
        }));

    }


    const [activeComponent, setActiveComponent] = useState('');


    const renderComponent = () => {
        switch (activeComponent) {
            case 'add':
                return (<AddPatientComponent />);
            case 'laboratory':
                return (<LabComponent />);
            case 'images':
                return (<ImagenesComponent />);
            case 'prescriptions':
                return (<RecetasComponent />);
            case 'checkups':
                return (<ChequeosComponent />);
            case 'edit':
                return (<EditPatient />);
                case 'contacts':
                    return (<AddContactsComponent />);    
            default:
                return null;
        }
    };


    return (
        <Grid container>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={12} md={4}>

                {child.length === 0
                    ? <Box sx={{ display: 'flex', flexDirection: 'row', mt: -5, px: 1 }}>
                        <Avatar src={currentPatientParent.urlphoto ? currentPatientParent.urlphoto : null} alt="Foto de perfil" sx={{ width: 60, height: 60, mx: 1 }} />
                    </Box>
                    : (
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: -5, px: 1 }}>
                            <Avatar
                                src={currentPatientParent.urlphoto ? currentPatientParent.urlphoto : null}
                                alt="Foto de perfil"
                                sx={{ width: 60, height: 60, mx: 1 }}
                                onClick={() => updateCurrentUser(currentPatientParent)} />
                            <Box display="flex" justifyContent="center" flexWrap="wrap">
                                {child.map((item) => (
                                    <Avatar
                                        key={item.id}
                                        src={item.urlphoto ? item.urlphoto : null}
                                        alt={`Foto de ${item.name}`}
                                        sx={{ width: 60, height: 60, mx: 1 }}
                                        onClick={() => updateCurrentUser(item)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}
                <Grid container>
                    <Grid item xs={6} md={6}>
                        <Box p={1}>
                            <Button
                                onClick={() => setActiveComponent('edit')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                                fullWidth>
                                Ficha médica
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                onClick={() => setActiveComponent('laboratory')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                                fullWidth>
                                Laboratorio
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                fullWidth
                                onClick={() => setActiveComponent('checkups')}
                                startIcon={<AddIcon />}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
                                Chequeos
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Box p={1}>
                            <Button
                                onClick={() => setActiveComponent('images')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                                fullWidth>
                                Imágenes
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                onClick={() => setActiveComponent('prescriptions')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                                fullWidth>
                                Medicamentos
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                fullWidth
                                startIcon={<AddIcon />}
                                endIcon={<Diversity3Icon />}
                                onClick={() => setActiveComponent('add')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
                                Agregar
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <Box p={1}>
                            <Button
                                fullWidth
                                startIcon={<PermContactCalendarIcon />}
                                // endIcon={<Diversity3Icon />}
                                onClick={() => setActiveComponent('contacts')}
                                sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
                                Contactos
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
                <Box sx={{ p: 4 }}>
                    {renderComponent()}
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
        </Grid>
    );
};

export default PatientComponent