/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { PrinterOutlined, ExportOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import './style.css';
import { AppContext } from "../../context/provider";
import axios from 'axios';
import Swal from 'sweetalert2';
import { uploadFile } from '../../base';
import { sendEmailUtils } from '../../utils/sendEmail';
import { Box } from '@mui/material';



const GeneratePDFSIFO = ({ codigoSifo }) => {

  const state = useContext(AppContext);
  const json = state.currentUser === undefined ? state[0] : state


  // console.log('codigo sifo PDF: ', JSON.stringify(codigoSifo, null, 4))
  const componentRef = useRef();
  const [items, setItems] = useState({
    cuentas: [],
  });

  function obtenerFechaMesAnterior() {
    let hoy = new Date();
    
    // Obtener el último día del mes anterior
    let ultimoDiaMesAnterior = new Date(hoy.getFullYear(), hoy.getMonth(), 0);
  
    // Formatear la fecha en AAAAMM
    let año = ultimoDiaMesAnterior.getFullYear().toString();
    let mes = (ultimoDiaMesAnterior.getMonth() + 1).toString().padStart(2, '0');
  
    return año + mes;
  }

  useEffect(() => {

    const fecha_anterior = obtenerFechaMesAnterior()
        console.log(fecha_anterior)

    const cuentaData = [];
    codigoSifo.Periodo_de_Facturación.map((item, index) =>
      cuentaData.push({
        codigo:
          codigoSifo.Tipo                              //1  OK
          + codigoSifo.Número_de_Socio                   //2  OK
          + codigoSifo.Código_de_OS                      //3  OK
          // + codigoSifo.Periodo_de_Facturación[index]     //4  OK puede ser item
          + fecha_anterior
          + codigoSifo.Número_de_Autorización[index]     //5  OK
          + codigoSifo.Número_de_Afiliado                //6  OK
          + codigoSifo.Fecha_de_prestación[index]        //7  OK
          + codigoSifo.Médico_Solicitante[index]         //8  OK
          + codigoSifo.Diagnostico[index]                //9  OK
          + codigoSifo.Cero_1                            //10 OK
          + `${codigoSifo.Código_de_prestación[index] == undefined ? '270606' : codigoSifo.Código_de_prestación[index]}` //11 OK
          + codigoSifo.Funcion                           //12 OK
          + codigoSifo.Cantidad[index]                   //13 OK              
          + codigoSifo.Recargo[index]                    //14 OK
          + codigoSifo.Cero_2                            //15 OK DECIMALES DEL RECARGO
          + codigoSifo.Primera_consulta[index]           //16 OK
          + codigoSifo.Cero_3                            //17 OK IMPORTE
          + codigoSifo.Nombre_del_Paciente[index]        //18 OK
          + codigoSifo.sexo[index]                       //19 OK
          + codigoSifo.Separador_70                      //20 OK 00
          + codigoSifo.Tipo_de_Matricula                 //21 OK
          + codigoSifo.Provincia                         //22 OK
          + codigoSifo.Numero_de_matricula               //23 OK
          + codigoSifo.Numero_de_orden[index]            //24 OK
          + codigoSifo.Espacio_en_blanco_2               //25 OK DNI
          + codigoSifo.DNI_del_afiliado[index]           //26 OK
          + codigoSifo.fecha_de_nacimiento[index]        //27 OK
      })
    );

    setItems({
      cuentas: cuentaData,
    });
    // eslint-disable-next-line 
  }, [])

  const handleExportTxt = () => {

    const fecha_de_presentacion = obtenerFechaMesAnterior()

    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear()}`;

    const formattedTime = `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`;
    // Generar el contenido del archivo .txt
    const txtContent = items.cuentas.map(item => item.codigo).join('\n');

    // Crear un objeto Blob con el contenido
    const blob = new Blob([txtContent], { type: 'text/plain' });

    // Crear una URL para el archivo Blob
    const url = URL.createObjectURL(blob);
    sendEmailUtils(json.currentUser.email,
      'SIFO',
      `Se presento SIFO ${formattedDate} - ${formattedTime}`,
      `${codigoSifo.Número_de_Socio + fecha_de_presentacion}.txt`,
      txtContent)
    // Crear un enlace y hacer clic en él para iniciar la descarga
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${codigoSifo.Número_de_Socio + fecha_de_presentacion}.txt`); // aqui cambiar nombre 
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    // sendPdfByEmail();
  };


  const sendPdfByEmail = async () => {
    const fileContent = items.cuentas.map(item => item.codigo).join('\n');
    const blob = new Blob([fileContent], { type: 'text/plain' });

    try {
      const downloadUrl = await uploadFile(blob)
      sendEmail(downloadUrl)
    } catch (error) {
      console.log('error', error)
    }

  };

  const sendEmail = async (downloadUrl) => {



    try {

      const email = json.currentUser.email;

      await axios.post('http://localhost:5001/tani-3a53a/us-central1/sendEmail', { email, downloadUrl });
      Swal.fire({
        text: `el email se envió exitosamente a: ${email} `,
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
      alert('ocurrió un error al enviar el email ', error)
    }

  }



  return (


    <Box>
      <ul ref={componentRef} style={{ padding: 0, margin: 0, listStyle: 'none' }}>
        {
          (items.cuentas.length === 0)
            ? (<li>Sin resultados para esta busqueda.  </li>)
            : items.cuentas.map(item => (
              <li
              style={{
                whiteSpace: 'pre-line', // Permite que el texto se divida en múltiples líneas verticalmente
                overflow: 'hidden', // Ocultar el contenido que se desborde
                textOverflow: 'ellipsis', // Mostrar puntos suspensivos (...) para contenido oculto
                maxWidth: '100%', // Asegurar que el elemento no se desborde del contenedor
              }}
                key={item.codigo}
              >{item.codigo}</li>
            ))}
      </ul>
      {
        (items.cuentas.length === 0)
          ? null
          :
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
            <ReactToPrint
              trigger={() => <button
                style={{ borderRadius: '8px', width: '50%' }}
              >
                <PrinterOutlined />
                {`\nIMPRIMIR`}
              </button>}
              content={() => componentRef.current}
            />
            <button
              style={{ borderRadius: '8px', width: '50%' }}
              onClick={handleExportTxt}>
              <ExportOutlined />
              {`\nEXPORTAR`}
            </button>
          </Box>
      }
    </Box>

  )
}

export default GeneratePDFSIFO