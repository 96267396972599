import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Box, MenuItem } from '@mui/material';
import { Line } from 'react-chartjs-2';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

const ChequeosComponent = () => {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [gender, setGender] = useState('');
  const [growthStage, setGrowthStage] = useState('');

  const [imc, setIMC] = useState(null);
  const [recommendedWeight, setRecommendedWeight] = useState(null);

  let factor = 0;

  const calculateIMC = () => {
    const weightValue = parseFloat(weight);
    const heightValueInCentimeters = parseFloat(height);


    if (
      !isNaN(weightValue) &&
      !isNaN(heightValueInCentimeters) &&
      heightValueInCentimeters > 0 &&
      gender &&
      growthStage
    ) {


      // Establecer el factor según el género y la etapa de crecimiento
      if (gender === 'masculino') {
        if (growthStage === 'niño') {
          factor = 1; // Factor para niños masculinos
        } else if (growthStage === 'adolescente') {
          factor = 2; // Factor para adolescentes masculinos
        } else {
          factor = 3; // Factor para adultos masculinos
        }
      } else if (gender === 'femenino') {
        if (growthStage === 'niño') {
          factor = 4; // Factor para niñas femeninas
        } else if (growthStage === 'adolescente') {
          factor = 5; // Factor para adolescentes femeninas
        } else {
          factor = 6; // Factor para adultas femeninas
        }
      }

      const heightValueInMeters = heightValueInCentimeters / 100;
      const calculatedIMC = weightValue / Math.pow(heightValueInMeters, 2) * factor;
      setIMC(calculatedIMC.toFixed(2));

      // Calcular peso recomendado para la altura dada (fórmula de IMC inversa)
      const recommendedWeightValue = 18.5 * Math.pow(heightValueInMeters, 2);
      setRecommendedWeight(recommendedWeightValue.toFixed(2));

    } else {
      setIMC(null);
      setRecommendedWeight(null);
    }
  };


  const data = {
    labels: ['Peso', 'Altura'],
    datasets: [
      {
        label: 'Tus Datos',
        data: [parseFloat(weight), parseFloat(height)],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        yAxisID: 'datos',
      },
      {
        label: 'Peso Ideal',
        data: [parseFloat(18.5 * Math.pow(1.75, 2) * factor)],
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        yAxisID: 'pesoIdeal',
      },
    ],
  };
  const handleShare = async () => {
    console.log('en funcionalidad')
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" mb={4}>
            Chequeo PESO/TALLA
          </Typography>
          <TextField
            label="Peso (kg)"
            variant="outlined"
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <TextField
            label="Altura (cm)"
            variant="outlined"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <TextField
            fullWidth
            label="Género"
            variant="outlined"
            select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="masculino">Masculino</MenuItem>
            <MenuItem value="femenino">Femenino</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Etapa de Crecimiento"
            variant="outlined"
            select
            value={growthStage}
            onChange={(e) => setGrowthStage(e.target.value)}
          >
            <MenuItem value="niño">Niño</MenuItem>
            <MenuItem value="adolescente">Adolescente</MenuItem>
            <MenuItem value="adulto">Adulto</MenuItem>
          </TextField>
          <Button variant="contained" color="primary" onClick={calculateIMC}>
            Calcular IMC
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        {imc !== null && recommendedWeight !== null && (
          <>
            <Typography variant="h6" style={{ marginTop: '10px' }}>
              Tu IMC: {imc}
            </Typography>
            <Typography variant="h6">
              Peso recomendado: {recommendedWeight} kg
            </Typography>
          </>
        )}
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Line data={data} />
        </Paper>
        <Box
          sx={{
            my: 2,
            border: '2px solid grey',
            borderRadius: '10px',
            display: 'inline-block',
            p: 1,
            width: '100%'
          }}>
          <Button
            startIcon={<SendToMobileIcon />}
            onClick={handleShare}
            sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}>
            Compartir
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChequeosComponent;
