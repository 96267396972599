import React, { useContext, useMemo } from 'react';

// libraries
import { Space } from 'antd-mobile';

// components
import { PanelRowElement } from '../../components';

// utils
import { AppContext } from '../../context/provider';

// constants
import texts from '../../constants/texts';
import { useAccounting, usePayments } from '../../hooks';
import Header from '../../layout/Header/Header';

export const PaymentReconciliations = () => {
  const [state] = useContext(AppContext);
  const { totalCancelled } = state;

  const { formatedCurrency } = useAccounting();
  const { getClaimsByMonth } = usePayments();

  // useEffect(() => {
  //   localStorage.setItem('persistState', JSON.stringify(state));
  // }, [state]);

  // eslint-disable-next-line
  const claimsByMonth = useMemo(() => getClaimsByMonth(), []);

  const {
    pages: {
      home: {
        components: {
          floatingPanel: { toCollectText },
        },
      },
    },
  } = texts;

  return (
    <div className='generate-invoice'>
      <Header title={'RECONCILIACIONES'} />
      <Space />
      <div className='container animate__animated animate__fadeIn animate__faster'>
        <div className='panel_container' style={{ textAlign: 'center', background: 'white', padding: '20px', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>{formatedCurrency(totalCancelled)}</h2>
          <h3>{toCollectText}</h3>
        </div>
      </div>


      {claimsByMonth.map((month, index) => <PanelRowElement key={index} data={month} />)}
    </div>

  );
};
