import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { useContext } from 'react';
import { AppContext } from '../context/provider';
import { getUniqueListBy } from '../utils';

dayjs.extend(customParseFormat);

export const usePayments = () => {
  const [state] = useContext(AppContext);
  const { cancelled } = state;

  const getClaimsByMonth = () => {
    const current = dayjs();
    const previous = current.add(-1, 'month');
    const next = current.add(1, 'month');

    const previousMonth = filterClaims(previous);
    const currentMonth = filterClaims(current);
    const nextMonth = filterClaims(next);

    return [previousMonth, currentMonth, nextMonth];
  };


  const convertDate = ({ seconds, nanoseconds }) => {
    return dayjs(seconds * 1000 + nanoseconds / 1000000);
  };

  const filterClaims = (period) =>
    cancelled.filter((c) => {
      const date = convertDate(c.presentationDay);
      c.date = date.format('DD/MM/YYYY');
      return dayjs(date).isSame(period, 'M');
    });

  const getMonthStr = (arr) =>
    convertDate(arr[0].presentationDay)
      .locale('es')
      .format('MMMM')
      .toUpperCase();

  const getDayStr = (date) => convertDate(date).locale('es').format('dddd DD');

  const getTotalAmount = (arr) => arr.reduce((acc, cur) => acc + cur.amount, 0);

  const getSortedAndUniqueArrayofDates = (dates) => {
    const unique = getUniqueListBy(dates, 'date');
    return unique.sort(({ date: d1 }, { date: d2 }) => {
      const day1 = dayjs(d1, 'DD/MM/YYYY');
      const day2 = dayjs(d2, 'DD/MM/YYYY');
      return  day2.isBefore(day1, 'd') ? 1 : -1
    });
  };

  return {
    getClaimsByMonth,
    getMonthStr,
    getDayStr,
    getTotalAmount,
    getSortedAndUniqueArrayofDates,
  };
};
