/*eslint-disble*/
import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../base';
import Swal from 'sweetalert2';
import { AppContext } from '../../context/provider';
import usePatient from '../../hooks/usePatient';
import CardContactas from '../patientCards/CardContactas';
import AddIcon from '@mui/icons-material/Add';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

const AddContactsComponent = () => {

    const [state, setState] = useContext(AppContext);
    const { currentPatientParent, contacts } = state;
    const { getContacts } = usePatient();
    const [contactos, setContactos] = useState([]);
    const [name, setName] = useState();
    const [lastName, setLastName] = useState();
    const [especiliadad, setEspeciliadad] = useState();
    const [address, setAddress] = useState();
    const [email, setEmail] = useState();
    const [numberPhone, setNumberPhone] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);


    useEffect(() => {
        console.log('contacts ', JSON.stringify(contacts, null, 5))
        if (contacts.length === 0) {
            getContacts()
        } else {
            setContactos(contacts);
        }
        // eslint-disable-next-line
    }, [contacts])

    const limpiarCampos = () => {
        setName('');
        setLastName('');
        setEspeciliadad('');
        setEmail('');
        setAddress('');
        setNumberPhone('');
    };


    const saveToFirebase = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            await addDoc(collection(db, 'contacts'), {
                userId: currentPatientParent.id,
                name: name,
                lastName: lastName,
                especiliadad: especiliadad,
                address: address,
                email: email,
                numberPhone: numberPhone,
            });

            const newContact = {
                userId: currentPatientParent.id,
                name: name,
                lastName: lastName,
                especiliadad: especiliadad,
                address: address,
                email: email,
                numberPhone: numberPhone,
            };

            setState((prevState) => ({
                ...prevState,
                contacts: [...prevState.contacts, newContact],
            }));

            Swal.fire({
                text: `Contacto Guardado`,
                icon: 'success',
                confirmButtonText: 'Ok',
            });
            limpiarCampos();
            setLoading(false);
            setShowForm(false);

        } catch (error) {

            console.log(error);
            Swal.fire({
                text: `Error al guardar`,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            limpiarCampos();
            setLoading(false);
            setShowForm(false);
        }

    }


    return (
        <Box sx={{ border: "3px solid #616A6B" }}>
            {!showForm && <>
                <Box direction="row" spacing={2} my={4}>
                    <Typography>CONTACTOS</Typography>
                    <Button
                        startIcon={<AddIcon />}
                        endIcon={<PermContactCalendarIcon />}
                        sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                        onClick={() => { setShowForm(true) }}
                    >agregar</Button>
                </Box>
                <Grid container spacing={2} px={1}>
                    {contactos.length === 0
                        ? null
                        : contactos.map((card) => (<CardContactas key={card.id} card={card} />))}
                </Grid></>}
            {
                showForm && <Box sx={{ width: 400, bgcolor: 'background.paper', p: 2, borderRadius: 2, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Button></Button>
                        <Typography textAlign="center" variant="h6">
                            {loading ? "Cargando..." : "Agregar contacto"}
                        </Typography>
                        <Button  onClick={() => { setShowForm(false) }}>X</Button>
                    </Box>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required />
                    <TextField
                        label="Apellido"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Especialidad"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={especiliadad}
                        onChange={(e) => setEspeciliadad(e.target.value)}
                        required
                    />
                    <TextField
                        label="Dirección"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                    <TextField
                        label="Correo Electrónico"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Número de Teléfono"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={numberPhone}
                        onChange={(e) => setNumberPhone(e.target.value)}
                        required />
                    <Button
                        fullWidth
                        type='submit'
                        sx={{ color: "green", fontWeight: 'bold', border: "3px solid grey" }}
                        onClick={saveToFirebase}>
                        GUARDAR CAMBIOS</Button>
                </Box>
            }
        </Box>
    )
}

export default AddContactsComponent