/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header/Header'
import { AppContext } from '../../context/provider';
import './MedicalRecord.css'
import { Grid, Box, Typography, Button, TextField, MenuItem, Select, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { db } from '../../base';
import { addDoc, collection } from 'firebase/firestore';
import Swal from 'sweetalert2';
import useHDC from '../../hooks/useHDC';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


export const MedicalRecord = () => {

  const { isLoading, getHDC } = useHDC();
  const [state, setState] = useContext(AppContext);
  const { selectedPatient, hdcs } = state;
  console.log(selectedPatient);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fecha: '',
    hora: '',
    id: selectedPatient?.id ?? '',
    dni: selectedPatient?.dni ?? '',
    email: selectedPatient?.email ?? '',
    motivoDeConsulta: '',
    antecedentesPersonales: '',
    antecedentesFamiliares: '',
    antecedentesQuirurgicos: '',
    alergias: '',
    detalleAlergias: '',
    examenesRealizados: '',
    detalleExamenes: '',
    enfermedadActual: '',
    tratamiento: '',
    procedimiento: '',
    observaciones: ''
  });

  useEffect(() => {
    console.log('Selected patient:', JSON.stringify(selectedPatient, null, 5));
    setFormData(prevFormData => ({
      ...prevFormData,
      fecha: new Date().toLocaleDateString(),
      hora: new Date().toLocaleTimeString(),
    }));
    getHDC();
  }, [selectedPatient]);

  const sortedHDCs = [...hdcs].sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  const isFormComplete = () => {
    return Object.values(formData).every(value => value.trim() !== '');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const clearFields = () => {
    setFormData({
      fecha: '',
      hora: '',
      id: selectedPatient?.id ?? '',
      dni: selectedPatient?.dni ?? '',
      email: selectedPatient?.email ?? '',
      motivoDeConsulta: '',
      antecedentesPersonales: '',
      antecedentesFamiliares: '',
      antecedentesQuirurgicos: '',
      alergias: '',
      detalleAlergias: '',
      examenesRealizados: '',
      detalleExamenes: '',
      enfermedadActual: '',
      tratamiento: '',
      procedimiento: '',
      observaciones: ''
    });

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, 'medicalhistory'), formData);
      setState((prevState) => ({
        ...prevState,
        hdcs: [...prevState.hdcs, formData],
      }));
      clearFields();
      Swal.fire({
        text: `Guardado exitosamente`,
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: `No se pudo guardar`,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    } finally {
      setLoading(false);
    }

  };

  return (
    <Grid container>
      <Header title={'Historial Medico'} />
      <Grid item xs={12} md={3}>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="form-title">INFORMACIÓN E HISTORIA CLINICA</div>
        <Box sx={{}}>
          <Box sx={{}}>
            <Typography>Nombre :{selectedPatient.name.toUpperCase()}</Typography>
          </Box>
          <Box sx={{}}>
            <Typography>DNI: {selectedPatient.dni}</Typography>
          </Box>
        </Box>
        {isLoading && hdcs.length === 0 ? (
          <Typography>Cargando...</Typography>
        ) : sortedHDCs.length > 0 ? (
          sortedHDCs.map((hdc, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ArrowDropDownCircleIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>{hdc.fecha} - {hdc.motivoDeConsulta.toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Hora: {hdc.hora}</Typography>
                <Typography>Antecedentes Personales: {hdc.antecedentesPersonales.toUpperCase()}</Typography>
                <Typography>Antecedentes Familiares: {hdc.antecedentesFamiliares.toUpperCase()}</Typography>
                <Typography>Antecedentes Quirúrgicos: {hdc.antecedentesQuirurgicos.toUpperCase()}</Typography>
                <Typography>Alergias: {hdc?.alergias === 'si' ? `Sí - ${hdc.detalleAlergias.toUpperCase()}` : 'No'}</Typography>
                <Typography>Exámenes Realizados: {hdc?.examenesRealizados === 'si' ? `Sí - ${hdc.detalleExamenes.toUpperCase()}` : 'No'}</Typography>
                <Typography>Enfermedad Actual: {hdc.enfermedadActual}</Typography>
                <Typography>Tratamiento: {hdc.tratamiento.toUpperCase()}</Typography>
                <Typography>Procedimiento: {hdc.procedimiento.toUpperCase()}</Typography>
                <Typography>Observaciones: {hdc.observaciones.toUpperCase()}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>Sin HDCs...</Typography>
        )}
        <div className="form-title">Motivo de consulta</div>
        <Box sx={{ px: 4, pb: 4 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Motivo de la Consulta"
              name="motivoDeConsulta"
              value={formData.motivoDeConsulta}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Antecedentes Personales"
              name="antecedentesPersonales"
              value={formData.antecedentesPersonales}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Antecedentes Familiares"
              name="antecedentesFamiliares"
              value={formData.antecedentesFamiliares}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Antecedentes Quirúrgicos"
              name="antecedentesQuirurgicos"
              value={formData.antecedentesQuirurgicos}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="alergias-label">Alergias</InputLabel>
              <Select
                labelId="alergias-label"
                id="alergias"
                name="alergias"
                value={formData.alergias}
                label="Alergias"
                onChange={handleInputChange}
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            {formData.alergias === 'si' && (
              <TextField
                label="CUAL"
                name="detalleAlergias"
                value={formData.detalleAlergias}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel id="examenes-label">Exámenes Realizados</InputLabel>
              <Select
                labelId="examenes-label"
                id="examenesRealizados"
                name="examenesRealizados"
                value={formData.examenesRealizados}
                label="Exámenes Realizados"
                onChange={handleInputChange}
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            {formData.examenesRealizados === 'si' && (
              <TextField
                label="Detalle de Exámenes"
                name="detalleExamenes"
                value={formData.detalleExamenes}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            )}
            <TextField
              label="Enfermedad actual"
              name="enfermedadActual"
              value={formData.enfermedadActual}
              onChange={handleInputChange}
              fullWidth
              multiline
              margin="normal"
            />
            <TextField
              label="Tratamiento"
              name="tratamiento"
              value={formData.tratamiento}
              onChange={handleInputChange}
              fullWidth
              required
              multiline
              margin="normal"
            />
            <TextField
              label="Procedimiento"
              name="procedimiento"
              placeholder='indicaciones'
              value={formData.procedimiento}
              onChange={handleInputChange}
              fullWidth
              required
              multiline
              margin="normal"
            />
            <TextField
              label="Observaciones"
              name="observaciones"
              value={formData.observaciones}
              onChange={handleInputChange}
              fullWidth
              required
              multiline
              margin="normal"
            />
            <Button type="submit" variant="contained" color="success" disabled={isFormComplete()} fullWidth>
              {loading ? "Cargando..." : "Guardar"}
            </Button>
          </form>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
      </Grid>
    </Grid>
  )
}