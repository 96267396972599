import React from 'react';

// libraries
import { StyleSheet, View, Text, } from '@react-pdf/renderer';

const InvoiceFooter = ({ total, quantityOfPages, currentPage }) => {
  return(
    <View style={styles.footerContainer}>
      <View>
        <Text style={styles.observations}> OBSERVACIONES: </Text>
      </View>

      <View style={styles.totalInfo}>
        <View style={styles.table}>
          <View style={styles.header}>
            <View style={styles.headOne}>
              <Text style={styles.smText}> Lugar y Fecha </Text>
            </View>
            <View style={styles.headTwo}>
              <Text style={styles.smText}> PERÍODO DE LIQUIDACIÓN (MM/AA)  </Text>
            </View>
            <View style={styles.headThree}>
              <Text style={styles.smText}> FIRMA DEL PRESTADOR </Text>
            </View>
            <View style={styles.headFour}>
              <Text style={styles.smText}> SELLO </Text>
            </View>
            <View style={styles.headFive}>
              <Text style={styles.smText}> SE ADJUNTA FACTURA Nº  </Text>
            </View>
            <View style={styles.headSix}>
              <Text style={styles.smText}> SELLO y FIRMA recepcion </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
  },
  totalInfo: {
    flex: 1,
    flexDirection: 'row',
    border: '1 solid black',
  },
  totalRow: {
    padding: 20,
  },
  totalLabel: {
    fontWeight: 'bold',
  },
  totaInfo: {
    paddingLeft: 20,
  },
  footerInfo: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
  },
  leftInfo: {
    width: '20%',
  },
  page: {
    position: 'absolute',
    left: '45%',
    margin: '0 auto',
    width: 70,
    textAlign: 'center',
    paddingTop: 5,
  },
  observations: {
    height: 40,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: 'black',
    border: '1 solid black',
    height: 40,
  },
  headOne: {
    justifyContent: 'flex-start',
    borderRight: '1 solid black',
    width: '16%',
    textAlign: 'center',
    height: 40,
  },
  headTwo: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRight: '1 solid black',
    width: '16%',
    textAlign: 'center',
    height: 40,
  },
  headThree: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRight: '1 solid black',
    width: '16%',
    textAlign: 'center',
    height: 40,
  },
  headFour: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRight: '1 solid black',
    width: '16%',
    textAlign: 'center',
    height: 40,
  },
  headFive: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRight: '1 solid black',
    width: '16%',
    textAlign: 'center',
    height: 40,
  },
  headSix: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '20%',
    textAlign: 'center',
    height: 40,
  },
  pageText: { fontSize: 12, },
  xsText: { fontSize: 6 },
  smText: { fontSize: 9 },
  mdText: { fontSize: 12 },
  lgText: { fontSize: 15 },
});

export default InvoiceFooter;